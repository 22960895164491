<template>
  <div class="message-more">
    <div class="btn" @click="open = !open">•••</div>
    <div class="dropdown" ref="dropdown" v-if="open">
      <!-- <a class="link edit" v-if="isAuthor" @click="editClicked">Edit</a>
      <a class="link delete" v-if="isAuthor" @click="deleteClicked">Delete</a>
      <a class="link report" @click="reportClicked">Report</a> -->
      <a class="link share" @click="shareClicked">Share</a>
    </div>
  </div>
</template>

<script lang="ts">
// TS stuff
import { computed, defineComponent, PropType, ref } from "vue";
import type { Message } from "@/types";
import { useStore } from "@/application/store";
import { onClickOutside } from "@vueuse/core";
// import { useFeedsStore } from "@/application/feeds/store";
import { useNotification } from "@/application/plugins/Notification"
import api from "@/application/api";
// import { updateMessage } from "@/application/feeds/store/state";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  emits: {
    editing(f: boolean) {
      return f;
    },
  },
  setup(props, { emit }) {
    const { state, dispatch, commit } = useStore();
    const notify = useNotification()
    const { message } = props;
    const open = ref(false);
    const isAuthor = computed(() => message.user.id == state.currentUser?.id);
    const dropdown = ref();
    onClickOutside(dropdown, (_) => {
      open.value = false;
    });

    const editClicked = () => {
      open.value = false;
      commit("messages_update", { id: message.id, editing: true });
      emit("editing", true);
    };

    const deleteClicked = async () => {
      open.value = false;
      if (confirm("Are you sure?")) {
        await dispatch("messages_destroy", message.id);
        notify("post deleted");
      }
    };
    const shareClicked = async () => {
      open.value = false;
      await navigator.clipboard.writeText(message.publicUrl as string);
      notify("link copied");
    };

    const reportClicked = async () => {
      open.value = false;
      await api.messages.report(message.id);
      notify("post reported");
    };

    return {
      open,
      isAuthor,
      dropdown,
      editClicked,
      deleteClicked,
      shareClicked,
      reportClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../variables";
.message-more {
  position: relative;
  margin: 0.25rem 0;
  height: 20px;
  width: 25px;

  .btn {
    position: absolute;
    right: 0;
    cursor: pointer;
    color: $medium-gray1;
  }
  .dropdown {
    position: absolute;
    right: -5px;
    top: 20px;
    min-width: 80px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    z-index: 1500;
    border: 1px solid var(--border-color, #d6d6d6);
    border-radius: 4px;
    padding: 10px 16px;
    text-align: right;

    a {
      cursor: pointer;
      color: var(--black);
      font-size: 15px;
      line-height: 25px;
      // padding: 0 16px 0 16px;
      // border-bottom: 1px solid var(--border-color, #d6d6d6);
      &.delete {
        // color: red;
      }
      &:hover {
        color: red;
      }
    }
  }
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    class="h-6 w-6 icon camera-icon"
    stroke="currentColor"
    fill="currentColor"
  >
  <path
    id="Video_Camera"
    d="M72.9,14.4L56,25.3V22c0-4.4-3.6-8-8-8H8c-4.4,0-8,3.6-8,8v32c0,4.4,3.6,8,8,8h40c4.4,0,8-3.6,8-8v-3.3
l16.9,10.9c1.9,1,3.1-0.7,3.1-1.7V16C76,15,74.9,13.2,72.9,14.4z"
  />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

import { BasePeer } from "./types"
import { VideoToken } from "./v2"

export enum MessageType {
  welcome = "welcome",
  startMessage = "startMessage",
  endText = "endText",
  typing = "typing",
  typingTag = "typingTag",
  endTyping = "endTyping",
  textPosted = "textPosted",
  textPostedSuccess = "textPostedSuccess",
  startImage = "startImage",
  imagePosted = "imagePosted",
  imagePostedSuccess = "imagePostedSuccess",
  messageLiveStreamFramePostedSuccess = "messageLiveStreamFramePostedSuccess",
  imageProgress = "imageProgress",
  imageSelected = "imageSelected",
  messageDeleted = "messageDeleted",
  videoProcessingComplete = "videoProcessingComplete",
  startComment = "startComment",
  typingComment = "typingComment",
  endComment = "endComment",
  commentPosted = "commentPosted",
  commentPostedSuccess = "commentPostedSuccess",
  commentDeleted = "commentDeleted",
  streamEnded = "streamEnded",
  reaction = "reaction",
  liveStreamPing = "liveStreamPing",
  liveStreamCreate = "liveStreamCreate",
  liveStreamCreatedSuccess = "liveStreamCreatedSuccess",
  liveStreamCreatedError = "liveStreamCreatedError",
  liveStreamCreated = "liveStreamCreated",
  liveStreamClosed = "liveStreamClosed",
  liveStreamAnnounce = "liveStreamAnnounce",
  liveStreamJoin = "liveStreamJoin",
  liveStreamJoined = "liveStreamJoined",
  liveStreamPeerJoined = "liveStreamPeerJoined",
  liveStreamAlreadyJoined = "liveStreamAlreadyJoined",
  liveStreamJoinError = "liveStreamJoinError",
  liveStreamNotJoined = "liveStreamNotJoined",
  liveStreamLeave = "liveStreamLeave",
  liveStreamLeft = "liveStreamLeft",
  liveStreamNotFoundError = "liveStreamNotFoundError",
  liveStreamExists = "liveStreamExists",
  liveStreamMaxExceeded = "liveStreamMaxExceeded",
  liveStreamStarted = "liveStreamStarted",
  liveStreamNotAuthenticated = "liveStreamNotAuthenticated",
}

export type VideoPeer = BasePeer & {

}


/**
 * JSON
  {
    "type":"welcome",
    "id":"",
    "msgId":"",
    "feedId":0,
    "uid":1007,
    "body":
          {
            "isAuthenticated":true,
            "liveStreams":[
              {
                  "id":"6D705C45-F462-425A-96B3-FAB853584BF7_1ADBF7D0",
                  "recId":"01FM4JYNZFGKH3PNQRWD740JTD",
                  "createdByDeviceId":"6D705C45-F462-425A-96B3-FAB853584BF7",
                  "count":1,
                  "created":"2021-11-10T09:30:38.150920786Z",
                  "meta":"{\"username\":\"Undo\",\"userId\":20}",
                  "started":true,
                  "type":"video",
                  "peers":null
              }
            ],
            "rooms":{

            }
          }
    "version":2
 }
*/
export type welcomeLiveStream = {
  roomId: string
  createdByDeviceId: string
  started: boolean
  count: number
  created: string | Date
  id: string
  meta: welcomeLiveStreamMeta
  peers: null | Array<VideoPeer>
  recId: string
  type: "video"
}

export type welcomeLiveStreamMeta = {
  username: string
  userId: number
  uid: number
  profilePic?: string
}

export type welcomeMsg = {
  version: number
  type: MessageType
  id: string
  feedId: number
  roomId: string
  uid: number
  body: {
    isAuthenticated: boolean
    liveStreams: Array<welcomeLiveStream>,
    meta: {
      username: string
      userId: number
      profilePicUrl: string
    }
  }
}

export type liveStreamReaction = {
  id: string
  dimension: number;
  userId: number;
  username: string;
  duration: number;
  yShift: number;
  createdAt: Date;
  body: string;
}

export type liveStreamMsg = {
  version: number
  type: MessageType
  id: string
  roomId: string
  feedId?: number
  uid: number
  body: string | { [key: string]: any }
}

export type LiveStreamRoomInfo = {
  id: string
  recId?: string
  count: number
  createdByDeviceId: string
  meta: Record<string, any>
  type: string
  created: string
  started: boolean
  recordingStartedAt: string
}




export interface Msg {
  id: string
  type: string
  roomId: string
  feedId: number
  body: string | object
  version: number
}

export namespace msg {
  export interface welcomeRaw extends Msg {
    uid: number
    liveStreams: []
    rooms: {}
  }

  export interface welcome extends Msg {
    body: {
      isAuthenticated: boolean
      liveStreams: Array<welcomeLiveStream>
    }
  }

  export type liveStreamMsg = liveStreamCreated
    | liveStreamCreatedSuccess
    | liveStreamJoined
    | liveStreamPeerJoined
    | liveStreamNotJoined
    | liveStreamLeft
    | liveStreamStarted
    | liveStreamClosed

  //liveStreams
  type lsBase = {
    id: string
    uid: number
    msgId: string
    feedId: number
    version: number
  }
  type lsRoomInfo = {
    id: string
    recId: string
    count: number
    createdByDeviceId: string
    meta: {
      username: string
      userId: number
      profilePic?: string
    }
    type: "vide"
    created: string
    started: boolean
    recordingStartedAt: string
  }

  type tokenBody = {
    videoToken: VideoToken
    info: lsRoomInfo
    peer: VideoPeer
  }

  export type liveStreamCreatedSuccess = lsBase & {
    type: MessageType.liveStreamCreatedSuccess
    body: tokenBody
  }

  export type liveStreamCreated = lsBase & {
    type: MessageType.liveStreamCreated
    body: lsRoomInfo
  }

  export type liveStreamJoined = lsBase & {
    type: MessageType.liveStreamJoined
    body: tokenBody
  }

  export type liveStreamPeerJoined = lsBase & {
    type: MessageType.liveStreamPeerJoined
    body: lsRoomInfo
  }

  export type liveStreamNotJoined = lsBase & {
    type: MessageType.liveStreamNotJoined
    body: lsRoomInfo
  }

  export type liveStreamLeft = lsBase & {
    type: MessageType.liveStreamLeft
    body: lsRoomInfo
  }

  export type liveStreamStarted = lsBase & {
    type: MessageType.liveStreamStarted
    body: lsRoomInfo
  }

  export type liveStreamClosed = lsBase & {
    type: MessageType.liveStreamClosed
    body: lsRoomInfo
  }



  //typings...
  export interface startMessage extends Msg {
    userId: number
    body: {
      username: string
      userId: number
      profilePicUrl?: string
      type: string
      id: string
      tmpId: string
      body?: string
    }
  }

  export interface typing extends Msg {
    userId: number
    body: {
      username: string
      userId: number
      profilePicUrl?: string
      type?: string
      id: string
      body: string
    }
  }

  export interface imageSelected extends Msg {
    userId: number
    body: {
      id: string
      username: string
      userId: number
      profilePicUrl?: string
      type?: string
      imageWidth: number
      imageHeight: number
      previewBase64: number
    }
  }
}


import { useStore } from "@/application/store";
import { Message, v1 } from "@/types";
import { ulid } from "ulid";
import { createPreviewFromFile } from "../use/useImageUpload";
import { useWebSocketController } from "../use/useWebSocket";

export async function startMessage(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.startMessage,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      type: message.type,
      id: message.id,
      tmpId: message.id
    }),
    version: 1
  });
}

export async function imageSelected(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  const img = await createPreviewFromFile(message.imageFile!, 100, 100);
  //TODO: find message params???
  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.imageSelected,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      id: message.id,
      tmpId: message.tmpId,
      imageWidth: img.imageWidth,
      imageHeight: img.imageHeight,
      previewBase64: img.preview,
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      type: message.type
    }),
    version: 1
  });
}

export async function commentPosted(messageId: Number) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    type: v1.MessageType.commentPosted,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      messageId: messageId,
    }),
    version: 1
  });
}

export async function endComment(messageId: Number) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    type: v1.MessageType.endComment,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      messageId: messageId,
    }),
    version: 1
  });
}

export async function typingComment(commentBody: String, messageId: Number) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    type: v1.MessageType.typingComment,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      body: commentBody,
      messageId: messageId,
    }),
    version: 1
  });
}

// { "body": {"userId":20,"body":"Un","username":"Undo","id":"7762560A-EC2B-47D5-89CF-B41831F203CC"}", "id": "", "type": "typing", "userId": 20, "version": 1 }
export async function typing(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.typing,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      body: message.body,
      id: message.id,
      tmpId: message.id
    }),
    version: 1
  });
}

export async function endTyping(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.endTyping,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      body: message.body,
      id: message.id,
      tmpId: message.id
    }),
    version: 1
  });
}

// {"body":{"userId":20,"body":"Undo","username":"Undo","id":"7762560A-EC2B-47D5-89CF-B41831F203CC"}","id":"","type":"textPosted","userId":20,"version":1}
export async function textPosted(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.textPosted,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      body: message.body,
      id: message.id,
      tmpId: message.id
    }),
    version: 1
  });
}

// { "body": {"tmpId":"7762560A-EC2B-47D5-89CF-B41831F203CC","userId":20,"createdAt":"2021-11-04T10:39:30.000-04:00","user":{"id":20,"canCommentOnMessages":true,"igHandle":null,"canAudioChat":true,"canPostText":true,"canPostImage":true,"bio":"Berlin","image":{"preview":null,"imageWidth":640,"imageHeight":640,"url":"https:\\\/\\\/img.gs\\\/dvzcgkgkpx\\\/${width},quality=medium\\\/https:\\\/\\\/splay-uploads-dev.s3.amazonaws.com\\\/bb\\\/bb109f13-0632-4fc6-b44d-014df8395648.jpg","imageKey":"bb\\\/bb109f13-0632-4fc6-b44d-014df8395648.jpg","position":1},"canPostRelease":false,"canBroadcast":true,"shareUrl":"http:\\\/\\\/get-staging.splay.com\\\/u\\\/3fd4ae15-80bd-11ea-af0b-12556c2dfc3e","webChatEnabled":false,"name":"Undo","status":"active"},"username":"Undo","imageNotProcessed":true,"commentsCount":0,"publicUrl":"http:\\\/\\\/staging.splay.com\\\/Undo\\\/3689","type":"text","id":3689,"body":"Undo","tag":null,"mentions":[]}", "id": "", "type": "textPostedSuccess", "userId": 20, "version": 1 }
export async function textPostedSuccess(message: Message, tmpId: string) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.textPostedSuccess,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      tmpId,
      ...message
    }),
    version: 1
  });
}

// body: {userId: 20, username: 'Undo', id: 'EBAAB7B0-0251-4209-9188-74C6871786C8'}
// id: ""
// type: "endText"
// userId: 20
// version: 1
export async function endText(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.endText,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      id: message.id
    }),
    version: 1
  });
}

//{"body":{"tag":"","id":"D9270373-F731-452F-A3A6-65619092A06B","username":"Undo","body":"","userId":20}","id":"","type":"imagePosted","userId":20,"version":1}
export async function imagePosted(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.imagePosted,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      body: message.body,
      id: message.id,
      tmpId: message.id,
      tag: message.tag
    }),
    version: 1
  });
}
// {
//   "body": {
//       "username": "Undo",
//       "userId": 20,
//       "id": 3711
//   },
//   "id": "",
//   "type": "messageDeleted",
//   "userId": 20,
//   "version": 1
// }
export async function messageDeleted(message: Message) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.messageDeleted,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      body: message.body,
      id: message.id,
      tmpId: message.id
    }),
    version: 1
  });
}
//{"body":{"username":"Undo","progress":0.01,"id":"D9270373-F731-452F-A3A6-65619092A06B","userId":20}","id":"","type":"imageProgress","userId":20,"version":1}
export async function imageProgress(message: Message, progress: number) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.imageProgress,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      username: state.currentUser?.name,
      userId: state.currentUser?.id,
      progress,
      id: message.id,
      tmpId: message.id
    }),
    version: 1
  });
}

//// {
//   "body":{
//      "publicUrl":"http://staging.splay.com/Undo/3691",
//      "tmpId":"D9270373-F731-452F-A3A6-65619092A06B",
//      "imageNotProcessed":true,
//      "type":"photo",
//      "mentions":[

//      ],
//      "username":"Undo",
//      "userId":20,
//      "images":[
//         {
//            "urls":{
//               "original":"https://splay-uploads-dev.s3.amazonaws.com/5c/5c39784f-b8d5-4131-9797-0ec85e1e24df.jpg",
//               "high":"https://d2kth2a8r2pj9w.cloudfront.net/5c/5c39784f-b8d5-4131-9797-0ec85e1e24df.jpg/high.jpg",
//               "low":"https://d2kth2a8r2pj9w.cloudfront.net/5c/5c39784f-b8d5-4131-9797-0ec85e1e24df.jpg/low.jpg"
//            },
//            "position":1,
//            "imageKey":"5c/5c39784f-b8d5-4131-9797-0ec85e1e24df.jpg",
//            "preview":"/9j/4AAQSkZJRgABAQAAkACQAAD/4QCMRXhpZgAATU0AKgAA... ..k=",
//            "imageWidth":1080,
//            "imageHeight":1920,
//            "url":"https://img.gs/dvzcgkgkpx/${width},quality=medium/https://splay-uploads-dev.s3.amazonaws.com/5c/5c39784f-b8d5-4131-9797-0ec85e1e24df.jpg"
//         }
//      ],
//      "createdAt":"2021-11-04T11:38:44.000-04:00",
//      "id":3691,
//      "body":"",
//      "tag":null,
//      "user":{
//         "id":20,
//         "canCommentOnMessages":true,
//         "igHandle":null,
//         "canAudioChat":true,
//         "canPostImage":true,
//         "canPostText":true,
//         "bio":"Berlin",
//         "canPostRelease":false,
//         "image":{
//            "preview":null,
//            "imageWidth":640,
//            "position":1,
//            "url":"https://img.gs/dvzcgkgkpx/${width},quality=medium/https://splay-uploads-dev.s3.amazonaws.com/bb/bb109f13-0632-4fc6-b44d-014df8395648.jpg",
//            "imageKey":"bb/bb109f13-0632-4fc6-b44d-014df8395648.jpg",
//            "imageHeight":640
//         },
//         "canBroadcast":true,
//         "shareUrl":"http://get-staging.splay.com/u/3fd4ae15-80bd-11ea-af0b-12556c2dfc3e",
//         "webChatEnabled":false,
//         "status":"active",
//         "name":"Undo"
//      },
//      "commentsCount":0
//   },
//   "id":"",
//   "type":"imagePostedSuccess",
//   "userId":20,
//   "version":1
// }
export async function imagePostedSuccess(message: Message, tmpId: string) {
  const c = await useWebSocketController();
  const { state } = useStore();

  c.send({
    id: ulid(),
    feedId: message.feedId || 0,
    type: v1.MessageType.imagePostedSuccess,
    userId: state.currentUser?.id,
    body: JSON.stringify({
      tmpId,
      ...message
    }),
    version: 1
  });
}

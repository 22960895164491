<template>
  <div class="bubble-col" @touchstart.capture="setIsTouching(true)">
    <div 
      class="image" 
      v-for="(m, i) in messages"
      :key="m.id"
      :ref="`circle-${i + 1}`"
      :style="{ width: `${circleWidth}px`, height: `${circleWidth}px` }"
      @mouseenter="$emit('set-hover', m)"
      @mouseleave="$emit('clear-hover')"
    >
      <router-link 
        class="bg" 
        :ref="`bg-${i}`" 
        :style="{ backgroundColor: getBackgroundColor(), width: `${circleWidth}px`, height: `${circleWidth}px` }"
        :to="`/${m.user}/${m.id}`"
      >
      <img :src="m.image" alt="">
      </router-link>
    </div>
  </div>
</template>

<script>

import _random from "lodash/random"

import VirtualScroll from 'virtual-scroll'

export default {

  props: {
    messages: {
      type: Array,
      required: true
    },
    numRows: {
      type: Number,
      required: true
    },
    numCols: {
      type: Number,
      required: true
    },
    scrollFactor: {
      type: Number,
      required: true
    },
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    legalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      winWidth: window.innerWidth,
      yTransform: 0,
      scrollInterface: false,
      scrollInterval: false,
      lastScrollPos: 0,
      isTouching: false,
      touchTimeout: false,
      lastUpdate: Date.now(),
      pageVisible: false
    }
  },
  computed: {
    circleWidth() {
      return this.winWidth / this.numCols
    },
    colHeight() {
      return this.circleWidth * this.messages.length
    },
    scaleOffsets() {
      return this.messages.map(_ => Math.random() * 2 * Math.PI)
    },
    colorOffsets() {
      return this.messages.map(_ => Math.random() * 2 * Math.PI)
    },
    isMobile() {
      return window.innerWidth < 550
    },
    scaleSpeed() {
      return this.isMobile ? .02 : .005
    }
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn && this.isMobile) clearInterval(this.scrollInterval)
    },
    legalOpen(isOpen) {
      if (isOpen) cancelAnimationFrame(this.scrollInterval)
      else {
        this.lastUpdate = Date.now()
        this.scrollInterval = requestAnimationFrame(this.autoScroll)
      }
    }
  },
  mounted() {
    // if (!this.isMobile || (this.isMobile && !this.isLoggedIn)) this.startAutoAcroll()
    if (!this.legalOpen) this.scrollInterval = requestAnimationFrame(this.autoScroll)
    if (!this.isMobile) this.startVirtualScroll()
    else {
      this.$el.addEventListener('scroll', e => {
        const scrollPos = this.$el.scrollTop
        this.onScroll({ deltaY: this.lastScrollPos - scrollPos})
        this.lastScrollPos = scrollPos
        clearTimeout(this.touchTimeout)
        this.touchTimeout = setTimeout(() => {
          this.isTouching = false
        }, 100)
      })
    }
    window.addEventListener('visibilitychange', () => {
      this.pageVisible = !document.hidden
      if (document.hidden) cancelAnimationFrame(this.scrollInterval)
      else {
        this.lastUpdate = Date.now()
        this.scrollInterval = requestAnimationFrame(this.autoScroll)
      }
    })
  },
  unmounted() {
    cancelAnimationFrame(this.scrollInterval)
    if (this.scrollInterface) this.scrollInterface.destroy()
  },
  methods: {
    setIsTouching(isTouching) {
      this.isTouching = isTouching
    },
    autoScroll() {
      const elapsed = Date.now() - this.lastUpdate
      const pixelsToMove = elapsed / 10 // 1px every 10ms

      if (this.isMobile) {
        if (!this.isTouching){
          if (this.scrollFactor * pixelsToMove >= 1) {
            this.$el.scrollTop += (this.scrollFactor * pixelsToMove)
            this.lastUpdate = Date.now()
          }
        }
        else {
          this.lastUpdate = Date.now()
        }
      }
      else {
        const scrollAmt = -0.7 * this.scrollFactor * pixelsToMove
        this.onScroll({ deltaY: scrollAmt })
        this.lastUpdate = Date.now()
      }
      this.scrollInterval = requestAnimationFrame(this.autoScroll)
    },
    startVirtualScroll() {
      const scrollOptions = {
        mouseMultiplier: this.scrollFactor
      }
      this.scrollInterface = new VirtualScroll(scrollOptions)
      this.scrollInterface.on(e => {
        this.onScroll(e)
      })
    },
    onScroll(e) {
      this.yTransform += e.deltaY
      if (this.yTransform > 0) this.yTransform = 0
      if (!this.isMobile) this.$el.style.transform = `translateY(${this.yTransform}px)`
      // Update Y position of circles for infinite scroll
      let bottomOfScreen = Math.ceil((Math.abs(this.yTransform) + window.innerHeight) / this.circleWidth) % this.messages.length
      let topOfScreen = Math.ceil((Math.abs(this.yTransform)) / this.circleWidth) % this.messages.length
      if (0 > e.deltaY) {
        //scrolling Down
        const frame = Math.floor(Math.abs(this.yTransform / (this.colHeight + this.circleWidth))) + 1
        const elToShift = Math.floor(Math.abs(this.yTransform / this.circleWidth)) % (this.messages.length + 1)
        if (elToShift > 0) {
          for (let i = 0; i < elToShift; i++) {
            this.$refs[`circle-${i + 1}`][0].style.transform = `translateY(${this.colHeight * frame}px)`
          }
        }
      }
      else {
        //scrolling Up
        const frame = Math.floor(Math.abs(this.yTransform / this.colHeight)) + 1
        for (let i = 0; i < this.messages.length; i++) {
          if (bottomOfScreen < topOfScreen) {
            if ((i > bottomOfScreen && i < topOfScreen)) this.$refs[`circle-${i + 1}`][0].style.transform = `translateY(${this.colHeight * (frame - 1)}px)`
          }
          else if (i < topOfScreen || i > bottomOfScreen) this.$refs[`circle-${i + 1}`][0].style.transform = `translateY(${this.colHeight * (frame - 1)}px)`
        }
      }
      // Only update scale and BG color if in view 
      if (topOfScreen > 0) topOfScreen--
      if (bottomOfScreen < this.messages.length) bottomOfScreen++
      if (topOfScreen > bottomOfScreen) { // Edge case for when array of circles is looping 
        for (let i = topOfScreen; i < this.messages.length; i++) this.updateBubbleScale(i)
        for (let i = 0; i < bottomOfScreen; i++) this.updateBubbleScale(i)
      }
      else {
        for (let i = topOfScreen; i < bottomOfScreen; i++) this.updateBubbleScale(i)
      }
    },
    updateBubbleScale(idx) {
      const scale = ((Math.sin((this.yTransform  * (1/this.scrollFactor)* this.scaleSpeed + this.scaleOffsets[idx])) + 1) / 2)  * .85 + .15
      this.$refs[`bg-${idx}`][0].$el.style.transform = `scale(${scale})`
      const alpha = ((Math.sin((this.yTransform  * (1/this.scrollFactor)* this.scaleSpeed + this.colorOffsets[idx])) + 1) / 2) * 255
      if (!this.isLoggedIn) this.$refs[`bg-${idx}`][0].$el.style.backgroundColor = `rgb(${alpha}, ${alpha}, ${alpha})`
      this.$refs[`circle-${idx + 1}`][0].style.opacity = 1
    },
    getBackgroundColor() {
      const alpha = _random(100, 250)
      return `rgb(${alpha}, ${alpha}, ${alpha})`
    }
  }
}

</script>

<style lang="scss">
.bubble-col {
  position: relative;
  display: inline-block;
  vertical-align: top;
  &::-webkit-scrollbar {
    display: none;
  }
  .logged-in & {
    .image .bg {
      pointer-events: auto;
      // img {
      //   opacity: 1;
      // }
    }
  }
  .image {
    cursor: pointer;
    // will-change: transform;
    // border-radius: 100%;
    pointer-events: none;
    // overflow: hidden;
    opacity: 0;
    span {
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
    }
    .bg {
      border-radius: 100%;
      overflow: hidden;
      display: block;
      position: relative;
      pointer-events: none;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      // opacity: 0;
      border-radius: 100%;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 550px) {
    height: 100vh;
    overflow: auto;
  }
}
</style>
<template>
  <svg
    width="51px"
    height="90px"
    viewBox="0 0 51 90"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="splay-white"
        transform="translate(-533.000000, -250.000000)"
        :fill="fill"
        fill-rule="nonzero"
      >
        <g id="g2997" transform="translate(352.000000, 250.000000)">
          <g id="g3021" transform="translate(181.546900, 0.681200)">
            <polygon
              id="path3023"
              points="31.369 0.24 50.495 0.24 19.149 88.694 0.031 88.694"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script type="text/javascript">
export default {
  props: {
    fill: {
      type: String,
      default: "#EB222C"
    }
  }
};
</script>

<template>
  <div :class="['sub-menu user-edit menu', { disabled: submitting }]">
    <section @click="closeRoomMenu">
      <strong>
        <svg
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          class="arrow"
        >
          <path
            d="M21,11v1H5.64l6.72,6.72l-0.71,0.71L3.72,11.5l7.92-7.92l0.71,0.71L5.64,11H21z"
          ></path>
        </svg>
        Edit User</strong
      >
    </section>
    <section class="user-edit-form">
      <div class="user-avatar">
        <img :src="userImage" />
        <input type="file" ref="profileImageInput" @change="processNewImage" />
      </div>
      <div class="input-section">
        <span>Username</span>
        <input type="text" ref="userName" v-model="userName" />
      </div>
      <!-- <div class="input-section">
        <span>Instagram</span>
        <input type="text" ref="igHandle" v-model="igHandle" />
      </div> -->
      <button @click="submitUserData" class="save">{{ saveButtonText }}</button>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { imgURLImageoptim } from "../../utils";
import {
  uploadImage,
  uploadProgress,
  progressFunc,
  crop
} from "@/application/use/useImageUpload";
import imageCompression from "browser-image-compression";

export default defineComponent({
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newProfileImg: false,
      newProfileImgObj: false,
      submitting: false,
      saveButtonText: "Save",
      userName: this.currentUser.name,
      igHandle: this.currentUser.igHandle
    };
  },
  computed: {
    userImage() {
      return this.newProfileImg || this.getImgUrl(this.currentUser.image);
    }
  },
  methods: {
    closeRoomMenu() {
      this.$emit("closeSubMenu");
    },
    getImgUrl(image) {
      return imgURLImageoptim(image);
    },
    async processNewImage() {
      if (this.$refs.profileImageInput.value) {
        let iFile = this.$refs.profileImageInput.files?.item(0);
        const { file, dataURL } = await crop(iFile, 300, 300);
        this.newProfileImg = dataURL;
        this.newProfileImgObj = file;
        const src = await imageCompression.getDataUrlFromFile(file);
        this.newProfileImg = src;
      }
    },
    async submitUserData() {
      this.submitting = true;
      this.saveButtonText = "Submitting...";
      const userData = {};

      if (this.newProfileImgObj) {
        const image = await uploadImage(this.newProfileImgObj, progressFunc);
        userData.image = image;
      } else userData.image = this.currentUser.image;
      userData.igHandle = this.currentUser.igHandle
      userData.name = this.userName;
      const { currentUser, err } = await this.$store.dispatch(
        "submitCurrentUserForm",
        {
          obj: userData
        }
      );
      this.saveButtonText = "Done";
      this.submitting = false;
      setTimeout(() => (this.saveButtonText = "Save"), 1500);
    }
  }
});
</script>

<style lang="scss">
.sub-menu.user-edit {
  &.disabled {
    .user-edit-form {
      pointer-events: none;
      .save {
        opacity: 0.6;
      }
    }
  }
  .user-edit-form {
    border-top: 1px solid #ccc;
    .user-avatar {
      border-radius: 100%;
      width: 90px;
      height: 90px;
      margin: 0 auto;
      display: block;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
    .input-section {
      position: relative;
      padding-top: 12px;
      span {
        display: block;
        padding-bottom: 5px;
      }
      input {
        font-size: 15px;
        padding: 6px 8px;
        border-radius: 2px;
        border: none;
        width: calc(100% - 18px);
        border: 1px solid #ccc;
        &:focus {
          outline-color: #313131;
          outline-width: 1px;
        }
      }
    }
    .save {
      position: relative;
      width: 100%;
      background-color: red;
      border: none;
      color: white;
      font-size: 15px;
      padding: 8px;
      margin-top: 16px;
      font-weight: 600;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
</style>

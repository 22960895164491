import { RoomPeer } from "@/types/v2";
import ReconnectingWebSocket from "reconnectingwebsocket";
import { ID } from "../feeds/api";
import { connectWebScoket } from "../use/useWebSocket";
// import { bus, Feed, FeedToken } from "../store";
import cancelable, { timeout } from "./cancelable";
import Logger from "./Logger";
// import msg, { feedLeftResp, joinFeedResp, presenceFeedsSubscribeResp, WsErr, WsMsg } from "./msg";

const logger = new Logger("WSC")
const debug = logger.debugColor("lime")
const debug1 = logger.debugColor("#90EE90")
// const debug2 = logger.debugColor("pink")
const debugErr = logger.debugColor("red")

let _counter = 0

// const RPC_RESP = Symbol("rspc.resp")
// const FEED_NOTIFY = Symbol("feed.notify")
// const NOTIFY = Symbol("notify")

export interface PendingRPCCall<R = RPCResp> {
  start: Date,
  seq: number,
  handler(m: R): void
}

export interface RPCResp {
  id: string
  type: string
  msgId?: string
}

export declare interface WsReq {
  id: string
  msgId?: string
  roomId?: string
  roomType?: string
  type: string
  version: number
}

export declare interface WsMsg {
  uid: number
  id: string
  msgId?: string
  roomId: string
  roomType?: string
  type: string
  version: number
  feedId?: number
  peer?: RoomPeer,
  body?: string | { [key: string]: any }
  peerCount: number
}


export declare interface WsErr {
  id: ID
  type: string
  reason: string
  version: number
}

export function isWsErr(arg: WsErr | any): arg is WsErr {
  return arg && arg.reason && typeof arg.reason == "string"
}


export class WebSocketController {
  protected sock: ReconnectingWebSocket;
  protected pending: { [id: string]: PendingRPCCall; } = {};
  protected _uid!: number


  protected static RPC_RESP = Symbol("rspc.resp")
  protected static FEED_NOTIFY = Symbol("feed.notify")
  protected static NOTIFY = Symbol("notify")


  protected constructor(sock: ReconnectingWebSocket) {
    this.sock = sock;

    this.addSockEventListener()
  }

  addSockEventListener() {
    this.sock.addEventListener("message", (e) => {

      const m: WsMsg & { uid: number } = JSON.parse(e.data);
      if (m.type == "welcome") {
        this._uid = m.uid
      }

      if ("body" in m && typeof m.body == 'string' && m.body[0] == `{`) {
        m.body = JSON.parse(m.body)
        //@ts-ignore
        if ("meta" in m.body && typeof m.body.meta == 'string' && m.body.meta[0] == `{`) {
          m.body.meta = JSON.parse(m.body.meta)
        }
      }

      const reqId = m.msgId || m.id
      const pc = this.pending[reqId]
      if (pc) {
        if (typeof m.body == 'string' && m.body[0] == "{") {
          m.body = JSON.parse(m.body);
        }
        pc.handler(m)
        // this.emit(WebSocketController.RPC_RESP, m);
        // this.onRPC(m)
        delete (this.pending[reqId]);
      } else {
        if (m.type.startsWith("feed") || m.feedId && m.feedId != 0) {
          debug1(`<<F ${m.type}::${m.feedId}`, m)
          // this.emit(WebSocketController.FEED_NOTIFY, m);
          this.onFeedNotify(m)
        } else {
          debug1(`<<<< ${m.type}`, m)
          // this.emit(WebSocketController.NOTIFY, m);
          this.onNotify(m)
        }
      }
    });
  }

  get uid() {
    return this._uid
  }


  async reconnect() {
    this.sock = await connectWebScoket(true)
    this.addSockEventListener()
  }


  connected() {
    return this.sock.readyState == 1;
  }

  send(msg: any) {
    //@ts-ignore
    const t = msg.msg?.type ? `::${msg.msg?.type}` : "";
    debug1(`->${msg.type}${t}`, msg);

    this.sock.send(JSON.stringify(msg));
  }

  protected call<R extends RPCResp>(req: WsReq): PromiseLike<{ res?: R, err?: WsErr }> {
    const seq = _counter++;
    const start = new Date();

    let reqId = req.msgId || req.id

    this.pending[reqId] = { seq, start, handler: () => { } };

    const p = cancelable((resolve, reject) => {
      //TIMEOUT
      const tm = timeout(5000, {
        id: reqId,
        type: req.type,
        reason: "timeout",
        version: req.version
      });

      tm.then((err) => {
        const pc = this.pending[reqId];
        if (!pc) {

          return;
        }
        const dur = new Date().getTime() - pc.start.getTime();
        debugErr(`<- ${seq} ${req.type} ${dur}`, req);

        // this.off(WebSocketController.RPC_RESP, handler);
        delete this.pending[reqId]
        reject(err);
      });

      this.pending[reqId].handler = (m: R) => {
        // const resId = m.msgId || m.id
        // if (resId == reqId) {
        tm.cancel();
        // delete this.pending[reqId]
        // this.off(WebSocketController.RPC_RESP, handler);

        const pc = this.pending[reqId];
        if (!pc) {
          debugger;
          return;
        }
        //
        const dur = new Date().getTime() - pc.start.getTime();

        if (isWsErr(m)) {
          debugErr(`<- ${seq} ${m.type} +${dur}ms`, m);
          resolve({ err: m });
        } else {
          debug(`<- ${seq} ${m.type} +${dur}ms`, m);
          resolve({ res: m });
        }
        // }
      };
      // this.on(WebSocketController.RPC_RESP, handler);

      debug(`-> ${seq} ${req.type}`, req);
      this.sock.send(JSON.stringify(req));
    }) as PromiseLike<{ res?: R, err?: WsErr }>
    return p;
  }

  protected onNotify(m: WsMsg) { }
  protected onFeedNotify(m: WsMsg) { }

}

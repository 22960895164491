// Import pages
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from "vue-router";
import { loadCurrentUser } from "./api/user";
// import { routes as feedRoutes } from "./feeds/router";
import Logger from "./lib/Logger";
// import Home from "./pages/Home.vue";
import Post from "./pages/Post.vue";
import Privacy from "./pages/Privacy.vue";
import Profile from "./pages/Profile.vue";
import Terms from "./pages/Terms.vue";
import Archive from "./pages/Archive.vue";
import AppLanding from "./pages/AppLanding.vue"
// import VoiceChatDetail from "./pages/VoiceChatDetail.vue";
// import VoiceChats from "./pages/VoiceChats.vue";
import { useStore } from "./store";

// import Video from "./pages/Video.vue";
//@ts-ignore
// const Video = () => import("./pages/VideoStreamer.vue");
//@ts-ignore
//@ts-ignore
const UserSettings = () => import("./pages/UserSettings.vue");

async function requiresCurrentUser(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  try {
    const user = await loadCurrentUser();
    return next();
  } catch (e) {
    next({ name: "home" });
  }
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppLanding,
    name: "home"
  },
  {
    path: "/:user/:id",
    component: Post,
    name: "post"
  },
  {
    path: "/privacy",
    component: AppLanding,
    name: "privacy"
  },
  {
    path: "/terms",
    component: AppLanding,
    name: "terms"
  },
  // {
  //   path: "/channel/:feedId/broadcast-video",
  //   component: BroadcastVideo,
  //   name: "broadcastVideo"
  // },
  // {
  //   path: "/voice-chats",
  //   component: VoiceChats,
  //   name: "voice_chats"
  // },
  // {
  //   path: "/voice-chats/:id",
  //   component: VoiceChatDetail,
  //   name: "voice_chat_detail"
  // },
  // {
  //   path: "/edit-profile",
  //   component: UserSettings,
  //   name: "user_settings",
  //   beforeEnter: requiresCurrentUser,
  //   meta: { requiresCurrentUser: true }
  // },
  // ...feedRoutes,
  // {
  //   path: "/user/:name",
  //   redirect: to => {
  //     // the function receives the target route as the argument
  //     // return redirect path/location here.
  //     return { name: "profile", params: { name: to.params.name } };
  //   }
  // },
  {
    path: "/:name",
    component: Profile,
    name: "profile"
  }
];

const router = createRouter({ history: createWebHistory(), routes });
const logger = new Logger("router");

router.beforeEach((to, from, next) => {
  const store = useStore();
  if (from.name == "home")
    store.commit("uiSet", { prevScrollPosition: window.scrollY });
  else if (to.name != "home") store.commit("uiSet", { prevScrollPosition: 0 });
  if (from.name != "home" && to.name != "home") {
    // store.commit("setMessagePageNum", 0);
  }
  store.commit("uiSet", { prevView: from.name as string });
  store.commit("uiSet", { loading: true });

  document.body.classList.remove(`route-${from.name as string}`);
  document.body.classList.add(`route-${to.name as string}`);
  window.scrollTo({ top: 0, behavior: "smooth" });
  next();
});

export default router;

<template>
  <div class="page-privacy">
    <h2>Privacy Policy</h2>
    <div>Last Updated: March 10, 2023 Splay, LLC (“Splay,” “we,” “us,” or “our”)
    is an e-commerce platform provider that connects sellers and buyers of
    clothing, apparel, and other products. This Privacy Policy is designed to
    help you understand how we collect, use, and share your personal
    information, and to help you understand and exercise your privacy rights.</div> 
    <!-- <a href="#section1">1.
    SCOPE</a> <a href="#section2">2. PERSONAL INFORMATION WE COLLECT</a> <a href="#section3">3. HOW WE USE YOUR INFORMATION </a>
    <a href="#section4">4. HOW WE DISCLOSE YOUR INFORMATION</a> <a href="#section5">5. YOUR PRIVACY CHOICES AND RIGHTS</a> <a href="#section6">6.
    SECURITY OF YOUR INFORMATION</a> <a href="#section7">7. INTERNATIONAL DATA TRANSFERS</a> <a href="#section8">8. RETENTION OF
    PERSONAL INFORMATION</a> <a href="#section9">9. SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</a> <a href="#section10">10.
    SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</a> <a href="#section11">11. CHILDREN’S INFORMATION</a> <a href="#section12">12.
    OTHER PROVISIONS</a> <a href="#section13">13. CONTACT US</a> 
     -->
    <div id="section1">1. SCOPE <br>&emsp;This Privacy Policy applies to
    personal information processed by us, including on our websites, mobile
    applications, and other online or offline offerings. To make this Privacy
    Policy easier to read, our websites, mobile applications, and other
    offerings are collectively called “Services.” Splay is the controller for
    the personal information we process, unless otherwise stated. For
    information on how to contact us, please refer to “Contact Us” below.
    Important note: The sellers on our Services may process certain data of
    their own, which may contain personal information. The data that we process
    through our Services is processed by us on behalf of our sellers, and our
    privacy practices will be governed by the contracts that we have in place
    with our sellers, not this Privacy Policy. If you have any questions or
    concerns about how such data is handled or would like to exercise your
    rights, you should contact the person or entity (i.e., the data controller)
    who has contracted with us to use the Service to process this data. Our
    sellers control the personal information in these cases and determine the
    security settings within the account, its access controls and credentials.
    We will, however, provide assistance to our sellers to address any concerns
    you may have, in accordance with the terms of our contract with them. </div>
    <div id="section2">2.
    PERSONAL INFORMATION WE COLLECT<br>&emsp;The categories of personal information we
    collect depend on how you interact with us, our Services, and the
    requirements of applicable law. We collect information that you provide to
    us, information we obtain automatically when you use our Services, and
    information from other sources such as third-party services and
    organizations, as described below. Information You Provide to Us Directly We
    may collect the following personal information that you provide to us.
    Account Creation. Although an account is not required to use our Services or
    make purchases, an account is required to make posts on the Services. When
    you create a user account, we collect the information described in Section C
    below, along with your name, username, and password. Interactive Features.
    Our Services allow you to upload text, photos, videos, and other content;
    you may also upload listings of items available for purchase. We and others
    who use our Services may collect personal information that you submit or
    make available through our interactive features (e.g., messaging and chat
    features, commenting functionalities, forums, blogs, and social media
    pages). Any information you provide on the public sections of these features
    will be considered “public,” unless otherwise required by applicable law,
    and is not subject to the privacy protections referenced herein. Please
    exercise caution before revealing any information that may identify you in
    the real world to other users. Purchases. We may collect personal
    information and details associated with your purchases, including payment,
    shipping, and contact information. Any payments made via our Services are
    processed by third-party payment processors. We do not directly collect or
    store any payment card information entered through our Services, but may
    receive information associated with your payment card information (e.g.,
    your transaction details). Your Communications with Us. We may collect
    personal information, such as your name, email address, phone number, or
    mailing address when you request information about our Services, request
    customer or technical support, or otherwise communicate with us. Surveys. We
    may contact you to participate in surveys. If you decide to participate, you
    may be asked to provide certain information which may include personal
    information. Sweepstakes or Contests. We may collect personal information
    you provide for any sweepstakes or contests that we offer. In some
    jurisdictions, we are required to publicly share information of sweepstakes
    and contest winners. Information Collected Automatically We may collect
    personal information automatically when you use our Services: Automatic Data
    Collection. We may collect certain information automatically when you use
    our Services, such as your Internet protocol (IP) address, MAC address,
    cookie identifiers, mobile carrier, mobile advertising and other unique
    identifiers, details about your browser, operating system, or device,
    general location information (including approximate location derived from IP
    address), and Internet service provider. We may also automatically collect
    information regarding your use of our Services, such as pages that you visit
    before, during and after using our Services, information about the links you
    click, the types of content you interact with, the frequency and duration of
    your activities, and other information about how you use our Services. In
    addition, we may collect information that other people provide about you
    when they use our Services, including information about you when they tag
    you. Cookies, Pixel Tags/Web Beacons, and Other Technologies. We, as well as
    third parties that provide content, advertising, or other functionality on
    our Services, may use cookies, pixel tags, local storage, and other
    technologies (“Technologies”) to automatically collect information regarding
    your use of our Services. Cookies. Cookies are small text files placed in
    device browsers that store preferences and facilitate and enhance your
    experience. Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon)
    is a piece of code embedded in our Services that collects information about
    engagement on our Services. The use of a pixel tag allows us to record, for
    example, that a user has visited a particular web page or clicked on a
    particular advertisement. We may also include web beacons in e-mails to
    understand whether messages have been opened, acted on, or forwarded. Our
    uses of these Technologies fall into the following general categories:
    Operationally Necessary. This includes Technologies that allow you access to
    our Services, applications, and tools that are required to identify
    irregular website behavior, prevent fraudulent activity and improve security
    or that allow you to make use of our functionality; Performance-Related. We
    may use Technologies to assess the performance of our Services, including as
    part of our analytic practices to help us understand how individuals use our
    Services (see Analytics below); Functionality-Related. We may use
    Technologies that allow us to offer you enhanced functionality when
    accessing or using our Services. This may include identifying you when you
    sign into our Services or keeping track of your specified preferences,
    interests, or past items viewed; Advertising- or Targeting-Related. We may
    use first party or third-party Technologies to deliver content, including
    ads relevant to your interests, on our Services or on third-party websites.
    See “Your Privacy Choices and Rights” below to understand your choices
    regarding these Technologies. Analytics. We may use Technologies and other
    third-party tools to process analytics information on our Services, such as
    Google Analytics. For more information, please visit Google Analytics’
    Privacy Policy. To learn more about how to opt-out of Google Analytics’ use
    of your information, please click here. Social Media Platforms. Our Services
    may contain social media buttons such as Facebook, Twitter, or Instagram
    (that might include widgets such as the “share this” button or other
    interactive mini programs). These features may collect your IP address,
    which page you are visiting on our Services, and may set a cookie to enable
    the feature to function properly. Your interactions with these platforms are
    governed by the privacy policy of the company providing it. Information
    Collected from Other Sources We may obtain information about you from other
    sources, including through third-party services and organizations. For
    example, if you access our Services through a third-party application, such
    as an app store, a third-party login service such as Apple Pay, or a social
    networking site, we may collect information about you from that third-party
    application that you have made available via your privacy settings,
    including but not limited to your shipping address. </div><div id="section3">3. HOW WE USE YOUR
    INFORMATION <br>&emsp;We use your information for a variety of business purposes,
    including to provide our Services, for administrative purposes, and to
    market our products and Services, as described below. Provide Our Services
    We and our service providers use your information to fulfil our contract
    with you and provide you with our Services, such as: Managing your
    information and accounts; Providing access to certain areas,
    functionalities, and features of our Services; Answering requests for
    customer or technical support; Communicating with you about your account,
    activities on our Services, and policy changes; and Processing your
    financial information and other payment methods for products or Services
    purchased. Administrative Purposes We use your information for various
    administrative purposes, such as: Pursuing our legitimate interests such as
    direct marketing, research and development (including marketing research),
    network and information security, and fraud prevention; Detecting security
    incidents, protecting against malicious, deceptive, fraudulent or illegal
    activity, and prosecuting those responsible for that activity; Measuring
    interest and engagement in our Services; Short-term, transient use, such as
    contextual customization of ads; Improving, upgrading or enhancing our
    Services; Developing new products and Services; Ensuring internal quality
    control and safety; Authenticating and verifying individual identities,
    including requests to exercise your rights under this policy; Debugging to
    identify and repair errors with our Services; Auditing relating to
    interactions, transactions and other compliance activities; Enforcing our
    agreements and policies; and Complying with our legal obligations. Marketing
    and Advertising our Products and Services We may use personal information to
    tailor and provide you with content and advertisements. We may provide you
    with these materials as permitted by applicable law. Other Purposes We also
    use your information for other purposes as requested by you or as permitted
    by applicable law. Consent. We may use personal information for other
    purposes that are clearly disclosed to you at the time you provide personal
    information or with your consent. Automated Decision Making. We may engage
    in automated decision making, including profiling, such as to prevent fraud.
    Splay’s processing of your personal information will not result in a
    decision based solely on automated processing that significantly affects you
    unless such a decision is necessary as part of a contract we have with you,
    we have your consent, or we are permitted by law to engage in such automated
    decision making. If you have questions about our automated decision making,
    you may contact us as set forth in “Contact Us” below. De-identified and
    Aggregated Information. We may use personal information and other
    information about you to create de-identified and/or aggregated information,
    such as de-identified demographic information, de-identified location
    information, information about the device from which you access our
    Services, or other analyses we create. Share Content with Friends or
    Colleagues. Our Services may offer various tools and functionalities. For
    example, we may allow you to provide information about your friends through
    our referral services. Our referral services may allow you to forward or
    share certain content with a friend or colleague, such as an email inviting
    your friend to use our Services. </div><div id="section4">4. HOW WE DISCLOSE YOUR INFORMATION <br>&emsp;We
    disclose your information to third parties for a variety of business
    purposes, including to provide our Services, to protect us or others, or in
    the event of a major business transaction such as a merger, sale, or asset
    transfer, as described below. Disclosures to Provide our Services The
    categories of third parties with whom we may share your information are
    described below. Service Providers. We may share your personal information
    with our third-party service providers who use that information to help us
    provide our Services. This includes service providers that provide us with
    IT support, hosting, payment processing, customer service, and related
    services. Business Partners. We may share your personal information with
    business partners to provide you with a product or service you have
    requested. We may also share your personal information to business partners
    with whom we jointly offer products or services. Affiliates. We may share
    your personal information with our company affiliates for our administrative
    purposes, including activities such as IT management, for them to provide
    services to you, or support and supplement the Services we provide.
    APIs/SDKs. We may use third-party Application Program Interfaces (“APIs”)
    and Software Development Kits (“SDKs”) as part of the functionality of our
    Services. For more information about our use of APIs and SDKs, please
    contact us as set forth in “Contact Us” below. Disclosures to Protect Us or
    Others We may access, preserve, and disclose any information we store
    associated with you to external parties if we, in good faith, believe doing
    so is required or appropriate to: comply with law enforcement or national
    security requests and legal process, such as a court order or subpoena;
    protect your, our, or others’ rights, property, or safety; enforce our
    policies or contracts; collect amounts owed to us; or assist with an
    investigation or prosecution of suspected or actual illegal activity.
    Disclosure in the Event of Merger, Sale, or Other Asset Transfers If we are
    involved in a merger, acquisition, financing due diligence, reorganization,
    bankruptcy, receivership, purchase or sale of assets, or transition of
    service to another provider, your information may be sold or transferred as
    part of such a transaction, as permitted by law and/or contract. </div><div id="section5">5. YOUR
    PRIVACY CHOICES AND RIGHTS <br>&emsp;Your Privacy Choices. The privacy choices you may
    have about your personal information are determined by applicable law and
    are described below. Email Communications. If you receive an unwanted email
    from us, you can use the unsubscribe link found at the bottom of the email
    to opt out of receiving future emails. Note that you will continue to
    receive transaction-related emails regarding products or Services you have
    requested. We may also send you certain non-promotional communications
    regarding us and our Services, and you will not be able to opt out of those
    communications (e.g., communications regarding our Services or updates to
    our Terms or this Privacy Policy). Mobile Devices. We may send you push
    notifications through our mobile application. You may opt out from receiving
    these push notifications by changing the settings on your mobile device. “Do
    Not Track.” Do Not Track (“DNT”) is a privacy preference that users can set
    in certain web browsers. Please note that we do not respond to or honor DNT
    signals or similar mechanisms transmitted by web browsers. Cookies. You may
    stop or restrict the placement of Technologies on your device or remove them
    by adjusting your preferences as your browser or device permits. However, if
    you adjust your preferences, our Services may not work properly. Please note
    that cookie-based opt-outs are not effective on mobile applications.
    However, you may opt-out of personalized advertisements on some mobile
    applications by following the instructions for Android, iOS and others.
    Please note you must separately opt out in each browser and on each device.
    Your Privacy Rights. In accordance with applicable law, you may have the
    right to: Access Personal Information about you, including: (i) confirming
    whether we are processing your personal information; or (ii) obtaining
    access to or a copy of your personal information; Request Correction of your
    personal information where it is inaccurate, incomplete or outdated. In some
    cases, we may provide self-service tools that enable you to update your
    personal information; Request Deletion, Anonymization or Blocking of your
    personal information when processing is based on your consent or when
    processing is unnecessary, excessive or noncompliant; Request Restriction of
    or Object to our processing of your personal information when processing is
    noncompliant; Withdraw your Consent to our processing of your personal
    information. If you refrain from providing personal information or withdraw
    your consent to processing, some features of our Service may not be
    available; Request data portability and receive an electronic copy of
    personal information that you have provided to us; Be informed about third
    parties with which your personal information has been shared; and Request
    the review of decisions taken exclusively based on automated processing if
    that could affect data subject rights. If you would like to exercise any of
    these rights, please contact us as set forth in “Contact Us” below. We will
    process such requests in accordance with applicable laws. </div><div id="section6">6. SECURITY OF
    YOUR INFORMATION <br>&emsp;We take steps to ensure that your information is treated
    securely and in accordance with this Privacy Policy. Unfortunately, no
    system is 100% secure, and we cannot ensure or warrant the security of any
    information you provide to us. We have taken appropriate safeguards to
    require that your personal information will remain protected and require our
    third-party service providers and partners to have appropriate safeguards as
    well. To the fullest extent permitted by applicable law, we do not accept
    liability for unauthorized disclosure. By using our Services or providing
    personal information to us, you agree that we may communicate with you
    electronically regarding security, privacy, and administrative issues
    relating to your use of our Services. If we learn of a security system’s
    breach, we may attempt to notify you electronically by posting a notice on
    our Services, by mail or by sending an email to you. </div><div id="section7">7. INTERNATIONAL DATA
    TRANSFERS <br>&emsp;All information processed by us may be transferred, processed, and
    stored anywhere in the world, including, but not limited to, the United
    States or other countries, which may have data protection laws that are
    different from the laws where you live. We endeavor to safeguard your
    information consistent with the requirements of applicable laws. </div><div id="section8">8.
    RETENTION OF PERSONAL INFORMATION <br>&emsp;We store the personal information we
    collect as described in this Privacy Policy for as long as you use our
    Services or as necessary to fulfill the purpose(s) for which it was
    collected, provide our Services, resolve disputes, establish legal defenses,
    conduct audits, pursue legitimate business purposes, enforce our agreements,
    and comply with applicable laws. </div><div id="section9">9. SUPPLEMENTAL NOTICE FOR CALIFORNIA
    RESIDENTS <br>&emsp;This Supplemental California Privacy Notice only applies to our
    processing of personal information that is subject to the California
    Consumer Privacy Act of 2018 (“CCPA”). The CCPA provides California
    residents with the right to know what categories of personal information
    Splay has collected about them and whether Splay disclosed that personal
    information for a business purpose (e.g., to a service provider) in the
    preceding 12 months. California residents can find this information below:
    The categories of sources from which we collect personal information and our
    business and commercial purposes for using personal information are set
    forth in “Personal Information We Collect” and “How We Use Your Personal
    Information” above, respectively. “Sales” of Personal Information under the
    CCPA For purposes of the CCPA, Splay does not “sell” personal information,
    nor do we have actual knowledge of any “sale” of personal information of
    minors under 16 years of age. Additional Privacy Rights for California
    Residents Non-Discrimination. California residents have the right not to
    receive discriminatory treatment by us for the exercise of their rights
    conferred by the CCPA. Authorized Agent. Only you, or someone legally
    authorized to act on your behalf, may make a verifiable consumer request
    related to your personal information. To designate an authorized agent,
    please contact us as set forth in “Contact Us” below and provide written
    authorization signed by you and your designated agent. Verification. To
    protect your privacy, we will take steps the following steps to verify your
    identity before fulfilling your request. When you make a request, we will
    ask you to provide sufficient information that allows us to reasonably
    verify you are the person about whom we collected personal information or an
    authorized representative, which may include e.g., asking you to answer
    questions regarding your use of our Services. If you are a California
    resident and would like to exercise any of your rights under the CCPA,
    please contact us as set forth in “Contact Us” below. We will process such
    requests in accordance with applicable laws. Accessibility. This Privacy
    Policy uses industry-standard technologies and was developed in line with
    the World Wide Web Consortium’s Web Content Accessibility Guidelines,
    version 2.1. If you wish to print this policy, please do so from your web
    browser or by saving the page as a PDF. </div><div id="section10">10. SUPPLEMENTAL NOTICE FOR NEVADA
    RESIDENTS <br>&emsp;If you are a resident of Nevada, you have the right to opt-out of
    the sale of certain Personal Information to third parties who intend to
    license or sell that Personal Information. You can exercise this right by
    contacting us at privacy@splay.com with the subject line “Nevada Do Not Sell
    Request” and providing us with your name and the email address associated
    with your account. Please note that we do not currently sell your Personal
    Information as sales are defined in Nevada Revised Statutes Chapter 603A.
    </div><div id="section11">11. CHILDREN’S INFORMATION <br>&emsp;The Services are not directed to children under
    13 (or other age as required by local law), and we do not knowingly collect
    personal information from children. If you are a parent or guardian and
    believe your child has uploaded personal information to our site without
    your consent, you may contact us as described in “Contact Us” below. If we
    become aware that a child has provided us with personal information in
    violation of applicable law, we will delete any personal information we have
    collected, unless we have a legal obligation to keep it, and terminate the
    child’s account if applicable. </div><div id="section12">12. OTHER PROVISIONS <br>&emsp;Third-Party
    Websites/Applications. The Services may contain links to other
    websites/applications and other websites/applications may reference or link
    to our Services. These third-party services are not controlled by us. We
    encourage our users to read the privacy policies of each website and
    application with which they interact. We do not endorse, screen or approve,
    and are not responsible for, the privacy practices or content of such other
    websites or applications. Providing personal information to third-party
    websites or applications is at your own risk. Supervisory Authority. If you
    are located in the European Economic Area, Switzerland, the United Kingdom
    or Brazil, you have the right to lodge a complaint with a supervisory
    authority if you believe our processing of your personal information
    violates applicable law. Changes to our Privacy Policy. We may revise this
    Privacy Policy from time to time in our sole discretion. If there are any
    material changes to this Privacy Policy, we will notify you as required by
    applicable law. You understand and agree that you will be deemed to have
    accepted the updated Privacy Policy if you continue to use our Services
    after the new Privacy Policy takes effect. </div><div id="section13">13. CONTACT US <br>&emsp;If you have any
    questions about our privacy practices or this Privacy Policy, or to exercise
    your rights as detailed in this Privacy Policy, please contact us at: privacy@splay.com</div>
  </div>
</template>

<script>

import SvgSplayIntroLogo from "@/svg/SvgSplayIntroLogo.vue";

export default {
  components: {
    SvgSplayIntroLogo
  }
};
</script>

<style lang="scss">
.page-privacy {
  white-space: pre-line;
  padding: 160px 20px 20px;
  color: var(--black);
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1000;
  position: relative;
  background-color: var(--white);
  div {
    margin: 1em 0;
  }

  a {
    display: block;
    color: #008CBA;
  }

  @media only screen and (max-width: 700px) {
    font-size: 14px;
    line-height: 1.6;
  }
  @media only screen and (max-width: 550px) {
    padding: 60px 12px 12px;
  }
}
</style>

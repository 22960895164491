import { inject } from "vue";
import TWEEN from "@tweenjs/tween.js";

// css located in _notification.scss

export default {
  install(Vue, options) {
    const notificationContainer = document.createElement("div");
    notificationContainer.setAttribute("id", "splayNotifications");
    document.body.appendChild(notificationContainer);

    const animationLoop = () => {
      TWEEN.update();
      requestAnimationFrame(animationLoop);
    };
    animationLoop();

    const getNotificationEl = (text, width) => {
      const parser = new DOMParser();
      const template = `<svg class="notification" width="${width}px" height="50px" viewBox="0 0 ${width} 50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="#EB222C" points="16,0.24 ${width -
        0.5},0.24 ${width - 16.5},50 0.031,50"></polygon><text x="${width -
        140}" y="28" font-weight="500" fill="white">${text}</text></svg>`;
      const el = parser.parseFromString(template, "image/svg+xml").children[0];
      const polygon = el.children[0];
      const textEl = el.children[1];
      el.update = (width, opacity) => {
        el.setAttribute("width", `${width}px`);
        el.setAttribute("viewBox", `0 0 ${width} 50`);
        polygon.setAttribute(
          "points",
          `16,0.24 ${width - 0.5},0.24 ${width - 16.5},50 0.031,50`
        );
        textEl.setAttribute("x", `${width - 140}`);
        textEl.setAttribute("opacity", opacity);
      };
      return el;
    };

    const notification = async text => {
      let startWidth = window.innerWidth / 2 + 50;
      const el = getNotificationEl(text, startWidth);
      notificationContainer.appendChild(el);

      //Animations
      const transformUpdate = { transform: -100 };
      const slideAnimation = new TWEEN.Tween(transformUpdate)
        .to({ transform: 0 }, 400)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          el.style.transform = `translateX(${transformUpdate.transform}%)`;
        });

      const widthUpdate = { width: startWidth, opacity: 1 };
      const shrink = new TWEEN.Tween(widthUpdate)
        .to({ width: 29, opacity: 0 }, 400)
        .easing(TWEEN.Easing.Cubic.InOut)
        .delay(200)
        .onUpdate(() => {
          el.update(widthUpdate.width, widthUpdate.opacity);
        })
        .onComplete(() => el.remove());

      slideAnimation.chain(shrink).start();
      // slideAnimation.start();
    };

    Vue.config.globalProperties.$notify = notification;

    Vue.provide("Notification", notification);
  }
};

const useNotification = () => {
  return inject("Notification", undefined);
};

export { useNotification };

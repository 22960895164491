<template>
  <span :class="['message-body', { 'is-new': isNew }, { emoji: isEmoji }]" @click="trackClick">
    <span v-html="body" />
    <typing-cursor v-if="isTyping" />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Message } from "@/types";
import { isEmoji } from "../../utils";
import TypingCursor from "./TypingCursor.vue";

export default defineComponent({
  components: {
    TypingCursor,
  },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  data() {
    return {
      linkedBody: false,
    };
  },
  computed: {
    isNew() {
      return Message.isNew(this.message);
    },
    isTyping() {
      return this.message.typing;
    },
    isEmoji() {
      return this.message.body && this.message.body.length <= 10 && isEmoji(this.message.body);
    },
    formattedTime() {
      // 03.24.21, 05:16
      if (!this.message.createdAt) {
        return;
      } else if (typeof this.message.createdAt == "string") {
        //@ts-ignore
        const timeParts = this.message.createdAt.split("T");
        let date = timeParts[0].split("-");
        date = [date[1], date[2], date[0]].join(".");
        return `${date}, ${timeParts[1].split(".")[0].slice(0, -3)}`;
      } else if (typeof this.message.createdAt == "object") {
        return "";
      }
    },
    // backgroundLink() {
    //   return `<a class='background-link' url='/${encodeURIComponent(this.message.user.name)}/${
    //     this.message.id
    //   }'></a>`;
    // },
    body() {
      return Message.toHTML(this.message);
      // try {
      //   let body = this.message.body || this.formattedTime || "";
      //   this.message.mentions.map((mention) => {
      //     const target = body.substring(mention.start, mention.start + mention.length);
      //     body = body.replace(
      //       target,
      //       `<a url="/${encodeURIComponent(target.substring(1))}">${target}</a>`
      //     );
      //   });
      //   return body;
      // } catch {
      //   return this.message.body || "";
      // }
    },
  },
  methods: {
    trackClick(e: Event) {
      e.stopImmediatePropagation();
      //@ts-ignore
      if (e.path[0] && e.path[0].nodeName == "A") {
        //@ts-ignore
        const username = e.path[0].dataset.userName;
        this.$router.push({
          name: "profile",
          params: { name: username },
        });
        return;
      }

      if (!Message.isNew(this.message)) {
        this.$router.push({
          name: "post",
          params: { user: this.message.user.name, id: this.message.id },
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.message-body {
  font-weight: 300;
  line-height: 1.3;
  font-size: 18px;
  color: var(--black);
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  span:not(.typing-cursor) {
    // display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    :deep(.background-link) {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  :deep(a) {
    color: #eb222c;
    display: inline-block;
    position: relative;
    z-index: 100;
  }
}
</style>

<template>
  <div class="profile-pic" :style="style">
    <slot></slot>
    <img
      v-show="hasImageViaUrl && url != ''"
      :src="url"
      :width="width"
      :height="width"
      ref="pic"
      :class="[{ loaded: loaded }, { loading: !loaded }]"
    />
    <div
      v-if="!url && hasImageViaUrl"
      class="no-pic"
      :style="{ width: `${width}px`, height: `${width}px` }"
    ></div>
    <spinner
      class="pic-spinner"
      v-if="hasImageViaUrl && !loaded"
      :style="{ marginTop: `-${25 + width / 2}px` }"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import Spinner from "./Spinner.vue";
import { imgURLThumb } from "../utils";

export default defineComponent({
  components: { Spinner },
  props: {
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      require: false,
    },
    width: {
      type: Number,
      default: 100,
    },
    url: { type: String, required: false }, // If you don't pass a URL in you better pass something in for the slot.
  },
  computed: {
    hasImageViaUrl() {
      return this.url && this.url !== "";
    },
    style() {
      let s = {
        width: `${this.width}px`,
        height: `${this.width}px`,
        backgroundColor: this.backgroundColor,
        border: "none",
      };
      if (this.hasBorder) {
        s.border = `1px solid ${this.borderColor}`;
      }
      return s;
    },
  },
  setup(props, ctx) {
    const pic = ref<HTMLImageElement | null>(null);
    const loaded = ref(props.url == "");

    onMounted(() => {
      pic.value!.onload = () => {
        loaded.value = true;
        ctx.emit("pic-loaded");
      };
    });

    watch(
      () => props.url,
      (oldURL, newURL) => {
        loaded.value = false;
      }
    );

    return {
      loaded,
      url: computed(() => imgURLThumb({ url: props.url || "" }, props.width)),
      width: props.width,
      pic,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../variables";

.profile-pic {
  border-radius: 100%;
  overflow: hidden;
  img {
    &.loading {
      opacity: 0.6;
    }
  }
}

.no-pic {
  background: rgb(241, 241, 241);
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.pic-spinner {
  margin-top: -100px;
}
</style>

<template>
  <div class="form-image" :style="{ paddingBottom: `${aspectRatio * 100}%` }">
    <div class="progressbar" v-show="uploadProgress != 0">
      <circle-progress
        :percent="uploadProgress"
        :size="150"
        fill-color="#e92634"
        empty-color="#f7f7f700"
        :border-width="8"
        :border-bg-width="8"
      />
    </div>
    <img
      v-if="src"
      ref="imgRef"
      :src="src"
      :class="{ uploading: uploadProgress > 0 }"
    />
    <div @click="deleteImage" class="del-btn"><span>&times;</span></div>
    <!-- <div  class="edit-hint">
      click to change
      <input type="file" ref="fileRef" v-on:change="onFile" />
    </div> -->
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import imageCompression from "browser-image-compression";
//@ts-ignore
import CircleProgress from "vue3-circle-progress";
import { EditableImage } from "@/types/image";

const calcImageSize = (w: number, h: number, max = 400) => {
  const ratio = Math.min(max, screen.availWidth * 0.8) / w;
  return { w: w * ratio, h: h * ratio };
};

export default defineComponent({
  components: { CircleProgress },
  props: {
    editableImage: {
      type: Object as PropType<EditableImage>,
      required: true,
    },
  },
  emits: {
    editableImage(eImg: EditableImage) {
      return eImg;
    },

    fileDeleted(id: string) {
      return id;
    },
  },
  setup(props, { emit }) {
    const { editableImage } = props;
    const uploadProgress = ref(0);

    const imgRef = ref();
    const src = ref(editableImage.src);

    const width = ref(0);
    const height = ref(0);
    
    const aspectRatio = ref(0);

    const onFile = async (file: File) => {
      if (file) {
        src.value = await imageCompression.getDataUrlFromFile(file);
        const imgEl = await imageCompression.loadImage(src.value);
        const { w, h } = calcImageSize(imgEl.width, imgEl.height);
        width.value = w;
        height.value = h;
        
        aspectRatio.value = h / w;

        {
          const { width, height } = imgEl;
          emit("editableImage", { ...editableImage, file, width, height });
        }
      }
    };

    watch(editableImage, (e) => {
      uploadProgress.value = e.progress;
    });

    const deleteImage = (e: Event) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      emit("fileDeleted", editableImage.id);
    };

    onMounted(() => {
      const { w, h } = calcImageSize(editableImage.width, editableImage.height);
      width.value = w;
      height.value = h;
      aspectRatio.value = h / w
    });

    return {
      deleteImage,
      onFile,
      imgRef,
      uploadProgress,
      src,
      width,
      height,
      aspectRatio
    };
  },
});
</script>
<style lang="scss" scoped>
.form-image {
  user-select: none;
  position: relative;
  // min-width: 100px;
  // min-height: 100px;
  width: 100%;

  input[type="file"] {
    border: none;
    outline: none;
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    opacity: 0;
  }

  .progressbar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .del-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 95;
    color: white;
    font-size: 40px;
    height: 30px;
    width: 30px;
    cursor: pointer;

    background: rgba(0, 0, 0, 0.5);
    span {
      display: block;
      transform: translate(3px, -1px);
    }
  }

  .edit-hint {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 95;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    z-index: 90;
    top: 0;
    left: 0;
    pointer-events: none;

    &.uploading {
      opacity: 0.6;
    }
  }
}
</style>

import localStorageService from "@/application/lib/localStorageService";
import { WebSocketController, WsErr, WsMsg } from "@/application/lib/WebSocketController";
import { useStore as useMainStore } from "@/application/store";
import { Feed, feedsWS, feedWS, User, v1, v2 } from "@/types";
import type ReconnectingWebSocket from "reconnectingwebsocket";
import { TypedEmitter } from "tiny-typed-emitter";
import { ulid } from "ulid";
import { FeedToken, ID } from "../feeds/api";
import { imgURLLow } from "../utils";


const stage = () =>
  location.hostname
    .replace("www.", "prod")
    .replace(".com", "")
    .replace(".", "")
    .replace("splay", "")



const audioReq = (type: v2.MessageType, feedId: ID, data: { [k: string]: any } = {}) => ({
  ...data,
  uid: useMainStore().state.ws.uid,
  id: ulid(),
  type,
  roomId: `feed-${feedId}-audio-${stage()}`,
  feedId: feedId as number,
  peer: peer(),
  version: 2,
})


const videoReq = (type: v1.MessageType, feedId: ID, data: { [k: string]: any } = {}) => ({
  ...data,
  uid: useMainStore().state.ws.uid,
  id: `feed-${feedId}-video`,
  type,
  msgId: ulid(),
  feedId: feedId as number,
  peer: peer(),
  version: 1,
})

const peer = (): v2.RoomPeer => {
  const mainStore = useMainStore()

  const cu = mainStore.state.currentUser as User
  const uid = mainStore.state.ws.uid
  const deviceId = mainStore.getters.deviceId

  const profilePicUrl = cu.image && cu.image.url
  const { name, id: userId } = cu

  return { uid, name, userId, profilePicUrl, deviceId }
}


declare interface FeedsEvents {
  "feedsSubscribed": (reps: { res?: feedsWS.subscribed, err?: WsErr }) => void
  "feedsRooms": (reps: { res?: feedsWS.feedsRooms, err?: WsErr }) => void
  "feedJoined": (reps: { res?: feedWS.joined, err?: WsErr }) => void
  "feedLeft": (reps: { res?: feedWS.left, err?: WsErr }) => void
  "audioRoomCreated": (reps: { res?: v2.msg.roomCreated, err?: WsErr }) => void
  "audioRoomJoined": (reps: { res?: v2.msg.roomJoined, err?: WsErr }) => void
  "audioRoomLeft": (reps: { res?: v2.msg.roomLeft, err?: WsErr }) => void
  "audioRoomPublished": (reps: { res?: v2.msg.roomPublished, err?: WsErr }) => void
  "audioRoomUnpublished": (reps: { res?: v2.msg.roomUnpublished, err?: WsErr }) => void
  "audioRoomStartSpeaking": (reps: { res?: v2.msg.roomSpeaking, err?: WsErr }) => void
  "audioRoomStopSpeaking": (reps: { res?: v2.msg.roomStopSpeaking, err?: WsErr }) => void
  "notify": (r: { id: string, type: string, version: number } & { [k: string]: any }) => void
  "feed.notify": (r: { id: string, type: string, version: number } & { [k: string]: any }) => void
  "videoRoomCreated": (reps: { res?: v1.msg.liveStreamCreatedSuccess, err?: WsErr }) => void
  "videoRoomJoined": (reps: { res?: v1.msg.liveStreamJoined, err?: WsErr }) => void
  "videoRoomLeft": (reps: { res?: v1.msg.liveStreamLeft, err?: WsErr }) => void
  "videoRoomStarted": (reps: { res?: v1.msg.liveStreamStarted, err?: WsErr }) => void
  "videoRoomAnnounce": (payload: { reaction: v1.liveStreamReaction; feedId: number; roomId: string }) => void
}

export class WSBus extends TypedEmitter<FeedsEvents> {
}

export const bus = new WSBus()

export class WsController extends WebSocketController {
  private static _instance: WsController;

  private constructor(sock: ReconnectingWebSocket) {
    super(sock)
  }

  onNotify(m: WsMsg) {
    bus.emit("notify", m)
    if (m.type == v1.MessageType.liveStreamAnnounce) {
      bus.emit("videoRoomAnnounce", { feedId: m.feedId!, roomId: m.id, ...m.body as { reaction: v1.liveStreamReaction } })
    }
  }

  onFeedNotify(m: WsMsg) {
    bus.emit("feed.notify", m)
    if (m.type == v1.MessageType.liveStreamAnnounce) {
      bus.emit("videoRoomAnnounce", { feedId: m.feedId!, roomId: m.id, ...m.body as { reaction: v1.liveStreamReaction } })
    }
  }

  public static getInstance(sock: ReconnectingWebSocket) {
    // Do you need arguments? Make it a regular static method instead.
    return this._instance || (this._instance = new this(sock));
  }

  //PRC
  async feedsSubscribe(token: FeedToken) {
    const res = await this.call<feedsWS.subscribed>(feedsWS.feedsSubscribe(token));
    bus.emit("feedsSubscribed", res)
    return res
  }

  async feedsGetRooms(token: FeedToken) {
    const res = await this.call<feedsWS.feedsRooms>(feedsWS.feedsGetRooms(token));
    bus.emit("feedsRooms", res)

    return res
  }

  async feedJoin(feed: Feed, token: FeedToken) {
    const res = await this.call<feedWS.joined>(feedsWS.joinFeed(feed, token));
    bus.emit("feedJoined", res)
    return res
  }

  async feedLeave(feed: Feed) {
    const res = await this.call<feedWS.left>(feedsWS.feedLeave(feed));
    bus.emit("feedLeft", res)
    return res
  }

  //voiceChat
  async audioRoomCreate(feedId: ID) {
    const accessToken = localStorageService.getAccessToken()
    let req = audioReq(v2.MessageType.roomCreate, feedId, { accessToken })

    const res = await this.call<v2.msg.roomCreated>(req);
    bus.emit("audioRoomCreated", res)
    return res
  }

  async audioRoomJoin(feedId: ID) {
    const accessToken = localStorageService.getAccessToken()
    let req = audioReq(v2.MessageType.roomJoin, feedId, { accessToken })

    const res = await this.call<v2.msg.roomJoined>(req)
    bus.emit("audioRoomJoined", res)
    return res
  }

  async audioRoomLeave(feedId: ID) {
    let req = audioReq(v2.MessageType.roomLeave, feedId)
    const res = await this.call<v2.msg.roomLeft>(req);

    bus.emit("audioRoomLeft", res)
    return res
  }

  async audioRoomPublish(feedId: ID) {
    const { state } = useMainStore()
    const currentUser = state.currentUser

    let req = audioReq(v2.MessageType.roomPublish, feedId, { userId: currentUser!.id })
    const res = await this.call<v2.msg.roomPublished>(req);
    bus.emit("audioRoomPublished", res)
    return res
  }

  async audioRoomUnpublish(feedId: ID) {
    const { state } = useMainStore()
    const currentUser = state.currentUser

    let req = audioReq(v2.MessageType.roomUnpublish, feedId, { userId: currentUser!.id })
    const res = await this.call<v2.msg.roomUnpublished>(req);

    bus.emit("audioRoomUnpublished", res)
    return res
  }

  async audioRoomStartSpeaking(feedId: ID, at: number) {
    const { state } = useMainStore()
    const currentUser = state.currentUser

    let req = { ...audioReq(v2.MessageType.roomSpeaking, feedId), userId: currentUser!.id, at }
    this.send(req);
    bus.emit("audioRoomStartSpeaking", { res: { ...req, roomType: "audio" } })
    return { res: req, err: undefined }
  }

  async audioRoomStopSpeaking(feedId: ID, at: number) {
    const { state } = useMainStore()
    const currentUser = state.currentUser

    let req = { ...audioReq(v2.MessageType.roomStopSpeaking, feedId), userId: currentUser!.id, at }
    this.send(req);
    bus.emit("audioRoomStopSpeaking", { res: { ...req, roomType: "audio" } })
    return { res: req, err: undefined }
  }

  //VIDEO
  async videoRoomCreate(feedId: ID) {
    const accessToken = localStorageService.getAccessToken()
    const { state } = useMainStore()
    const user = state.currentUser

    const body = JSON.stringify({ username: user?.name, userId: user?.id, profilePic: imgURLLow(user?.image.url || "") })

    let req = videoReq(v1.MessageType.liveStreamCreate, feedId, { accessToken, body })

    const res = await this.call<v1.msg.liveStreamCreatedSuccess>(req);
    bus.emit("videoRoomCreated", res)
    return res
  }

  async videoRoomJoin(feedId: ID, roomID: string) {
    const accessToken = localStorageService.getAccessToken()
    let req = videoReq(v1.MessageType.liveStreamJoin, feedId, { accessToken })
    req.id = roomID

    const res = await this.call<v1.msg.liveStreamJoined>(req);
    bus.emit("videoRoomJoined", res)
    return res
  }

  async videoRoomLeave(feedId: ID) {
    const accessToken = localStorageService.getAccessToken()
    let req = videoReq(v1.MessageType.liveStreamLeave, feedId)

    const res = await this.call<v1.msg.liveStreamLeft>(req);
    bus.emit("videoRoomLeft", res)
    return res
  }

  //{"type":"liveStreamStarted","id":"E3B0BA26-2D28-4DF5-8E30-9579E16AD005_33FFA0D2","msgId":"","feedId":0,"uid":1013,"body":"{\"id\":\"E3B0BA26-2D28-4DF5-8E30-9579E16AD005_33FFA0D2\",\"recId\":\"01FPYJTE5B3C9YPPGGMT6Z76WC\",\"createdByDeviceId\":\"E3B0BA26-2D28-4DF5-8E30-9579E16AD005\",\"count\":3,\"created\":\"2021-12-15T09:19:57.525606+01:00\",\"meta\":\"{\\\"userId\\\":69,\\\"username\\\":\\\"jana\\\"}\",\"started\":true,\"type\":\"video\",\"peers\":null}","version":1}
  async videoRoomStarted(feedId: ID, roomId: string) {
    const { state } = useMainStore()
    const user = state.currentUser

    const body = JSON.stringify({ username: user?.name, userId: user?.id, profilePic: imgURLLow(user?.image.url || "") })

    let req = videoReq(v1.MessageType.liveStreamStarted, feedId, { body })
    req.id = roomId
    this.send(req);
    // bus.emit("videoRoomStarted", req)
    return Promise.resolve({ res: req })
  }

  async videoRoomReaction(feedId: ID, roomId: string, emoji: string) {
    const { state } = useMainStore()
    const user = state.currentUser!
    const reaction = {
      id: ulid(),
      dimension: 68.404853694114252,
      userId: user.id,
      username: user.name,
      duration: 3.189394321280826,
      yShift: 162.96426498345468,
      createdAt: new Date(),
      body: emoji
    }

    const body = JSON.stringify({ reaction })

    let req = videoReq(v1.MessageType.liveStreamAnnounce, feedId, { body })
    req.id = roomId
    this.send(req);

    return Promise.resolve({ res: { reaction }, err: undefined })
  }
}

<template>
  <div
    :class="[
      'archive-home',
      { 'single-message': isSingleMessage },
      { 'intro-active': introActive },
      { 'search-open': showYearSearch }
    ]"
  >
    <div class="background-lines">
      <div class="line" v-for="line in 100" />
    </div>
    <div class="sidebar" @click.self="setYearSearchClick(false)">
      <span
        :class="['active-year', { all: displayYear == '2011' }]"
        v-text="displayYear == '2011' ? 'ALL' : displayYear"
        @click.self="setYearSearchClick(!showYearSearch)"
      />
      <div class="active-month">
        <div class="month-pagination next-month" @click.self="changeMonth(1)">
          <span>&#9700;</span>
        </div>
        <div
          class="month"
          v-text="monthLookup[activeMonth - 1]"
          @click.self="toggleMonthSearch"
        />
        <div class="month-pagination prev-month" @click.self="changeMonth(-1)">
          <span>&#9700;</span>
        </div>
      </div>
      <div v-if="isSingleMessage" class="back-button" @click="navToGrid">
        <arrow-left />
      </div>
      <span class="year-search-container">
        <span class="title">bbs</span>
      </span>
      <logo-black
        class="logo"
        ref="logo"
        :style="{ transform: `translateY(${logoOffset}px)` }"
        @click.native="navToMessageList"
      />
    </div>
    <div :class="['year-search', { active: showYearSearch }]">
      <div class="wrapper year" v-show="!showMonthSearch">
        <span
          v-for="year in filteredSearchableYears"
          :class="['link', { active: year == activeYear }]"
          @click="searchForYear(year)"
          >20{{ year }}</span
        >
        <span
          :class="['link', { active: activeYear == 11 }]"
          @click="searchForYear(11)"
          >ALL</span
        >
      </div>
      <div class="wrapper month" v-show="showMonthSearch">
        <span
          :class="['link', { active: 1 == activeMonth }]"
          @click="searchForMonth(1)"
          >jan</span
        >
        <span
          :class="['link', { active: 2 == activeMonth }]"
          @click="searchForMonth(2)"
          >feb</span
        >
        <span
          :class="['link', { active: 3 == activeMonth }]"
          @click="searchForMonth(3)"
          >mar</span
        >
        <span
          :class="['link', { active: 4 == activeMonth }]"
          @click="searchForMonth(4)"
          >apr</span
        >
        <span
          :class="['link', { active: 5 == activeMonth }]"
          @click="searchForMonth(5)"
          >may</span
        >
        <span
          :class="['link', { active: 6 == activeMonth }]"
          @click="searchForMonth(6)"
          >june</span
        >
        <span
          :class="['link', { active: 7 == activeMonth }]"
          @click="searchForMonth(7)"
          >july</span
        >
        <span
          :class="['link', { active: 8 == activeMonth }]"
          @click="searchForMonth(8)"
          >aug</span
        >
        <span
          :class="['link', { active: 9 == activeMonth }]"
          @click="searchForMonth(9)"
          >sept</span
        >
        <span
          :class="['link', { active: 10 == activeMonth }]"
          @click="searchForMonth(10)"
          >oct</span
        >
        <span
          :class="['link', { active: 11 == activeMonth }]"
          @click="searchForMonth(11)"
          >nov</span
        >
        <span
          :class="['link', { active: 12 == activeMonth }]"
          @click="searchForMonth(12)"
          >dec</span
        >
      </div>
      <div class="line-wrapper">
        <span class="link" v-for="i in 20" v-html="'&nbsp'" />
      </div>
    </div>
    <div
      v-show="!introActive"
      :class="['messages', { 'no-background': !messages.length }]"
    >
      <span class="query-stats" v-if="messages.length">
        <span v-if="messages.length && !showLoadingMessage"
          >{{ messageCount }} messages</span
        >
        <span v-else>loading...</span>
      </span>
      <input
        type="search"
        placeholder="search..."
        autocomplete="off"
        v-model="textQuery"
        @input="textSearch"
        :class="{ searching: loadingMessages }"
      />
      <div
        class="archive-message"
        v-for="message in messages"
        :key="message.id"
        ref="message"
        :data-date="message.postedAt"
        data-observed="false"
        :id="`message-${message.id}`"
      >
        <div class="message-header" @click="setSingleMessage(message.id)">
          <span class="author">
            <strong v-html="message.author" />
          </span>
          <span class="timestamp" v-html="parsePostedDate(message)" />
        </div>
        <div class="message-body">
          <p class="message-text" ref="body" v-html="message.body" />
        </div>
      </div>
      <div class="no-messages" v-show="!showLoadingMessage && !messages.length">
        no messages found
      </div>
    </div>
    <div class="intro" v-show="introActive">
      <input
        type="search"
        placeholder="search..."
        autocomplete="off"
        v-model="textQuery"
        @input="textSearch"
        :class="{ searching: loadingMessages }"
      />
      <div class="date-teaser">
        <strong @click="setYearSearchClick(!showYearSearch)">time</strong>a
        decade reference
      </div>
      <div class="tagline">
        for you and yours
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LogoBlack from "../components/svgs/LogoBlack.vue";
import ArrowLeft from "../components/svgs/ArrowLeft.vue";
import v2 from "../api/v2";
import _throttle from "lodash/throttle";
import _isEmpty from "lodash/isEmpty";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default defineComponent({
  components: {
    LogoBlack,
    ArrowLeft
  },
  data: () => ({
    introActive: true,
    loadingMessages: false,
    showLoadingMessage: false,
    messages: [],
    searchableYears: [],
    textQuery: "",
    maxPages: 99,
    messageCount: false,
    page: 1,
    activeYear: "",
    activeMonth: false,
    queryYear: "11",
    queryMonth: "12",
    textSearchTimeout: false,
    showYearSearch: false,
    showMonthSearch: false,
    isSingleMessage: false,
    logoOffset: 0,
    monthLookup: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    abbvMonthLookup: {
      Dec: 12,
      Nov: 11,
      Oct: 10,
      Sep: 9,
      Aug: 8,
      Jul: 7,
      Jun: 6,
      May: 5,
      Apr: 4,
      Mar: 3,
      Feb: 2,
      Jan: 1
    },
    inViewMessages: [],
    newestMessageId: -1,
    intersectionObserver: false,
    headerFontSize: window.innerWidth < 600 ? 14 : 18,
    bodyFontSize: 10,
    dateFontSize: 10,
    vertOffset: 0,
    lastUrl: ""
  }),
  watch: {
    introActive(isActive) {
      if (isActive) {
        document.body.style.height = `${window.innerHeight}px`;
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "";
        document.body.style.overflow = "";
      }
    },
    inViewMessages(messages) {
      if (!messages.length) return;
      const newestMessage = messages.reduce((maxDate, date) => {
        if (!maxDate) return dayjs(date, "MMM DD, HH:mm, YYYY");
        else {
          const parsedDate = dayjs(date, "MMM DD, HH:mm, YYYY");
          if (maxDate.isBefore(parsedDate)) return parsedDate;
          else return maxDate;
        }
      }, false);
      this.activeYear = newestMessage.format("YY");
      this.activeMonth = newestMessage.format("M");
    },
    "$route.fullPath"(newPath, oldPath) {
      this.lastUrl = oldPath;
    },
    "$route.query"(query) {
      let performSearch = false;
      if (query.month && query.month != this.queryMonth) {
        this.queryMonth = query.month;
        performSearch = true;
        this.introActive = false;
      }
      if (query.year && query.year != this.queryYear) {
        this.queryYear = query.year;
        performSearch = true;
        this.introActive = false;
      }
      if (query.text && query.text != this.textQuery) {
        this.textQuery = query.text;
        performSearch = true;
        this.introActive = false;
      }
      if (query.message) {
        this.getMessage(query.message);
        this.introActive = false;
      } else if (performSearch) {
        this.messages = [];
        document.body.style.height = "";
        document.body.style.overflow = "";
        this.isSingleMessage = false;
        this.showLoadingMessage = true;
        this.logoOffset = 0;
        this.doSearch(true, true);
      }
      this.$gtag.pageview("/archive" + this.$router.fullPath);
    }
  },
  computed: {
    filteredSearchableYears() {
      return this.searchableYears.filter(year => parseInt(year) < 11);
    },
    displayYear() {
      if (!this.activeYear) return "";
      else if (this.activeYear > 30) return `19${this.activeYear}`;
      else return `20${this.activeYear}`;
    },
    formattedSearchParams() {
      const params = {
        page: this.page
      };
      if (!this.queryYear) {
        this.queryYear = "11";
        this.queryMonth = "12";
      }
      params["q[posted_at_gt]"] = `1-1-1990`;
      const queryMonth =
        this.queryMonth == "12" ? 1 : parseInt(this.queryMonth) + 1;
      let queryYear = this.queryYear;
      if (this.queryMonth == "12") {
        if (parseInt(this.queryYear) < 9)
          queryYear = `0${parseInt(this.queryYear) + 1}`;
        else if (parseInt(this.queryYear) == 99) queryYear = "00";
        else queryYear = parseInt(this.queryYear) + 1;
      }
      if (queryYear > 30) queryYear = `19${queryYear}`;
      else queryYear = `20${queryYear}`;
      params["q[posted_at_lt]"] = `1-${queryMonth}-${queryYear}`;
      params["q[author_or_body_cont]"] = this.textQuery || "";
      return params;
    }
  },
  created() {
    this.intersectionObserver = new IntersectionObserver(
      e => {
        e.forEach(el => {
          if (el.isIntersecting) {
            this.addToView(el.target.dataset.date);
          } else this.removeFromView(el.target.dataset.date);
        });
      },
      { threshold: 1 }
    );

    document.body.style.minHeight = "unset";
    // Get URL query params
    const query = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    if (query.text) this.textQuery = query.text;
    if (query.year) this.searchForYear(query.year.slice(-2));
    if (query.month) this.searchForMonth(query.month);

    //Determind how to load data
    if (query.message) {
      this.introActive = false;
      this.getMessage(query.message);
    } else if (!_isEmpty(query)) {
      this.introActive = false;
      this.doSearch(true, true);
    } else this.initLoad();

    if (this.introActive) {
      document.body.style.height = `${window.innerHeight}px`;
      document.body.style.overflow = "hidden";
    }
    this.$gtag.pageview("/archive" + this.$router.fuillPath);
  },
  mounted() {
    window.addEventListener(
      "scroll",
      _throttle(e => {
        this.handleBottomScroll();
      }, 300)
    );
  },
  methods: {
    navToGrid() {
      if (this.lastUrl)
        this.$router.push(
          `${this.lastUrl}&scrollTo=${this.$route.query.message}`
        );
      else this.navToMessageList();
    },
    parsePostedDate(message) {
      if (this.isSingleMessage) {
        const d = dayjs(message.posted_at);
        return `${d.format("MMMM DD, YYYY")} at ${d.format("h:mma")}`;
      } else return message.postedAt;
    },
    setSingleMessage(id) {
      this.$router.push(`/?message=${id}`);
    },
    setObservers() {
      this.$refs.message.forEach(m => {
        if (m.dataset.observed == "false") {
          this.intersectionObserver.observe(m);
          m.setAttribute("data-observed", true);
        }
      });
    },
    changeMonth(monthChange) {
      this.activeMonth = monthChange + parseInt(this.activeMonth);
      if (this.activeMonth > 12) {
        this.activeMonth = "1";
        if (this.activeYear == "99") this.activeYear = "00";
        else {
          this.activeYear = parseInt(this.activeYear) + 1;
          if (this.activeYear < 10) this.activeYear = "0" + this.activeYear;
        }
      } else if (this.activeMonth < 1) {
        this.activeMonth = "12";
        if (this.activeYear == "00") this.activeYear = "99";
        else this.activeYear = parseInt(this.activeYear) - 1;
        if (this.activeYear < 10) this.activeYear = "0" + this.activeYear;
      }
      this.queryMonth = this.activeMonth;
      this.queryYear = this.activeYear;

      this.page = 1;
      this.showYearSearch = false;
      this.showMonthSearch = false;

      this.doSearch(true, true);
      this.showLoadingMessage = true;
    },
    addToView(data) {
      this.inViewMessages.push(data);
    },
    removeFromView(date) {
      this.inViewMessages = this.inViewMessages.filter(d => {
        return d != date;
      });
    },
    navToMessageList() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.showLoadingMessage = true;
      this.messages = [];
      this.queryMonth = false;
      this.queryYear = false;
      this.activeYear = false;
      this.activeMonth = false;
      this.textQuery = "";
      this.logoOffset = 0;
      this.$router.push("/");
      this.isSingleMessage = false;
      this.introActive = true;
      this.initLoad();
    },
    async getMessage(messageId) {
      this.isSingleMessage = true;
      this.queryMonth = false;
      this.queryYear = false;
      this.textQuery = "";
      this.messages = [];
      this.showLoadingMessage = true;
      const response = await v2.get("/archive_messages/get_by_id", {
        params: { id: messageId }
      });
      response.data.message.postedAt = response.data.date;
      this.showLoadingMessage = false;
      this.messages = [response.data.message];
    },
    setYearSearchClick(isActive) {
      this.showYearSearch = isActive;
      if (!isActive) this.showMonthSearch = false;
    },
    toggleMonthSearch() {
      this.showYearSearch = true;
      this.showMonthSearch = true;
    },
    textSearch() {
      this.showLoadingMessage = true;
      this.loadingMessages = false;
      clearTimeout(this.textSearchTimeout);
      this.textSearchTimeout = setTimeout(() => {
        this.page = 1;
        // TODO: HERE
        this.showYearSearch = false;
        this.showMonthSearch = false;
        if (this.isSingleMessage) this.messages = [];
        this.isSingleMessage = false;
        // this.activeYear = 11;
        this.queryYear = 11;
        // this.activeMonth = "12";
        this.queryMonth = "12";
        this.doSearch(true, true);
      }, 500);
    },
    setNameSearch(name) {
      this.page = 1;
      this.activeYear = 11;
      this.queryYear = 11;
      this.activeMonth = "12";
      this.queryMonth = "12";
      this.textQuery = name;
      this.doSearch(true, true);
      this.showLoadingMessage = true;
    },
    async initLoad() {
      const response = await v2.get("/archive_messages", {
        params: {
          "q[posted_at_gt]": "1-1-1990",
          "q[posted_at_lt]": "1-1-2012",
          page: 1
        }
      });

      this.searchableYears = response.data.searchableYears;
    },
    highlightQuery(m) {
      m.author = m.author.replaceAll(
        this.textQuery.toLowerCase(),
        `<span class="highlight">${this.textQuery.toLowerCase()}</span>`
      );
      m.body = m.body.replaceAll(
        this.textQuery.toLowerCase(),
        `<span class="highlight">${this.textQuery.toLowerCase()}</span>`
      );
      /////
      m.author = m.author.replaceAll(
        this.textQuery.toUpperCase(),
        `<span class="highlight">${this.textQuery.toUpperCase()}</span>`
      );
      m.body = m.body.replaceAll(
        this.textQuery.toUpperCase(),
        `<span class="highlight">${this.textQuery.toUpperCase()}</span>`
      );
      /////
      const capitalizedQuery =
        this.textQuery.charAt(0).toUpperCase() + this.textQuery.slice(1);
      m.author = m.author.replaceAll(
        capitalizedQuery,
        `<span class="highlight">${capitalizedQuery}</span>`
      );
      m.body = m.body.replaceAll(
        capitalizedQuery,
        `<span class="highlight">${capitalizedQuery}</span>`
      );
      return m;
    },
    async doSearch(resetScroll, resetMessages) {
      this.introActive = false;
      if (this.loadingMessages || this.maxPages < this.page) return;

      // Reset font sizes
      (this.headerFontSize = window.innerWidth < 600 ? 14 : 18),
        (this.bodyFontSize = 10);
      this.dateFontSize = 10;
      this.vertOffset = 0;

      const currentTextQuery = this.textQuery;

      this.loadingMessages = true;

      const response = await v2.get("/archive_messages", {
        params: this.formattedSearchParams
      });

      // Update metadata of loaded information
      this.maxPages = response.data.pagination.pages;
      this.messageCount = response.data.pagination.count;
      this.searchableYears = response.data.searchableYears;

      if (resetScroll) window.scrollTo(0, 0);
      if (resetMessages && this.textQuery == currentTextQuery) {
        if (this.textQuery) {
          this.messages = response.data.messages.map(m =>
            this.highlightQuery(m)
          );
        }
        this.messages = response.data.messages;
        this.inViewMessages = [];
      } else this.messages = this.messages.concat(response.data.messages);

      const queryParams = {};
      let scrollTo = false;
      if (this.textQuery) queryParams.text = this.textQuery;
      if (this.queryYear) queryParams.year = this.queryYear;
      if (this.queryMonth) queryParams.month = this.queryMonth;
      if (this.$route.query.scrollTo) scrollTo = this.$route.query.scrollTo;

      this.$router.push({ name: "home", query: queryParams });

      this.$nextTick(() => {
        this.loadingMessages = false;
        this.showLoadingMessage = false;
        this.setObservers();
        if (scrollTo) {
          this.$nextTick(() => {
            const scrollTarget =
              document
                .querySelector(`#message-${scrollTo}`)
                .getBoundingClientRect().top - 90;
            window.scrollTo({ top: scrollTarget, behavior: "smooth" });
          });
        }
      });
    },
    handleBottomScroll() {
      // To avoid calling anything whilst the page doesn't have enough messages to scroll.
      if (this.$el.offsetHeight > window.innerHeight) {
        if (window.scrollY / this.$el.offsetHeight > 0.7) {
          if (!this.loadingMessages) {
            this.page += 1;
            this.doSearch(false, false);
          }
        }
      }
    },
    searchForMonth(month) {
      this.activeMonth = month;
      this.queryMonth = month;
      this.page = 1;
      this.showYearSearch = false;
      this.showMonthSearch = false;

      if (!this.queryYear) this.queryYear = this.activeYear;
      this.doSearch(true, true);
      this.showLoadingMessage = true;
    },
    searchForYear(year) {
      if (year == 11) {
        this.activeYear = year;
        this.queryYear = year;
        this.queryMonth = 12;
        this.page = 1;
        this.showYearSearch = false;
        this.showMonthSearch = false;
        this.doSearch(true, true);
        this.showLoadingMessage = true;
      } else {
        this.activeYear = year;
        this.queryYear = year;
        this.showMonthSearch = true;
      }
    }
  }
});
</script>

<style lang="scss">
.route-archive {
  background-color: #313131;
  margin: 0;
  padding-top: 0;
  line-height: unset;
}

.archive-home {
  display: flex;
  font-family: "arial", "helvetica", sans-serif;
  font-size: 10px;
  margin: 0;
  padding-top: 0;

  &.search-open {
    .messages {
      input[type="search"] {
        width: calc(100% - 38px);
        padding-left: 18px;
        margin: 0 18px;
      }
    }
  }

  &.intro-active {
    .sidebar {
      .active-month,
      .active-year {
        opacity: 0;
        pointer-events: none;
      }
    }
    &.search-open {
      .background-lines {
        .line {
          border-radius: 0;
        }
      }
      .year-search {
        left: 109px;
        &::before {
          left: 124px;
        }
      }
      .intro {
        .tagline {
          opacity: 0;
        }
      }
    }
  }

  &.single-message {
    .sidebar {
      .active-month {
        display: none;
      }
      .active-year {
        opacity: 0;
        pointer-events: none;
      }
    }
    .messages {
      background-color: unset;
      padding: 0;
      .query-stats {
        display: none;
      }
      input[type="search"] {
        width: calc(100% - 38px);
        padding-left: 18px;
        margin: 0 18px;
      }
      .archive-message {
        width: calc(100% - 210px);
        max-width: 1200px;
        margin: 125px 0 0 0;
        padding: 0px 2px 0 2px;
        .message-header {
          min-height: 126px;
          overflow: unset;
          padding: 0 5px;
          & > .author {
            line-height: 88px;
            font-size: 64px;
          }
          .timestamp {
            position: absolute;
            left: -2px;
            top: 7px;
            font-size: 12px;
            color: #999;
            text-align: center;
          }
        }
        .message-body {
          font-size: 36px;
          box-shadow: inset 0px -2px 0px 0px #636363;
          .message-text {
            padding: 0 0 0 5px;
            line-height: 50px;
          }
        }
      }
      .placeholder {
        display: none;
      }
    }
    @media only screen and (max-width: 600px) {
      .messages {
        input[type="search"] {
          width: calc(100% - 16px);
          padding-left: 8px;
          margin: 0 8px;
        }
        .archive-message {
          width: calc(100vw - 80px);
          margin-top: 101px;
          .message-header {
            min-height: 84px;
            padding-top: 17px;
            padding-bottom: 17px;
            box-sizing: border-box;
            & > .author {
              font-size: 30px;
              white-space: unset;
              line-height: 42px;
            }
            .timestamp {
              font-size: 10px;
              top: 3px;
              .time {
                position: absolute;
                bottom: -67px;
              }
            }
          }
          .message-body {
            font-size: 22px;
            .message-text {
              line-height: 33.5px;
            }
          }
        }
      }
    }
  }

  .intro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 48px;
    font-weight: 500;
    color: #636363;
    margin-top: 51px;
    white-space: nowrap;
    input[type="search"] {
      width: calc(100% - 38px);
      padding-left: 18px;
      margin: 0 18px;
    }
    .date-teaser {
      margin-top: 100px;
      line-height: 74px;
      strong {
        cursor: pointer;
        line-height: 74px;
        display: inline-block;
        padding-left: 17px;
        padding-right: 8px;
        margin-right: 7px;
        &:hover {
          background-color: #636363;
          color: #313131;
        }
      }
    }
    .tagline {
      margin: 26px 0 0 115px;
      line-height: 74px;
    }
  }

  .sidebar {
    width: 115px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding-right: calc(100% - 115px);
    .active-year {
      font-size: 36px;
      font-weight: 700;
      color: #fff;
      width: 115px;
      text-align: center;
      display: block;
      margin-top: 51px;
      height: 74px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &.all {
        font-size: 39px;
      }
      @media (hover: hover) {
        &:hover {
          background-color: #636363;
        }
      }
    }
    .active-month {
      position: relative;
      width: 115px;
      height: 74px;
      color: #636363;
      font-weight: 700;
      display: flex;
      align-items: center;
      // justify-content: center;
      font-size: 48px;
      text-transform: lowercase;
      padding: 26px 0;
      cursor: pointer;
      .month {
        padding-bottom: 7px;
        padding-left: 16px;
        &:hover {
          color: #fff;
        }
      }
      .month-pagination {
        position: absolute;
        height: 26px;
        width: 100%;
        font-size: 16px;
        text-align: center;
        color: #313131;
        @media (hover: hover) {
          &:hover {
            color: #fff;
          }
        }
      }
      .next-month {
        top: 0;
        span {
          display: block;
          transform: translateY(7px) rotate(45deg);
          transform-origin: center;
          pointer-events: none;
        }
      }
      .prev-month {
        bottom: 0;
        span {
          display: block;
          transform: translateY(-2px) rotate(225deg);
          transform-origin: center;
          pointer-events: none;
        }
      }
    }
    .back-button {
      height: 74px;
      margin-top: 26px;
      margin-bottom: 35px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      box-sizing: border-box;
      width: 115px;
      cursor: pointer;
      svg {
        height: 60px;
        width: 60px;
        stroke: #636363;
      }
      @media (hover: hover) {
        &:hover {
          svg {
            stroke: #fff;
          }
        }
      }
    }
    .logo {
      width: 82px;
      height: 74px;
      align-self: center;
      margin: 235px auto 0;
      display: block;
      cursor: pointer;
    }
  }
  .background-lines {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #313131;
    transform: translateY(-49px);
    z-index: -1;
    & > :first-child {
      opacity: 0;
    }
    .line {
      width: 100%;
      height: 26px;
      background-color: #636363;
      margin-top: 74px;
    }
  }
  .current-date {
    margin: 2rem 0 6px 1rem;
    color: rgb(153, 153, 153);
  }
  .title {
    font-size: 48px;
    font-weight: bold;
    text-decoration: none;
    color: #636363;
    margin-top: 53px;
    margin-left: 1rem;
  }
  .posted-year {
    font-size: 80px;
    font-weight: bold;
    color: #fff;
    margin-top: 1rem;
    margin-left: 1rem;
    display: inline-block;
  }
  .year-search-container {
    font-size: 37px;
    font-weight: bold;
    text-decoration: none;
    color: #636363;
    display: block;
    margin-top: 10px;
  }
  .year-search {
    position: fixed;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    top: 51px;
    left: 99px;
    bottom: 0;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
    font-size: 99px;
    padding-left: 15px;
    width: calc(100vw - 115px);
    z-index: 500;
    &.active {
      display: block;
    }
    .wrapper {
      display: flex;
      width: 544px;
      flex-wrap: wrap;
      flex-direction: column;
      max-height: 640px;
      box-sizing: border-box;
      padding-right: 36px;
      align-items: flex-end;
      top: -12px;
      position: relative;
      &.month {
        font-size: 70px;
      }
      .link {
        width: fit-content;
        min-width: 220px;
        text-align: center;
        font-weight: 700;
        display: block;
      }
    }
    .line-wrapper {
      position: absolute;
      top: -12px;
      width: 100%;
      pointer-events: none;
      & > :first-child {
        opacity: 0;
      }
      .link {
        width: 100%;
        display: block;
        pointer-events: none;
      }
    }
    .tagline {
      position: absolute;
      top: 0;
      left: 15px;
      width: 100%;
      font-size: 18px;
      padding: 8px 5px 0px 140px;
      color: transparent;
      background-color: #636363;
      text-transform: uppercase;
      z-index: 500;
      pointer-events: none;
      .copyright {
        font-size: 10px;
        color: transparent;
        bottom: 0;
        z-index: 100;
        display: block;
        padding-left: 26px;
      }
    }
    .link {
      z-index: 100;
      &.active {
        color: #fff;
        &:hover {
          color: #313131;
        }
      }
    }
    &::before {
      content: " ";
      display: block;
      position: fixed;
      top: 51px;
      left: 115px;
      width: 100%;
      height: 100%;
      background-color: #313131;
      pointer-events: none;
    }
  }
  .year-search > .year {
    text-decoration: none;
    color: #fff;
  }
  .year-search > .year:hover {
    color: red;
  }
  .search-form {
    display: flex;
  }
  .messages {
    background-color: #313131;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 81px 0 32px 0;
    margin-left: 115px;
    min-height: var(--100vh);
    box-sizing: border-box;
    &::before {
      content: " ";
      display: block;
      height: 51px;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: #313131;
      z-index: 100;
    }
    &.no-background {
      background-color: transparent;
    }
    .query-stats {
      position: fixed;
      top: 58px;
      left: 115px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      padding: 8px 0 5px 1px;
      top: 51px;
      width: 544px;
      box-sizing: border-box;
      background: #313131;
      z-index: 100;
    }
    .no-messages {
      width: 545px;
      background-color: #636363;
      color: #313131;
      font-size: 32px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      box-sizing: border-box;
    }
    .archive-message {
      width: 540px;
      background-color: #636363;
      padding: 2px;
      transform-origin: left;
      margin-bottom: 16px;
      .highlight {
        background-color: yellow;
        color: #313131;
      }
      .message-body {
        background-color: #313131;
        color: white;
      }
      .message-text {
        padding: 5px;
        margin: 0;
        overflow-wrap: break-word;
        overflow: hidden;
        img {
          height: auto;
          width: auto;
        }
      }
      .message-header {
        background-color: #636363;
        color: #313131;
        padding: 5px;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
      }
      .timestamp {
        white-space: nowrap;
        cursor: pointer;
        color: #999;
      }
      .message-header > .author {
        font-weight: 700;
        font-size: 18px;
        flex: 1 1 auto;
        color: #313131;
      }
      @media only screen and (max-width: 600px) {
        .message-header {
          padding: 3px 4px;
        }
      }
    }
  }
  .link {
    text-decoration: none;
    color: #aaa;
    line-height: 75px;
    padding: 6px 0 7px 0;
    text-align: center;
    border-top: 12px solid #636363;
    &:hover {
      background-color: #aaa;
      color: #313131;
    }
  }
  button[type="submit"] {
    font-size: 10px;
  }
  input[type="search"]:focus-visible {
    outline: none;
  }
  input[type="search"] {
    padding: 5px 0 4px 18px;
    margin: 0 0 0 18px;
    background-color: #636363;
    width: 641px;
    border: 0;
    border-radius: 16px;
    color: #fff;
    font-weight: bold;
    position: fixed;
    left: 0;
    top: 21px;
    font-size: 18px;
    z-index: 200;
    &::placeholder {
      color: #313131;
    }
    &:focus {
      outline: none;
      box-shadow: inset 0px 0px 0px 1px #ffffff;
      color: #fff;
    }
  }
  input[type="search"]::-webkit-search-cancel-button {
    appearance: none;
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='white' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    cursor: pointer;
    padding-right: 5px;
    margin-bottom: 1px;
  }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .searching {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    color: #636363;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 5px 4px 10px;
    z-index: 100;
    width: 659px;
    box-sizing: border-box;
  }
  .top-filler {
    content: " ";
    display: block;
    top: 0;
    left: 115px;
    width: 544px;
    height: 16px;
    background-color: #313131;
    position: fixed;
    z-index: 100;
  }
  .button {
    background: #fff;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 1px 2px;
    text-decoration: none;
    font-family: arial, helvetica, sans-serif;
  }
  .pagination-container {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #aaa;
    gap: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  nav.pagy-nav > .disabled,
  .active {
    color: black;
  }
  .pagy-nav {
    display: inline-flex;
    column-gap: 1rem;
  }
  .pagy-nav > .gap {
    color: #aaa;
  }
  @media only screen and (max-width: 600px) {
    .background-lines {
      transform: translateY(-16px);
      .line {
        height: 17px;
        margin-top: 50px;
      }
    }
    .link {
      line-height: 46px;
      border-top: 8px solid #636363;
    }
    .searching {
      width: 544px;
      padding: 4px 5px 3px 10px;
    }
    input[type="search"] {
      width: calc(100% - 16px);
      height: 30px;
      padding-left: 8px;
      margin: 0 8px;
    }
    .sidebar {
      width: 75px;
      .logo {
        width: 60px;
        height: 50px;
        margin-top: 155px;
      }
      .active-year {
        width: 75px;
        font-size: 26px;
        height: 50px;
        margin-top: 51px;
        &.all {
          font-size: 29px;
        }
      }
      .active-month {
        width: 75px;
        font-size: 33px;
        height: 50px;
        padding: 17px 0;
        .month {
          padding: 0;
          margin-left: 6px;
        }
        .month-pagination {
          height: 17px;
        }
        .next-month {
          span {
            font-size: 12px;
            transform: translateY(3px) rotate(45deg);
          }
        }
        .prev-month {
          span {
            font-size: 12px;
            transform: translateY(-4px) rotate(225deg);
          }
        }
      }
      .back-button {
        margin-top: 17px;
        height: 50px;
        margin-bottom: 19px;
        padding-left: 4px;
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
    .year-search-container {
      margin-top: 2px;
      .title {
        font-size: 33px;
        margin-left: 6px;
      }
      &::before {
        left: 75px;
      }
    }
    .year-search {
      left: 75px;
      padding-left: 0;
      width: calc(100vw - 75px);
      font-size: 55px;
      // padding-top: 43px;
      top: 55px;
      &::before {
        left: 75px;
      }
      .tagline {
        left: 0;
        padding: 11px 0 0 0;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
      }
      // .line-wrapper {
      //   top: -11px
      // }
      .wrapper {
        width: 100%;
        padding: 0 14px;
        max-height: 420px;
        &.month {
          font-size: 50px;
        }
        .link {
          width: 50%;
          min-width: unset;
          border-top: 8px solid #636363;
        }
      }
    }
    .messages {
      margin-left: 75px;
      .query-stats {
        left: 75px;
        width: calc(100vw - 75px);
      }
      .no-messages {
        width: 100%;
        font-size: 26px;
        padding: 7px;
      }
      .archive-message {
        width: calc(100vw - 80px);
      }
    }
    .intro {
      font-size: 33px;
      input[type="search"] {
        padding-left: 8px;
        margin: 0 8px;
        width: calc(100% - 16px);
      }
      .date-teaser {
        line-height: 50px;
        margin-top: 67px;
        strong {
          padding: 0 8px 0 8px;
          margin: 0;
          line-height: 50px;
        }
      }
      .tagline {
        margin: 18px 0 0 73px;
        line-height: 50px;
      }
    }
    &.search-open {
      .messages {
        input[type="search"] {
          width: calc(100% - 16px);
          padding-left: 8px;
          margin: 0 8px;
        }
      }
      // .sidebar .active-year {
      //   background-color: #636363;
      // }
    }
    &.intro-active {
      .sidebar {
        .year-search-container {
          padding-left: 0;
          margin-top: 1px;
        }
      }
      &.search-open {
        .year-search {
          left: 82px;
          &::before {
            left: 82px;
          }
        }
        .sidebar {
          .year-search-container {
            padding-left: 0;
          }
        }
        .intro {
          .tagline {
            display: none;
          }
          .date-teaser {
            color: transparent;
            strong {
              // padding: 0 4px;
              background-color: #636363;
              color: #313131;
            }
          }
        }
      }
    }
  }
}
</style>

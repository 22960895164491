<template>
  <nav class="header-nav">
    <button
      :class="[`hamburger hamburger--3dx`, { 'is-active': isOpen }]"
      @click.prevent="toggleOpen"
      type="button"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <div class="dropdown--background" @click.self="toggleOpen" v-if="isOpen"></div>
    <nav class="dropdown" ref="dropDown" v-if="isOpen">
      <div class="dropdown__content">
        <h3>Rooms</h3>
        <div class="feeds">
          <div class="feed">
            <router-link @click="hide" to="/">
              <profile-pic
                background-color="white"
                :has-border="true"
                border-color="rgb(235, 34, 44)"
                url=""
                :width="50"
              >
                <SvgSplaySlash class="splay-slash" height="50"></SvgSplaySlash>
              </profile-pic>
              <div class="name">Splay</div>
            </router-link>
          </div>

          <div v-for="feed in feeds" :key="feed.id" class="feed">
            <router-link
              @click="hideAndChangeFeed(feed)"
              :to="{ name: 'feed', params: { slug: feed.slug } }"
            >
              <profile-pic
                :has-border="true"
                border-color="#333"
                :url="feed?.image?.url || ''"
                :width="50"
              />
              <div class="name">
                {{ feed.name }}
              </div>
            </router-link>

            <div class="feed-options" v-if="isCreator(feed)">
              <router-link
                @click="hide"
                class="buttony-link edit"
                :to="{ name: 'editFeed', params: { slug: feed?.slug } }"
                >Edit</router-link
              >

<!--
              <router-link
                @click="hide"
                class="buttony-link"
                :to="{ name: 'newfeedInvitations', params: { slug: feed?.slug } }"
                >Invite</router-link
              >
    -->
            </div>
          </div>
        </div>

        <div class="other-options">
          <router-link @click="hide" class="buttony-link" :to="{ name: 'feedNew' }" v-if="userIsActive"
            >Create a new Room</router-link
          >
          <div class="links" v-if="userIsActive">
            <router-link :to="{ name: 'feedInvites' }" @click="toggleOpen" class="link"
              >Invites</router-link
            >
            <!-- <router-link :to="{ name: 'feeds' }" @click="toggleOpen" class="link"
              >Rooms</router-link
            >
            <router-link :to="{ name: 'feedRooms' }" @click="toggleOpen" class="link"
              >Rooms</router-link
            > -->
            <router-link :to="{ name: 'user_settings' }" @click="toggleOpen" class="link"
              >Edit Profile</router-link
            >
          </div>

          <div class="links" v-if="!userIsActive">
            <router-link :to="{ name: 'user_settings' }" @click="toggleOpen" class="link"
              >Edit Your Application</router-link
            >            
          </div>

        </div>

        <!-- <router-link class="buttony-link" :to="{ name: 'user_settings' }">Edit Account</router-link> -->
      </div>

      <!-- <router-link :to="{ name: 'feeds' }" @click="toggleOpen" class="terms">Channels</router-link> -->
      <!-- <router-link :to="{ name: 'feedRooms' }" @click="toggleOpen" class="terms">Rooms</router-link> -->
      <!-- <router-link :to="{ name: 'feedInvites' }" @click="toggleOpen" class="terms"
        >Invites</router-link
      > -->
    </nav>
  </nav>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
// import { onClickOutside } from "@vueuse/core";
import { useRouter } from "vue-router";
import ProfilePic from "../ProfilePic.vue";
import SvgSplaySlash from "@/svg/SvgSplaySlash.vue";
import { Feed } from "@/types";
import { useStore } from "@/application/store";

const { state, dispatch, commit } = useStore();

export default defineComponent({
  components: { SvgSplaySlash, ProfilePic },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        dispatch("feeds_list", 1);
      }
      this.$emit("dropdown-toggled", newVal);
    },
  },
  computed: {
    feeds() {
      return state.feeds.items;
    },
    userIsActive(){
      return state.currentUser && state.currentUser.status === 'active'
    }
  },
  setup(props, ctx) {
    const router = useRouter();
    const isOpen = ref(false);
    const toggleOpen = () => (isOpen.value = !isOpen.value);
    const hide = () => {
      isOpen.value = false;
    };

    const dropDown = ref();
    // onClickOutside(dropDown, (_) => {
    //   isOpen.value = false;
    // });

    const gotoFeed = (feed: Feed) => {
      commit("feedMessages_setFeed", feed);
      router.push({ name: "feed", params: { slug: feed.slug } });
    };

    const hideAndChangeFeed = (feed: Feed) => {
      hide();
      gotoFeed(feed);
    };

    return {
      isOpen,
      hide,
      hideAndChangeFeed,
      dropDown,
      toggleOpen,
      gotoFeed,
      isCreator: (f: Feed) => f.creatorId == state.currentUser?.id,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-nav {
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
}

.other-options {
  text-align: center;
  padding-bottom: 48px;

  .links {
    margin: 2rem 1rem;
    .link {
      margin: 0 0.5rem;
    }
  }
}

.buttony-link {
  font-size: 14px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 2px 48px;
  border-radius: 3px;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }

  &.edit {
    border: none;
    background: transparent;
    padding: 2px 24px;

    &:hover {
      text-decoration: underline;
    }
  }
}
.feed-options {
  display: flex;
}

.dropdown--background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  left: 0;
  right: 0;
  // top: 50px; // Below the header
  top: 0;
  bottom: 0;
  backdrop-filter: blur(12px);
  z-index: 9;
}

.dropdown__content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-height: calc(100vh - 50px);
}
.feeds {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  background-color: #eee;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.other-options {
  padding-top: 24px;
  background-color: #eee;
}
.feed {
  padding: 12px 0;
  margin: 0 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:first-child {
    display: flex;
    align-items: center;
  }
}

.name {
  font-weight: bold;
  margin-left: 24px;
  font-size: 16px;
  flex-grow: 0;
  flex-shrink: 0;
}

nav.dropdown {
  box-sizing: border-box;
  background: white;
  border: 1px solid #e8e7e7;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  overflow: hidden;
  max-width: 600px;
  width: 100%;
  position: fixed;
  padding: 12px 12px 0 12px;
  bottom: 0;
  z-index: 10;
  max-height: calc(100vh - 50px);

  h3 {
    margin-top: 12px;
    margin-bottom: 24px;
    padding-left: 12px;
    font-size: 20px;
  }
}

.hamburger {
  transform: scale(0.7);
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  margin-left: -12px;
  margin-top: -2px;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 2px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before,
.hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

// Disabling the animation
// .hamburger--3dx.is-active .hamburger-inner {
//   background-color: transparent !important;
//   transform: rotateY(180deg);
// }
// .hamburger--3dx.is-active .hamburger-inner::before {
//   transform: translate3d(0, 10px, 0) rotate(45deg);
// }
// .hamburger--3dx.is-active .hamburger-inner::after {
//   transform: translate3d(0, -10px, 0) rotate(-45deg);
// }
</style>


import { UserWithPic } from "@/types/UserWithPic";
import { Feed } from "./Feed";

export class FeedInvitation {
  id!: number | string
  feedId!: number | string
  feed!: Feed
  inviterId!: number
  inviter!: UserWithPic
  inviteeId?: number
  invitee?: UserWithPic


  static initialize(json: Object, feed: Feed, inviter: UserWithPic, invitee?: UserWithPic): FeedInvitation {
    const inv: FeedInvitation = Object.assign(new FeedInvitation(), json, {
      feed,
      inviter: UserWithPic.initialize(inviter),
      invitee: invitee && UserWithPic.initialize(invitee),
    });
    return inv
  }
}

// implements a cancel able timer

const defaultInterval = 1000;

const _timer = (sec: number, interval = defaultInterval) => {
  let iid: number | undefined;
  let resolve: undefined | ((sec: number) => void);
  let reject: undefined | (() => void);
  let _tickFunc: undefined | ((sec: number) => void);
  let _sec: number;

  let _promise = new Promise((_resolve, _reject) => {
    (resolve = _resolve), (reject = _reject);
  });

  _promise.catch((e) => { });

  const cancel = () => {
    window.clearInterval(iid);
    iid = undefined;
    reject && reject();
  };
  const tick = (f: (sec: number) => void) => {
    _tickFunc = f;
  };

  const update = (inv: number) => {
    interval = inv;
    window.clearInterval(iid);
    iid = undefined;
    iid = start();
  };

  _sec = 0;

  const start = () => {
    if (iid) {
      return;
    }
    return window.setInterval(() => {
      ++_sec;
      if (_sec * interval >= sec) {
        window.clearInterval(iid);
        _tickFunc && _tickFunc(_sec);
        resolve && resolve(_sec);

        iid = undefined;
        resolve = undefined;
        reject = undefined;
        _tickFunc = undefined;
        _sec = -1;

        return;
      }
      _tickFunc && _tickFunc(_sec);
    }, interval);
  };

  const pause = (f = true) => {
    if (!f) {
      start();
    } else if (iid) {
      window.clearInterval(iid);
      iid = undefined;
    }
  };

  iid = start();

  return {
    cancel,
    tick,
    update,
    pause,
    then: (f: any) => {
      _promise.then(f);
    },
  };
};

export const ticker = (inv: number = 1000) => timer(Infinity, inv);
export const timer = _timer;

export default _timer;

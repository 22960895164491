import { InjectionKey } from 'vue';
import { createStore } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { State } from './state';
import { Store } from './Store';
import { wsToStore } from './wsToStore';


let state = new State()

export const store = createStore<State>({
  state,
  actions,
  mutations,
  getters
});

export default store

export const key: InjectionKey<Store<State>> = Symbol()


export function useStore() {
  wsToStore(store)
  return store as Store<State>
  // return baseUseStore(key)
}


<template>
  <section :class="['app-landing', `mode-${mode}`, {'show-login': showLogin}]">
    <div 
      :class="['display-date', {'hide': hideDate}]" 
      v-if="mode == 'date' && isLoggedIn" 
      v-html="displayDate ? displayDate : currentDate" 
      ref="displayUser"
      @click="navToUser()"
    />
 
    <div class="login-modal" v-if="!isLoggedIn">

      <SvgSplayIntroLogo class="logo" />

      <template v-if="getPhoneNumber">
        <input class="phone-input" ref="phoneInput" type="tel">
        <button :class="['continue-button', {'active': isPhoneValid}]" @click="sendCode">Log In</button>
      </template>

      <template v-else>
        <span class="code-prompt">Enter the 6 digit code we sent</span>
        <input class="code-input" ref="codeInput" type="text" placeholder="XXXXXX" v-model="code">
        <button :class="['continue-button active', {'hide': !showCancel}]" @click="cancel">Cancel</button>
        <span class="invalid-message" v-if="codeInvalid">wrong code</span>
      </template>

      <a class="icon-app" href="https://apps.apple.com/us/app/splay/id882251721" target="_blank">
        <AppStoreIcon />
      </a>

      <div class="app-prompt">
        <span>By signing in you accept our <router-link to="/terms">Terms of Use</router-link> & <router-link to="/privacy">Privacy Policy</router-link></span>
      </div>

    </div>

    <slash-icon v-if="!isLoggedIn" @touchstart="showLogin = true" />

    <landing-bubbles 
      :class="[{'logged-in': isLoggedIn}, {'legal-open': isPrivacyPage || isTermsPage}]"
      :mode="mode" 
      @display="setDisplayDate" 
      @display-current="setCurrentDate"
      :is-logged-in="isLoggedIn" 
      :legal-open="isTermsPage || isPrivacyPage"
    />

    <transition name="fade">
      <privacy-page v-if="isPrivacyPage" />
    </transition>
    <transition name="fade">
      <privacy-page v-if="isTermsPage" />
    </transition>
  </section>
</template>

<script>
  import SvgSplayIntroLogo from "@/svg/SvgSplayIntroLogo.vue";
  import SlashIcon from "@/application/components/svg/SlashIcon.vue";
  import SearchIcon from "@/application/components/svg/SearchIcon.vue"
  import AppStoreIcon from "@/application/components/svg/AppStoreIcon.vue";
  import IconInstagram from "@/application/components/svg/IconInstagram.vue";
  import IconLinkedin from "@/application/components/svg/IconLinkedin.vue";
  import IconTwitter from "@/application/components/svg/IconTwitter.vue";
  import LandingBubbles from "@/application/components/site/LandingBubbles.vue"
  import LandingNames from "@/application/components/site/LandingNames.vue"
  import LandingSearch from "@/application/components/landing/Search.vue"
  import PrivacyPage from "@/application/pages/Privacy.vue"

  import intlTelInput from "intl-tel-input";
  import "intl-tel-input/build/css/intlTelInput.css";
  import localStorageService from "@/application/lib/localStorageService";

  import {
  loadCurrentUser,
  makeDummyUser,
  submitPhoneForVerification,
  verifySmsCode
} from "@/application/api/user";

  export default {
    components: {
      SvgSplayIntroLogo,
      AppStoreIcon,
      IconInstagram,
      IconLinkedin,
      IconTwitter,
      LandingBubbles,
      LandingNames,
      LandingSearch,
      SlashIcon,
      PrivacyPage
    },
    data() {
      return {
        mode: "date", 
        displayDate: "",
        currentDate: "",
        isPhoneValid: false,
        phone: "",
        getPhoneNumber: true,
        code: "",
        hideDate: false,
        isLoggedIn: false,
        codeInvalid: false,
        showCancel: true,
        showLogin: true
      }
    },
    created() {
      this.isLoggedIn = !!localStorage.getItem('oauth');
    },
    mounted() {
      if (!this.isLoggedIn) {
        this.initTelWidget();
      }
    },
    watch: {
      async code() {
        if (this.code.length == 6) {
          this.codeInvalid = false
          this.showCancel = false
          const result = await verifySmsCode({
            code: this.code,
            phone: this.phone
          });
          const verificationStatus = result.status;
          if (verificationStatus != "invalid") {
            localStorageService.setOAuth({
              accessToken: result.splay_jwt,
              sfuToken: result.sfu_jwt
            });
            this.isLoggedIn = true
            this.$emit('logged-in', true)
          } else {
            this.codeInvalid = true
            this.code = ""
          }
          this.showCancel = true
        }
      }
    },
    computed: {
      showImageBubbles() {
        return this.mode == 'date' || this.mode == 'user'
      },
      isMobile() {
        return window.innerWidth < 550
      },
      isTermsPage() {
        return this.$route.name == 'terms'
      },
      isPrivacyPage() {
        return this.$route.name == 'privacy'
      }
    },
    methods: {
      navToUser() {
        const user = this.$refs.displayUser.querySelector('span').innerText
        this.$router.push(`/${user}`)
      },
      cancel() {
        this.phone = ""
        this.getPhoneNumber = true
        this.$nextTick(() => this.initTelWidget())
      },
      toggleDate() {
        this.hideDate = !this.hideDate
      },
      async sendCode() {
        if (this.isPhoneValid) {
          this.getPhoneNumber = false
          const results = await submitPhoneForVerification({ phone: this.phone });
        }
      },
      setMode(mode) {
        this.mode = mode
      },
      setDisplayDate(date) {
        this.displayDate = date
      },
      setCurrentDate(date) {
        this.currentDate = date
      },
      initTelWidget() {
      let input = this.$refs.phoneInput;

      const formatIntlTelInput = e => {
        const iti = window.intlTelInputGlobals.getInstance(input);
        if (intlTelInputUtils) {
          // utils are lazy loaded, so must check
          let currentText = iti.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === "string") {
            // sometimes the currentText is an object :)
            iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
          }
        }
        this.isPhoneValid = iti.isValidNumber(); // etc
        this.phone = iti.getNumber(intlTelInputUtils.numberFormat.E164);
      };

      intlTelInput(input, {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        allowDropdown: false,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
          if (selectedCountryData.name == "United States") return "917-555-0123"
          else return selectedCountryPlaceholder
        }
      });
      input.addEventListener("keyup", formatIntlTelInput);
      input.addEventListener("change", formatIntlTelInput);
    },
    }
  }
</script>

<style lang="scss">

.search-open .display-date {
  opacity: 0;
  pointer-events: none;
}

.app-landing {
  position: relative;
  overflow: hidden;
  .display-date {
      position: fixed;
      top: 59px;
      left: 140px;
      font-size: 28px;
      color: #868686;
      z-index: 2000;
      font-weight: 500;
      text-transform: lowercase;
      cursor: pointer;
      transition: opacity 0.3s;
      span {
        color: #fff;
      }
      &:hover {
        span {
          text-decoration: underline;
        }
      }
  }
  &.show-login .login-modal {
    opacity: 1;
    pointer-events: auto;
  }
  .login-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index: 999;
    width: 340px;
    height: 460px;
    box-shadow: 1px 2px 5px 1px #b7b7b7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 20px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.6s;
    pointer-events: none;
    @media (prefers-color-scheme: dark) {
      box-shadow: 0px 0px 5px 2px #696969;
    }
    .logo {
      width: 120px;
      height: auto;
      margin-bottom: 50px;
      path:not(.slash) {
        fill: var(--black);
      }
    }
    .code-input {
      font-size: 20px;
      padding: 10px 0 10px 0px;
      margin-bottom: 6px;
      border-radius: 8px;
      border: 1px solid #b7b7b7;
      text-align: center;
      width: 246px;
      font-weight: 600;
      &:focus {
        outline: #000;
      }
    }
    .code-prompt {
      line-height: 1.2;
      max-width: 260px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: -28px;
      font-size: 15px;
      color: var(--black);
    }
    .invalid-message {
      position: absolute;
      left: 50%;
      bottom: 144px;
      transform: translateX(-50%);
      font-size: 12px;
      line-height: 1;
      color: var(--black);
    }
    .phone-input {
      font-size: 20px;
      padding: 10px 0 10px 16px ;
      border-radius: 8px;
      border: 1px solid #bbbbbb;
      font-weight: 600;
      width: 246px;
      box-sizing: border-box;
      &:focus {
        outline: #000;
      }
    }
    .iti {
      margin-bottom: 6px;
      .iti__selected-dial-code {
        display: none;
      }
      .iti__selected-flag {
        background-color: transparent;
      }
    }
    .signin-button {
      display: block;
      margin: 0 auto 0;
      height: 41px;
      // width: 200px;
      & > div {
        // border: 1px solid black;
        border-radius: 8px;
        overflow: hidden;
      }
      transition: filter 0.3s;
      &:hover {
        filter: invert(1);
      }
    }
    .continue-button {
      height: 41px;
      width: 250px;
      background-color: var(--black);
      color: var(--white);
      border: none;
      border-radius: 8px;
      border: 1px solid #000;
      font-size: 20px;
      margin-bottom: 30px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s, opacity 0.3s;
      opacity: 0.3;
      pointer-events: none;
      &.active {
        opacity: 1;
        pointer-events: auto;
      }
      &:hover {
        opacity: 0.75;
      }
      &.hide {
        opacity: 0;
        pointer-events: none;
      }
    }
    .app-prompt {
      max-width: 220px;
      color: var(--black);
      font-size: 15px;
      line-height: 1.2;
      text-align: center;
      padding-top: 15px;
      span {
        display: block;
        padding-bottom: 35px;
        a {
          text-decoration: underline;
        }
      }
    }
    .icon-app {
      display: flex;
      z-index: 999;
      margin-top: 8px;
      border: 1px solid var(--black);
      border-radius: 8px;
      svg {
        width: 120px;
        height: auto; 
        display: inline-block;
        .bg {
          stroke:#fff;
          @media (prefers-color-scheme: dark) {
            fill:#fff !important;
          }
        }
        .bg-2 {
          display: none;
        } 
        path {
          fill: var(--black) !important;
        }
      }
    }
  }
  .slash-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    z-index: 500;
  }
  @media only screen and (max-width: 550px) {
    .login-modal {
      height: 400px;
      .logo {
        margin-bottom: 40px;
      }
      .app-prompt {
        // padding-top: 30px;
        span {
          padding-bottom: 20px;
        }
      }
      .code-prompt {
        margin-top: -28px;
      }
      .continue-button {
        margin-bottom: 25px;
      }
    }
    .display-date {
      font-size: 17px;
      top: 7px;
      left: 110px;
      line-height: 1.1;
    }
  }
}

</style>
<template>
  <div class="message-grid">
    <!-- <VideoReactions /> -->
    <div class="grid">
      <message-form :form="form" v-if="$route.name != 'post'" />
      <div v-if="$route.name != 'post'" class="grid-selector">
        <svg class="active" width="21" height="21" viewbox="0 0 21 21">
          <rect x="0" y="0" width="21" height="21" fill="#ccc" />
        </svg>

        <svg @click="changeGridMode('dense')" width="21" height="21" viewbox="0 0 21 21">
          <rect x="0" y="0" width="10" height="10" fill="#ccc" />
          <rect x="11" y="0" width="10" height="10" fill="#ccc" />
          <rect x="0" y="11" width="10" height="10" fill="#ccc" />
          <rect x="11" y="11" width="10" height="10" fill="#ccc" />
        </svg>
      </div>

      <message-block
        v-for="message in messages"
        :key="message.id"
        :message="message"
        @setDetail="setDetail"
      />
    </div>
    <transition name="slide-up">
      <div class="message-detail" v-if="detailMessage">
        <div class="close" @click="setDetail(false)">
          <svg-close />
        </div>
        <message-block :message="detailMessage" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import MessageForm from "@/application/forms/MessageForm.vue";
// import Release from "@/application/e-comm/Release.vue";
import { Form, Message, Message as MessageT } from "@/types";
import { defineComponent, PropType } from "vue";
//Components
import MessageBlock from "../blocks/Message.vue";
import svgClose from "../svgs/Close.vue";
import VideoReactions from "./VideoReactions.vue";

export default defineComponent({
  components: {
    MessageBlock,
    svgClose,
    MessageForm,
    VideoReactions,
  },
  props: {
    messages: {
      type: Array as PropType<Array<MessageT>>,
      required: true,
    },
  },
  data() {
    return {
      detailMessage: false,
      form: new Form(Message.build(), "message", true),
    };
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.watchScroll);
  },
  watch: {
    detailMessage(message) {
      if (message) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "";
        document.body.style.overflow = "";
      }
    },
  },
  methods: {
    changeGridMode(mode) {
      this.$emit("changeMode", mode);
    },
    setDetail(message: MessageT) {
      if (this.detailMessage && this.detailMessage.id == message.id) this.detailMessage = false;
      else this.detailMessage = message;
    },
    watchScroll() {
      let scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollPos / this.$el.offsetHeight > 0.75) {
        this.$emit("loadMore");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.message-grid {
  background-color: var(--white);
  .grid-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--black);
    margin-bottom: 24px;
    span {
      margin-right: 8px;
    }
    svg {
      margin-right: 5px;
      cursor: pointer;
      &.active,
      &:hover {
        rect {
          fill: var(--black);
        }
      }
    }
    @media (max-width: 768px) {
      padding-right: 8px;
    }
  }
}
.message-detail {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  padding: 55px 0;
  background-color: var(--background-white);
  max-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 0;
  .close {
    position: absolute;
    top: 10px;
    right: 17px;
    z-index: 100;
    display: flex;
    padding: 10px;
  }
  .message {
    margin: 0 25px;
    width: calc(100% - 50px);
    box-sizing: border-box;
  }
}
</style>


import { feedsWS, v1, v2 } from "@/types";
import { feedWS } from "@/types/feeds";
import { welcomeLiveStream, welcomeLiveStreamMeta, welcomeMsg } from "@/types/v1";
import { RoomPeer } from "@/types/v2";
import { TypedEmitter } from "tiny-typed-emitter";
import { ulid } from "ulid";
import { computed, reactive, readonly } from "vue";
import { ID } from "../api";
import { wsToRoomStore } from "./wsToRoomsStore";


export class Room {
  id!: ID
  uid = ulid()
  feedId?: ID
  type!: string
  createdByDeviceId = ""
  peerCount = 0
  peers = new Array<v2.RoomPeer>()
  created?: string | Date
  recId?: string
  started = false
  startedAt?: Date
  recordingStartedAt?: Date

  static createFeed(m: feedWS.joined | feedsWS.roomCreated | feedsWS.FeedsRoom): Room {
    const r = new Room()
    r.id = m.roomId
    r.type = "feed"
    r.feedId = m.feedId
    r.peerCount = m.peerCount

    if ("peers" in m) {
      m.peers.forEach((p: RoomPeer) => r.addPeer(p))
    }


    return r
  }

  static createAudio(m: v2.msg.roomCreated | v2.msg.roomJoined | feedsWS.roomCreated | feedsWS.FeedsRoom): Room {
    const r = new Room()
    r.id = m.roomId
    r.type = "audio"
    r.feedId = m.feedId


    r.peerCount = m.peerCount

    r.created = m.created
    if ("peer" in m) {
      r.createdByDeviceId = m.peer.deviceId
      r.addPeer(m.peer)
    }
    if ("peers" in m) {
      m.peers.forEach((p) => r.addPeer(p))
    }
    return r
  }

  static createVideo(m: feedsWS.roomCreated | v1.msg.liveStreamCreated | v1.msg.liveStreamCreatedSuccess | v1.msg.liveStreamJoined): Room {
    const r = new Room()

    const mm = (m as v1.msg.liveStreamJoined)
    if (mm.body && mm.body.info) {
      r.addPeer(mm.body.peer)
      r.peerCount = 1
      r.createdByDeviceId = mm.body.info.createdByDeviceId
    } else if ("peer" in m) {
      r.createdByDeviceId = m.peer.deviceId
      r.addPeer(m.peer)
      r.peerCount = 1
    }
    r.id = ("roomId" in m) && m.roomId || `feed-${m.feedId}-video`
    r.type = "video"
    r.feedId = m.feedId

    return r
  }

  static createVideoFromWelcome(msg: welcomeMsg, ls: welcomeLiveStream): Room {
    const r = new Room()
    r.id = ls.id
    r.type = "video"
    r.feedId = msg.feedId
    r.recId = ls.recId
    r.createdByDeviceId = ls.createdByDeviceId
    r.created = new Date(ls.created)
    r.peerCount = ls.count
    r.started = ls.started

    const meta = JSON.parse((ls.meta as unknown as string)) as welcomeLiveStreamMeta
    const peer = { name: meta.username, userId: meta.userId, deviceId: ls.createdByDeviceId, uid: meta.uid, profilePicURL: meta.profilePic }
    r.addPeer(peer)
    return r
  }

  //   {
  //     "id": "feed-0-audio",
  //     "feedId": 0,
  //     "recId": "01FQ3Z4GFFDQ1P93EHV0P6YM79",
  //     "createdByDeviceId": "02fb713b-04f4-4e5a-adf9-8e859e7b0fa5",
  //     "count": 1,
  //     "created": "2021-12-17T11:31:11.599697+01:00",
  //     "meta": "",
  //     "started": false,
  //     "type": "audio",
  //     "peers": {
  //         "1046": {
  //             "uid": 1046,
  //             "userId": 68,
  //             "deviceId": "02fb713b-04f4-4e5a-adf9-8e859e7b0fa5",
  //             "name": "LZFR",
  //             "profilePicURL": "https://img.gs/dvzcgkgkpx/100,2x,quality=medium/https://splay-uploads-dev.s3.amazonaws.com/d7/d74ae4d3-cf53-4a35-bcc8-6cf4b589519f.jpg",
  //             "isAuthenticated": true,
  //             "canAudioChat": true,
  //             "published": false
  //         }
  //     },
  //     "publishedPeerCount": 0,
  //     "recordingStartedAt": "2021-12-17T11:39:06.642575+01:00"
  // }

  static createAudioFromWelcome(room: {
    id: string;
    recId: string;
    createdByDeviceId: string;
    feedId: number;
    count: number;
    started: boolean;
    recordingStartedAt: string;
    publishedPeerCount: number;
    peers: Record<string, RoomPeer>
  }): Room {
    const r = new Room()
    r.id = room.id
    r.type = "audio"
    r.recId = room.recId
    r.createdByDeviceId = room.createdByDeviceId
    r.feedId = room.feedId
    r.createdByDeviceId = room.createdByDeviceId
    r.peerCount = room.count
    r.started = room.started
    r.recordingStartedAt = new Date(room.recordingStartedAt)

    Object.values(room.peers).forEach(peer => {
      r.addPeer(peer)
    })

    return r
  }

  static createFromLiveStreamCreated(msg: v1.msg.liveStreamCreated) {
    const r = new Room()
    r.id = msg.id
    r.feedId = msg.feedId
    r.type = "video"
    r.recId = msg.body.recId
    r.createdByDeviceId = msg.body.createdByDeviceId
    r.created = new Date(msg.body.created)

    r.peerCount = msg.body.count
    r.started = msg.body.started

    const { meta } = msg.body
    const peer = { name: meta.username, userId: meta.userId, deviceId: msg.body.createdByDeviceId, uid: msg.uid, profilePicURL: meta.profilePic }
    r.addPeer(peer)
    return r
  }

  static create(fr: feedsWS.FeedsRoom) {
    switch (fr.roomType) {
      case "audio":
        return Room.createAudio(fr)

      case "video": {
        const r = Room.createFeed(fr)
        r.type = "video"
        return r
      }
      case "feed":
        return Room.createFeed(fr)
      default:
        throw new Error(`unknown type "${fr.roomType}"`)
    }
  }

  addPeer(peer: RoomPeer): boolean {
    const idx = this.peers.findIndex(p => p.uid == peer.uid)
    if (~idx) {
      const up = Object.assign(this.peers[idx], peer)
      this.peers.splice(idx, 1, up)
      // if (this.peerCount < this.peers.length) {
      //   this.peerCount = this.peers.length
      // }
      return false
    } else {
      this.peers.push(peer)
      return true
    }
  }


  getPeer(uid: number): RoomPeer | undefined {
    return this.peers.find(p => p.uid == uid)
  }

  updatePeer(peer: { uid: number } & Partial<RoomPeer>): RoomPeer | undefined {
    const idx = this.peers.findIndex(p => p.uid == peer.uid)
    if (~idx) {
      const up = Object.assign(this.peers[idx], peer)
      this.peers.splice(idx, 1, up)
      return up
    }
  }

  deletePeer(uid: number): RoomPeer | undefined {
    const idx = this.peers.findIndex(p => p.uid == uid)
    if (~idx) {
      this.peers.splice(idx, 1)
      return this.peers[idx]
    }
  }
  isJoined(uid: number) {
    return this.peers.findIndex(p => p.uid == uid) >= 0
  }

  creator() {
    return this.peers.find(p => p.deviceId == this.createdByDeviceId)
  }

  isCreatedBy(deviceId: string) {
    return this.createdByDeviceId == deviceId
  }
}

type stateType = {
  uid: number
  rooms: Array<Room>
  peerCounts: { [feedId: ID]: { [type: string]: number } }
}

const state = reactive<stateType>({
  uid: 0,
  rooms: new Array<Room>(),
  peerCounts: {}
})

export function deleteRoom(id: ID) {
  const idx = state.rooms.findIndex(r => r.id == id)
  if (~idx) {
    const room = state.rooms[idx]
    state.rooms.splice(idx, 1)
    delete state.peerCounts[room.feedId!]


    switch (room.type) {
      case "audio":
        bus.emit("audioRoomDestroyed", room)
        break
      case "feed":
        bus.emit("feedRoomDestroyed", room)
        break
      case "video":
        bus.emit("videoRoomDestroyed", room)
        break
    }
    return room
  }
}

export function addRoom(room: Room) {
  const idx = state.rooms.findIndex(r => r.id == room.id)
  if (~idx) {
    const ur = Object.assign(state.rooms[idx], room) as Room
    state.rooms.splice(idx, 1, ur)
    return ur
  }
  state.rooms.push(room)
  switch (room.type) {
    case "audio":
      bus.emit("audioRoomCreated", room, room.peers[0])
      break
    case "feed":
      bus.emit("feedRoomCreated", room)
      break
    case "video":
      bus.emit("videoRoomCreated", room, room.peers[0])
      break
  }

  return room
}

export function updateRoom(id: string, room: Partial<Room>) {
  const idx = state.rooms.findIndex(r => r.id == id)
  if (~idx) {
    const ur = Object.assign(new Room(), state.rooms[idx], room)
    state.rooms.splice(idx, 1, ur)
    return ur
  }
}


export function addPeer(roomId: ID, peer: RoomPeer) {
  const room = state.rooms.find(r => r.id == roomId)
  if (room) {
    room.addPeer(peer)
    switch (room.type) {
      case "audio": {
        bus.emit("audioRoomJoined", room, peer)
      }
      case "video": {
        bus.emit("videoRoomJoined", room, peer)
      }
    }
  }
}

export function updatePeer(roomId: ID, peer: { uid: number } & Partial<RoomPeer>) {
  const room = state.rooms.find(r => r.id == roomId)
  room?.updatePeer(peer)
}

export function deletePeer(roomId: ID, uid: number) {
  const room = state.rooms.find(r => r.id == roomId)
  room?.deletePeer(uid)
}


function isJoined(roomId: string, uid: number) {
  const room = state.rooms.find(r => r.id == roomId)
  return room?.isJoined(uid) || false
}

export function setPeerCount(roomId: ID, c: number) {
  const room = state.rooms.find(r => r.id == roomId)
  room && (room.peerCount = c)
}

function addRooms(rooms: feedsWS.FeedsRoom[]) {
  rooms.forEach((room) => {
    const r = Room.create(room)
    if (r) {
      addRoom(r)
    }
  })
}


export declare interface RoomsEvents {
  "feedRoomCreated": (msg: Room) => void
  "audioRoomCreated": (msg: Room, peer: RoomPeer) => void
  "audioRoomJoined": (msg: Room, peer: RoomPeer) => void
  "audioRoomLeft": (msg: Room, peer: RoomPeer) => void
  "audioRoomDestroyed": (msg: Room) => void
  "feedRoomDestroyed": (msg: Room) => void
  "videoRoomCreated": (msg: Room, peer: RoomPeer) => void
  "videoRoomJoined": (msg: Room, peer: RoomPeer) => void
  "videoRoomDestroyed": (msg: Room) => void
}

export class RoomsBus extends TypedEmitter<RoomsEvents> {
  constructor() {
    super()
  }
}

const bus = new RoomsBus()


// audio
export const useRoomsStore = () => {
  wsToRoomStore()

  return {
    state: readonly(state),
    bus,
    addRoom,
    deleteRoom,
    addRooms,
    setPeerCount,
    addPeer,
    updatePeer,
    deletePeer,
    isJoined,
    peerCounts: (feedId: number, type: string) => {
      const room = state.rooms.find((r) => r.feedId == feedId && r.type == type);
      return room?.peerCount || 0;
    },
    feedPeerCounts: () => {
      let c: { [feedId: ID]: { [type: string]: number } } = {}
      state.rooms.forEach(r => {
        c[r.feedId!] ??= { audio: 0, video: 0, feed: 0 }
        c[r.feedId!][r.type] = r.peerCount
      })

      return c
    },
    room: (roomID: ID) => computed(() => state.rooms.find(r => r.id == roomID)),
    peers: (roomID: ID) => computed(() => state.rooms.find(r => r.id == roomID)?.peers),
  }
}

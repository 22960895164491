<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9729 0.0268555L0.0268555 12.9729"
      stroke="black"
      stroke-width="0.5"
      stroke-miterlimit="10"
    />
    <path
      d="M0.0268555 0.0268555L12.9729 12.9729"
      stroke="black"
      stroke-width="0.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {};
</script>


import { Feed, FeedInvitation, ID, Message, PaginatedList } from "@/types";
import { Release } from "@/types/Release";
import { Settings } from "@/types/Settings";
import { CurrentUser } from "@/types/User";
import { MutationTree } from "vuex";
import { VideoReactions } from "../api/messages";
import { State } from "./state";

export type Mutations<S = State> = {
  setCurrentUser(state: S, payload: CurrentUser | null): void;
  setCurrentMessageTag(state: S, payload?: string): void;
  setSettings(state: S, payload: Settings | Partial<Settings>): void
  uiSet(state: S, payload: { [k: string]: any }): void

  //messages
  messages_add(state: S, msg: Message): void
  messages_update(state: S, up: { id: ID } & Partial<Message>): void
  messages_delete(state: S, id: ID): void
  messages_append(state: S, payload: PaginatedList<Message>): void
  messages_setLoading(state: S, loading: boolean): void
  messages_setErr(state: S, err: any): void
  messages_setComments(state: S, payload: Object): void //payload is messageID and array of comments
  messages_setVideoReactions(state: S, payload: VideoReactions): void //payload is messageID and array of comments

  //userMessages
  userMessages_clear(state: S, f: boolean): void
  userMessages_setUser(state: S, user: CurrentUser): void
  userMessages_add(state: S, msg: Message, k: string): void
  userMessages_update(state: S, up: { id: ID } & Partial<Message>): void
  userMessages_delete(state: S, id: ID): void
  userMessages_append(state: S, payload: PaginatedList<Message>): void
  userMessages_setLoading(state: S, loading: boolean): void
  userMessages_setErr(state: S, err: any): void

  //feedMessages
  feedMessages_clear(state: S, f: boolean): void
  feedMessages_setFeed(state: S, feed: Feed): void
  feedMessages_add(state: S, msg: Message, k: string): void
  feedMessages_update(state: S, up: { id: ID } & Partial<Message>): void
  feedMessages_delete(state: S, id: ID): void
  feedMessages_append(state: S, payload: PaginatedList<Message>): void
  feedMessages_setLoading(state: S, loading: boolean): void
  feedMessages_setErr(state: S, err: any): void

  //feeds
  feeds_setFeedsToken(state: S, token: string): void
  feeds_setFeed(state: S, feed: Feed): void
  feeds_add(state: S, msg: Feed, k: string): void
  feeds_update(state: S, up: { id: ID } & Partial<Feed>): void
  feeds_delete(state: S, id: ID): void
  feeds_append(state: S, payload: PaginatedList<Feed>): void
  feeds_setLoading(state: S, loading: boolean): void
  feeds_setErr(state: S, err: any): void

  //feed invitaions
  feedInvitations_clear(state: S, f: boolean): void
  feedInvitations_add(state: S, msg: FeedInvitation): void
  feedInvitations_update(state: S, up: { id: ID } & Partial<FeedInvitation>): void
  feedInvitations_delete(state: S, id: ID): void
  feedInvitations_append(state: S, payload: PaginatedList<FeedInvitation>): void
  feedInvitations_setLoading(state: S, loading: boolean): void
  feedInvitations_setErr(state: S, err: any): void

  //ws
  ws_setIsAuthenticated(state: S, f: boolean): void
  ws_setUID(state: S, uid: number): void
  ws_setConnected(state: S, f: boolean): void
  //debug

  rooms_set(state: S, rooms: any): void


  // releases

  releases_add(state: S, msg: Release, k: string): void
  releases_update(state: S, up: { id: ID } & Partial<Release>): void
  releases_delete(state: S, id: ID): void
  releases_append(state: S, payload: PaginatedList<Release>): void
  releases_setLoading(state: S, loading: boolean): void
  releases_setErr(state: S, err: any): void
}

export const mutations: MutationTree<State> & Mutations = {
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser || undefined;
  },
  setCurrentMessageTag(state, currentMessageTag) {
    state.currentMessageTag = currentMessageTag;
  },
  setSettings(state, settings) {
    state.settings = { ...state.settings, ...settings }
  },
  uiSet(state, data) {
    state.ui = { ...state.ui, ...data }
  },
  //Messages
  messages_add(state, msg) {
    state.messages.add(msg)

    //state.messages = Object.assign(new PaginatedList(), state.messages)
  },
  messages_update(state, msg) {
    state.messages.update(msg.id, msg)
    state.userMessages.update(msg.id, msg)
    state.feedMessages.update(msg.id, msg)

    //// state.messages = Object.assign(new PaginatedList(), state.messages)
  },
  messages_delete(state, id) {
    state.messages.delete(id)
    state.userMessages.delete(id)
    state.feedMessages.delete(id)

    //state.messages = Object.assign(new PaginatedList(), state.messages)
  },
  messages_append(state, msgs) {
    state.messages.append(msgs)
    //state.messages = Object.assign(new PaginatedList(), state.messages)
  },
  messages_setLoading(state, loading) {
    state.messages.loading = loading
    //state.messages = Object.assign(new PaginatedList(), state.messages)
  },
  messages_setErr(state, err) {
    state.messages.err = err
    //state.messages = Object.assign(new PaginatedList(), state.messages)
  },
  messages_setComments(state, payload) {
    const targetMessage = state.messages.find(payload.id)
    if (targetMessage) {
      const activeComments = targetMessage.comments.filter(c => c.typing)
      targetMessage.comments = payload.comments.concat(activeComments)
      state.messages.update(payload.id, targetMessage)
    }
  },

  messages_setVideoReactions(state, videoReactions) {

    Object.entries(videoReactions).forEach((e) => {
      const [mid, vr] = e
      state.messages.update(mid, { videoReactions: vr })
    })
  },
  //
  userMessages_clear(state, f) {
    state.user = undefined
    state.userMessages.clear()
    //state.userMessages = Object.assign(new PaginatedList(), state.userMessages)
  },
  userMessages_setUser(state, user) {
    state.user = user
  },
  userMessages_add(state, msg) {
    state.userMessages.add(msg)
  },
  userMessages_update(state, msg) {
    state.userMessages.update(msg.id, msg)
  },
  userMessages_delete(state, id) {
    state.userMessages.delete(id)
  },
  userMessages_append(state, msgs) {
    state.userMessages.items.splice(state.userMessages.items.length, 0, ...msgs.items)
    state.userMessages.pagination = msgs.pagination
  },
  userMessages_setLoading(state, loading) {
    state.userMessages.loading = loading
  },
  userMessages_setErr(state, err) {
    state.userMessages.err = err
  },

  //feedMessages
  feedMessages_clear(state, f) {
    state.feedMessages.clear()
    state.feed = undefined
  },
  feedMessages_setFeed(state, feed) {
    state.feed = feed
  },
  feedMessages_add(state, msg) {
    state.feedMessages.add(msg)
  },
  feedMessages_update(state, msg) {
    state.feedMessages.update(msg.id, msg)
  },
  feedMessages_delete(state, id) {
    state.feedMessages.delete(id)
  },
  feedMessages_append(state, msgs) {
    state.feedMessages.append(msgs)
  },
  feedMessages_setLoading(state, loading) {
    state.feedMessages.loading = loading
  },
  feedMessages_setErr(state, err) {
    state.feedMessages.err = err
  },
  //feeds
  feeds_setFeedsToken(state, token) {
    state.feedsToken = token
  },
  feeds_setFeed(state, feed) {
    state.feed = feed
  },

  feeds_add(state, feed) {
    state.feeds.add(feed)
  },
  feeds_update(state, feed) {
    state.feeds.update(feed.id, feed)
  },
  feeds_delete(state, id) {
    state.feeds.delete(id)
  },
  feeds_append(state, feeds) {
    state.feeds.append(feeds)
  },
  feeds_setLoading(state, loading) {
    state.feeds.loading = loading
  },
  feeds_setErr(state, err) {
    state.feeds.err = err
  },

  //feed Invitations
  feedInvitations_clear(state, f) {
    state.feedInvitations.clear()
  },

  feedInvitations_add(state, fi) {
    state.feedInvitations.add(fi)
  },

  feedInvitations_update(state, up) {
    state.feedInvitations.update(up.id, up)
  },

  feedInvitations_delete(state, id) {
    state.feedInvitations.delete(id)
  },

  feedInvitations_append(state, list) {
    state.feedInvitations.append(list)
  },

  feedInvitations_setLoading(state, loading) {
    state.feedInvitations.loading = loading
  },

  feedInvitations_setErr(state, err) {
    state.feedInvitations.err = err
  },


  // WS
  ws_setIsAuthenticated(state, f) {
    state.ws.isAuthenticated = f
  },
  ws_setUID(state, uid) {
    state.ws.uid = uid
  },
  ws_setConnected(state, f) {
    state.ws.connected = f
  },
  // User
  users_setProfileLoading(state, loading) {
    state.userMessages.loading = loading
  },

  users_setProfile(state, profile) {
    if (!state.users.profile) {
      state.users.profile = profile
    } else {
      if (profile.pagination.page == 1) {
        state.users.profile.messages = profile.messages
      } else {
        state.users.profile.messages = state.users.profile.messages.concat(profile.messages)
      }
    }
  },

  users_clearProfile(state, p) {
    state.users.profile = undefined
  },

  rooms_set(state, rooms) {
    //@ts-ignore
    state.rooms = rooms
  },

  releases_add(state, msg) {
    state.releases.add(msg)
  },
  releases_update(state, msg) {
    state.releases.update(msg.id, msg)
  },
  releases_delete(state, id) {
    state.releases.delete(id)
  },
  releases_append(state, msgs) {
    state.releases.append(msgs)
  },
  releases_setLoading(state, loading) {
    state.releases.loading = loading
  },
  releases_setErr(state, err) {
    state.releases.err = err
  },
};

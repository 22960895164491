import { MessageCreateResponse } from "@/application/api/messages";
import { Feed, FeedInvitation, Message, PaginatedList, UserWithPic } from "@/types";
import axios from "axios";
import { pick } from "lodash";
import v2 from "../../api/v2";
import { FeedInvitationsPaginated, FeedsJSONPaginated, FeedToken, MessaagesJSONPaginated } from "./types";

export * from "./types";

export type ID = string | number

export async function list(page = 1): Promise<{ res?: PaginatedList<Feed>, err?: any }> {
  try {
    const { data } = await v2.get(`/feeds?page=${page}`) as { data: FeedsJSONPaginated }

    const feeds = data.feeds.map((f) => Feed.initialize(f, data.creators[f.creatorId]))
    return { res: new PaginatedList(feeds, data.pagination) }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

export async function listInvites(page = 1): Promise<PaginatedList<FeedInvitation>> {
  const { data } = await v2.get(`/feeds/invites?page=${page}`) as { data: FeedInvitationsPaginated }

  const feedInvitations = data.feedInvitations.map((inv) => FeedInvitation.initialize(inv, data.feeds[inv.feedId], data.inviters[inv.inviterId], data.inviters[inv.inviteeId!]))
  return new PaginatedList(feedInvitations, data.pagination)
}

export async function acceptInvite(id: ID): Promise<void> {
  return await v2.post(`/feeds/invites/${id}/accept`)
}

export async function rejectInvite(id: ID): Promise<void> {
  return await v2.post(`/feeds/invites/${id}/reject`)
}


export type FeedCreateResponse = { res?: Feed, err?: any }

export async function create(feed: Feed): Promise<FeedCreateResponse> {
  try {
    const { data } = await v2.post("/feeds", { feed: pick(feed, "name", "slug", "image") })
    return { res: data }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}


export async function update(feed: Feed): Promise<FeedCreateResponse> {
  try {
    const { data } = await v2.patch(`/feeds/${feed.id}`, { feed: pick(feed, "name", "slug", "image") })
    return { res: data }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

export async function get(slug: string): Promise<{ res?: Feed, err?: any }> {
  try {
    const { data } = await v2.get(`/feeds/${slug}`)
    return { res: data }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

export async function listMessages(feedId: ID, page = 1): Promise<{ res?: PaginatedList<Message>, err?: any }> {

  try {
    const { data } = await v2.get(`/feeds/${feedId}/messages?page=${page}`) as { data: MessaagesJSONPaginated }
    return {
      res: new PaginatedList(
        data.messages.map(m => Message.initialize(m, data.users[m.userId])),
        data.pagination
      )
    }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}

export async function createMessage(message: Message): Promise<MessageCreateResponse> {
  try {
    let msg = {}
    if (message.type == "text") {
      msg = { body: message.body, mentions: message.mentions, type: "text" }
    } else {
      msg = { body: message.body, mentions: message.mentions, type: "photo", images: message.images }
    }
    const { data } = await v2.post(`/feeds/${message.feedId}/messages`, { message: msg })
    return { message: data }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}


export async function updateMessage(message: Message): Promise<MessageCreateResponse> {
  try {
    let msg = {}
    if (message.type == "text") {
      msg = { body: message.body, mentions: message.mentions, type: "text" }
    } else {
      msg = { body: message.body, mentions: message.mentions, type: "photo", images: message.images }
    }
    const { data } = await v2.patch(`/feeds/${message.feedId}/messages/${message.id}`, { message: msg })
    return { message: data }
  } catch (error: unknown) {
    if (error && axios.isAxiosError(error)) {
      return { err: error?.response?.data }
    }
    throw error
  }
}


export async function saveMessage(message: Message) {
  if (Message.isNew(message)) {
    message.type = Message.messageType(message)
    return createMessage(message)
  } else {
    return updateMessage(message)
  }
}

export async function destroyMessage(message: Message): Promise<boolean> {
  await v2.delete(`/feeds/${message.feedId}/messages/${message.id}`)
  return true
}

export async function listInvitations(feedId: ID, page = 1): Promise<PaginatedList<FeedInvitation>> {
  const { data } = await v2.get(`/feeds/${feedId}/invitations?page=${page}`) as { data: FeedInvitationsPaginated }

  return new PaginatedList(
    data.feedInvitations.map((inv) => FeedInvitation.initialize(inv, data.feeds[inv.feedId], data.inviters[inv.inviterId], (data.invitees || {})[inv.inviteeId!])),
    data.pagination

  )
}

export async function createInvitation(feedId: ID, feedInvitation: { inviteeId?: number }): Promise<FeedInvitation> {
  try {
    const { data } = await v2.post(`/feeds/${feedId}/invitations`, { feedInvitation })
    return data
  }
  catch(err) {
    return err.response.data
  }
}

export async function deleteInvitation(feedId: ID, id: ID): Promise<void> {
  return await v2.delete(`/feeds/${feedId}/invitations/${id}`)
}


export async function searchUsers(q: string): Promise<UserWithPic[]> {
  const { data } = await v2.get(`/users/search?q=${q}`) as { data: UserWithPic[] }

  return data.map(u => {
    u.name = `@${u.name}`
    return u
  })
}

export async function listUsers(feedId: ID, page = 1): Promise<PaginatedList<UserWithPic>> {
  const { data } = await v2.get(`/feeds/${feedId}/users?page=${page}`)
  return new PaginatedList(data.users, data.pagination)
}

export async function deleteUser(feedId: ID, userId: ID): Promise<void> {
  return await v2.delete(`/feeds/${feedId}/users/${userId}`)
}

export async function getToken(): Promise<{ res?: FeedToken, err?: any }> {
  try {
    const { data } = await v2.get(`/feeds_token`) as { data: { token: string } }
    return { res: data.token }
  } catch (err: unknown) {
    if (err && axios.isAxiosError(err)) {
      return { err: err?.response?.data }
    }
    throw err
  }
}

export default {
  users: {
    search: searchUsers
  },
  feeds: {
    list,
    create,
    update,
    get,
    listMessages,
    saveMessage,
    createMessage,
    updateMessage,
    destroyMessage,
    listInvitations,
    createInvitation,
    deleteInvitation,
    listUsers,
    deleteUser,
    listInvites,
    acceptInvite,
    rejectInvite,
    getToken
  }
}

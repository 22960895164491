import { CurrentUser } from '@/types/User';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  stripePublishableKey(state: State): string
  currentUser(state: State): CurrentUser | undefined
  appEmbed(state: State): boolean
  videoHost(state: State): string
  deviceId(state: State): string
}

export const getters: GetterTree<State, State> & Getters = {
  stripePublishableKey: (state) => state.settings.stripePublishableKey,
  currentUser: (state) => state.currentUser,
  appEmbed: (state) => state.settings.appEmbed,
  videoHost: (state) => state.settings.videoHost,
  deviceId: (state) => state.settings.deviceId
}

<script setup lang="ts">
import Spinner from "@/application/bbs20/components/Spinner.vue";
import { Room, useBbs20Store } from "@/application/bbs20/store";
import { audioRoomCreate } from "@/application/feeds/store/ws";
import { useStore } from "@/application/store";
import MicrophoneIcon from "@/svg/MicrophoneIcon.vue";
import VideoIcon from "@/svg/VideoIcon.vue";
import { computed } from "vue";

const props = defineProps({
  feedId: {
    type: Number,
    default: 0,
  },
});

const { state } = useStore();

const { state: roomState, ws, getRoom } = useBbs20Store();

const vroomID = () => `${roomState.stage}:${props.feedId}:video`;
const aroomID = () => `${roomState.stage}:${props.feedId}:audio`;

const videoRoom = getRoom(vroomID());
const audioRoom = getRoom(aroomID());
const isConnected = computed(() => roomState.isConnected);

const micBtnClicked = () => {
  audioRoomCreate(state.feed?.id || 0);
};

const videoBtnClicked = async () => {
  ws.createRoom(vroomID());
};

const connected = computed(() => state.ws.connected);
const canAudio = computed(
  () =>
    state.settings.features &&
    state.settings.features.webAudioSpeak &&
    state.ws.connected &&
    state.currentUser?.canAudioChat
);
const canVideo = computed(
  () =>
    state.settings.features &&
    state.settings.features.webLivevideoBroadcast &&
    state.ws.connected &&
    state.currentUser?.canBroadcast
);
const canText = computed(() => true || state.currentUser?.canPostText);
const canPhoto = computed(() => true || state.currentUser?.canPostImage);
const show = computed(
  () =>
    connected.value &&
    state.currentUser?.status === "active" &&
    !state.messages.items.find((m) => m.editing === true)
);
const numActiveButtons = computed(
  () => (canAudio.value ? 1 : 0) + (canVideo.value ? 1 : 0) + (canPhoto.value ? 1 : 0)
);

const showSpinner = (room?: Room) => {
  return ["creating", "connecting"].includes(room?.streamState || "");
};
</script>
<template>
  <div
    :class="['post-buttons', `buttons-${numActiveButtons}`, { 'not-connected': !isConnected }]"
    v-if="show"
  >
    <div>
      <div class="post-limit-message" v-if="!canPhoto && !canText">
        you've reached your limit of posts for today,
        <br />please return tomorrow.
      </div>
    </div>
    <template v-if="show">
      <div ontouchstart="" class="btn mic" @click="micBtnClicked" v-if="canAudio">
        <spinner v-if="showSpinner(audioRoom)" />
        <microphone-icon v-else />
      </div>
      <div
        ontouchstart=""
        class="btn btn-video no-highlight"
        v-if="canVideo"
        @click="videoBtnClicked"
      >
        <spinner v-if="showSpinner(videoRoom)" />
        <video-icon v-else />
      </div>
    </template>

    <slot />
  </div>
</template>

<style lang="scss">
@use "sass:math";
@import "../../variables.scss";
$button-width: 44px;

.post-buttons {
  text-align: center;
  .btn {
    cursor: pointer;
  }
  .mic {
    margin-right: -3px;
  }

  &.not-connected {
    .btn-mic,
    .btn-video {
      opacity: 0.3;
    }
  }
}
</style>

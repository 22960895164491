<template>
  <div class="profile-grid">
    <div class="width-wrapper">
      <dense-block v-for="message in messages" :message="message" :key="message.id" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DenseBlock from "@/application/components/blocks/DenseBlock.vue"

export default defineComponent({
  components: {
    DenseBlock
  },
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  methods: {
    watchScroll() {
      let scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollPos / this.$el.offsetHeight > 0.75) {
        this.$emit("loadMore");
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.watchScroll);
  }
});
</script>

<style lang="scss">

.profile-grid {
  position: relative;
  box-sizing: border-box;
  padding: 8px 8px 40px;
  background-color: var(--white);
  .width-wrapper {
    // max-width: 1660px;
    position: relative;
    margin: 0 auto;
  }
}
</style>

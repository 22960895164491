import { useStore } from "@/application/store"
import { useWebSocketController } from "@/application/use/useWebSocket"
import { Feed } from "@/types"
import { FeedToken, ID } from "../api"
import { Room, useRoomsStore } from "./rooms"


export async function feedsSubscribe(token: FeedToken) {
  // const { useWebSocketController, setPeerCounts } = useStore()
  const wsc = await useWebSocketController()

  const { res, err } = await wsc.feedsSubscribe(token)
  // res && setPeerCounts(res.peerCounts)

  return { res, err }
}
export async function feedsGetRooms(token: FeedToken) {


  const { addRooms } = useRoomsStore()

  const wsc = await useWebSocketController()
  const { res, err } = await wsc.feedsGetRooms(token)
  res && addRooms(res.rooms)

  return { res, err }
}
export async function feedJoin(feed: Feed) {
  const { dispatch } = useStore()
  const { addRoom, addPeer } = useRoomsStore()
  const wsc = await useWebSocketController()
  const { res: token, err: error } = await dispatch("feeds_loadFeedsToken", true)
  if (token) {
    const { res, err } = await wsc.feedJoin(feed, token)
    res && addRoom(Room.createFeed(res))

    return { res, err }
  }
}

export async function feedLeave(feed: Feed) {
  const { deletePeer } = useRoomsStore()
  const wsc = await useWebSocketController()

  const { res, err } = await wsc.feedLeave(feed)
  res && deletePeer(res.roomId, wsc.uid)
  return { res, err }
}

export async function audioRoomCreate(feedId: ID) {


  const { addRoom } = useRoomsStore()

  const wsc = await useWebSocketController()

  const { res, err } = await wsc.audioRoomCreate(feedId)
  res && addRoom(Room.createAudio(res))

  return { res, err }
}
export async function audioRoomJoin(feedId: ID) {


  const wsc = await useWebSocketController()
  const { addPeer, setPeerCount } = useRoomsStore()

  const { res, err } = await wsc.audioRoomJoin(feedId)
  if (res) {
    addPeer(res.roomId, res.peer)
    setPeerCount(res.roomId, res.peerCount)
  }

  return { res, err }
}
export async function audioRoomLeave(feedId: ID) {


  const wsc = await useWebSocketController()
  const { deletePeer, setPeerCount } = useRoomsStore()

  const { res, err } = await wsc.audioRoomLeave(feedId)
  if (res) {
    deletePeer(res.roomId, res.peer.uid)
    setPeerCount(res.roomId, res.peerCount)
  }
  return { res, err }
}
export async function audioRoomPublish(feedId: ID) {


  const { updatePeer } = useRoomsStore()
  const wsc = await useWebSocketController()

  const { res, err } = await wsc.audioRoomPublish(feedId)
  res && updatePeer(res.roomId, { uid: wsc.uid, published: true })
  return { res, err }
}

export async function audioRoomUnpublish(feedId: ID) {


  const { updatePeer } = useRoomsStore()
  const wsc = await useWebSocketController()

  const { res, err } = await wsc.audioRoomUnpublish(feedId)
  res && updatePeer(res.roomId, { uid: wsc.uid, published: false })
  return { res, err }

}
export async function audioRoomStartSpeaking(feedId: ID, at: number) {


  const { updatePeer } = useRoomsStore()
  const wsc = await useWebSocketController()

  const { res, err } = await wsc.audioRoomStartSpeaking(feedId, at)
  res && updatePeer(res.roomId, { uid: wsc.uid, speaking: true })
  return { res, err }
}
export async function audioRoomStopSpeaking(feedId: ID, at: number) {


  const { updatePeer } = useRoomsStore()
  const wsc = await useWebSocketController()

  const { res, err } = await wsc.audioRoomStopSpeaking(feedId, at)
  res && updatePeer(res.roomId, { uid: wsc.uid, speaking: false })
  return { res, err }
}

export async function videoRoomCreate(feedId: ID) {


  const { addRoom } = useRoomsStore()

  const wsc = await useWebSocketController()

  const { res, err } = await wsc.videoRoomCreate(feedId)
  res && addRoom(Room.createVideo(res))

  return { res, err }
}

export async function videoRoomJoin(feedId: ID, roomID: string) {


  const { addRoom } = useRoomsStore()

  const wsc = await useWebSocketController()

  const { res, err } = await wsc.videoRoomJoin(feedId, roomID)

  const { addPeer, setPeerCount } = useRoomsStore()

  if (res) {
    addPeer(res.id, res.body.peer)
    setPeerCount(res.id, res.body.info.count)
  }

  return { res, err }
}

export async function videoRoomLeave(feedId: ID) {


  const { addRoom } = useRoomsStore()

  const wsc = await useWebSocketController()

  const { res, err } = await wsc.videoRoomLeave(feedId)

  const { deletePeer, setPeerCount } = useRoomsStore()

  if (res) {
    deletePeer(res.id, res.uid)
    setPeerCount(res.id, res.body.count)
  }

  return { res, err }
}

// //{"type":"liveStreamStarted","id":"E3B0BA26-2D28-4DF5-8E30-9579E16AD005_33FFA0D2","msgId":"","feedId":0,"uid":1013,"body":"{\"id\":\"E3B0BA26-2D28-4DF5-8E30-9579E16AD005_33FFA0D2\",\"recId\":\"01FPYJTE5B3C9YPPGGMT6Z76WC\",\"createdByDeviceId\":\"E3B0BA26-2D28-4DF5-8E30-9579E16AD005\",\"count\":3,\"created\":\"2021-12-15T09:19:57.525606+01:00\",\"meta\":\"{\\\"userId\\\":69,\\\"username\\\":\\\"jana\\\"}\",\"started\":true,\"type\":\"video\",\"peers\":null}","version":1}

export async function videoRoomStarted(feedId: ID, roomId: string) {
  const wsc = await useWebSocketController()
  const { res } = await wsc.videoRoomStarted(feedId, roomId)

  if (res) {
    // updateRoom(res.id, { started: true })
  }
}


///`{\"type\":\"liveStreamAnnounce\",\"id\":\"46893276-D25D-4517-A0B1-56F1AA2166D0\",\"body\":
//\"{\\"reaction\\":{\\"dimension\\":68.404853694114252,\\"userId\\":37,\\"yShift\\":83.000414586786519,\\"username\\":\\"osag\\",\\"duration\\":3.189394321280826,\\"createdAt\\":\\"2020-11-26T17:17:16.000Z\\",\\"body\\":\\"asd\\"}}\",
//\"version\":1}`
export async function videoRoomReaction(feedId: ID, roomId: string, emoji: string) {
  const wsc = await useWebSocketController()
  const { res } = await wsc.videoRoomReaction(feedId, roomId, emoji)
  return { res, err: undefined }
}

<template>
  <div class="post-image" ontouchstart="">
    <div class="post-image-wrap">
      <input type="file" @change="onFileChanged" multiple="true" accept=".jpeg,.jpg,.png" />
      <div class="img-upload"></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <circle cx="256" cy="280" r="63" />
        <path
          d="M440 96h-88l-32-32H192l-32 32H72c-22.092 0-40 17.908-40 40v272c0 22.092 17.908 40 40 40h368c22.092 0 40-17.908 40-40V136c0-22.092-17.908-40-40-40zM256 392c-61.855 0-112-50.145-112-112s50.145-112 112-112 112 50.145 112 112-50.145 112-112 112z"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
export type FileEventTarget = EventTarget & { files: FileList };

export default {
  emits: {
    files(files: Array<File>) {
      return files;
    },
  },
  setup(props, { emit }) {
    const onFileChanged = (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (target.files?.length == 0) {
        return;
      }
      emit("files", target.files);
    };

    return {
      onFileChanged,
    };
  },
};
</script>
<style scoped lang="scss">
@use "sass:math";
@import "../variables.scss";
$button-width: 44px;

.post-image {
  .post-image-wrap {
    position: relative;
    width: $button-width;
    height: $button-width;
    overflow: hidden;
    cursor: pointer;
  }
  * {
    cursor: pointer;
  }

  input[type="file"] {
    width: $button-width;
    height: $button-width;
    position: absolute;
    border: none;
    outline: none;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .img-upload {
    width: $button-width;
    height: $button-width;
    outline: none;
    border: none;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  svg {
    position: absolute;
    pointer-events: none;
    z-index: 3;
    // width: 40px;
    height: 22px;
    width: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    circle,
    path {
      // fill: var(--white);
      fill: #828080
    }
  }
}
</style>

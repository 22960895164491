import { CurrentUser, Feed, FeedInvitation, Message, PaginatedList, Pagination, Profile, Settings } from "@/types";
import { Release } from "@/types/Release";

export type UserState = {
  profile?: Profile
}

export type MessagesState = {
  messages: Message[]
  loading: boolean
  pagination: Pagination
}

export class State {

  static keysFor(m: Message) {
    return `f${m.feedId}`
  }

  currentUser?: CurrentUser
  settings!: Settings
  currentMessageTag?: string
  //store ui state props eg scroll pos .... ?
  ui: { [type: string]: any } = {}

  messages = new PaginatedList<Message>()
  userMessages = new PaginatedList<Message>()
  user?: CurrentUser

  feedMessages = new PaginatedList<Message>()
  feed?: Feed
  feedInvitations = new PaginatedList<FeedInvitation>()

  feedsToken?: string
  feeds = new PaginatedList<Feed>()

  releases = new PaginatedList<Release>()

  ws = {
    connected: false,
    uid: 0,
    isAuthenticated: false
  }

  users: UserState = {
    profile: undefined,
  }

};

import { v1 } from "@/application/api/v2";
import feedsApi, { FeedCreateResponse } from "@/application/feeds/api";
import { Feed, Form, ID, Message, PaginatedList, User, UserWithPic } from "@/types";
import { Release } from "@/types/Release";
import { CurrentUser } from "@/types/User";
import axios from "axios";
import { ActionContext, ActionTree, CommitOptions, DispatchOptions } from "vuex";
import api from "../api";
import { ListOptions, MessageCreateResponse, VideoReactions } from "../api/messages";
import { CurrentUserResponse } from "../api/user";
import localStorageService from "../lib/localStorageService";
import { Mutations } from "./mutations";
import { State } from "./state";
import { messageDeleted } from "./ws";

export type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    o?: CommitOptions
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    k: K,
    payload?: Parameters<Actions[K]>[1],
    o?: DispatchOptions): ReturnType<Actions[K]>;
} & Omit<ActionContext<State, State>, "commit" | "dispatch">


export interface Actions {
  loadSettings(ctx: AugmentedActionContext, payload: null): void
  setCurrentMessageTag({ commit }: AugmentedActionContext, payload?: string): void
  loadCurrentUser(ctx: AugmentedActionContext, payload?: any): Promise<{ user?: CurrentUser, err?: any }>
  sendLiveVideoNotification(ctx: AugmentedActionContext, payload: { roomId: string }): void
  logout(ctx: AugmentedActionContext, payload: undefined): void

  newTextMessageForm(ctx: AugmentedActionContext, pl: { feedId: ID }): void
  newPhotoMessageForm(ctx: AugmentedActionContext, pl: { file?: File, feedId: ID }): void
  showEditCurrentUserForm(ctx: AugmentedActionContext, payload?: undefined): Form<CurrentUser>
  submitCurrentUserForm(ctx: AugmentedActionContext, payload: Form<CurrentUser>): Promise<CurrentUserResponse>
  submitMessageForm(ctx: AugmentedActionContext, payload: Form<Message>): Promise<MessageCreateResponse>
  submitFeedForm(ctx: AugmentedActionContext, payload: Form<Feed>): Promise<FeedCreateResponse>
  // Messages
  messages_list(ctx: AugmentedActionContext, payload: ListOptions): Promise<{ res?: PaginatedList<Message>, err?: any }>;
  messages_loadVideoRections(ctx: AugmentedActionContext, payload: Array<number>): Promise<{ res?: VideoReactions, err?: any }>;
  messages_destroy(ctx: AugmentedActionContext, payload: ID): Promise<boolean>;

  comments_load(ctx: AugmentedActionContext, payload: ID): boolean;

  // Users
  users_loadProfile(ctx: AugmentedActionContext, opt: { name: string } & ListOptions): Promise<{ res?: { user: CurrentUser, messages: PaginatedList<Message> }, err?: any }>;

  //feedMessages
  feedMessages_loadFeed(ctx: AugmentedActionContext, opt: string): Promise<{ res?: Feed, err?: any }>;
  feedMessages_list(ctx: AugmentedActionContext, opt: { slug: string, page: number }): Promise<{ res?: PaginatedList<Message>, err?: any }>;
  // feedMessages_list(ctx: AugmentedActionContext, opt: { slug: string, page: number }): Promise<{ res?: PaginatedList<Message>, err?: any }>;

  //feeds
  feeds_loadFeedsToken(ctx: AugmentedActionContext, opt?: any): Promise<{ res?: string, err?: any }>;
  feeds_list(ctx: AugmentedActionContext, opt?: any): Promise<{ res?: PaginatedList<Feed>, err?: any }>;

  // releases
  releases_list(ctx: AugmentedActionContext, page: number): Promise<{ res?: PaginatedList<Release>, err?: any }>;
}

export const actions: ActionTree<State, State> & Actions = {
  comments_load({ commit }, messageID) {

    v1.get(`/messages/${messageID}/comments.json`).then(res => {
      if (res.data.comments.length) {
        commit("messages_setComments", { id: messageID, comments: res.data.comments })
      }
    });

    return true
  },
  loadSettings({ commit }) {
    api.settings.load().then((settings) => {
      commit("setSettings", settings);
    })
  },
  setCurrentMessageTag({ commit }, currentMessageTag) {
    commit("setCurrentMessageTag", currentMessageTag);
  },
  loadCurrentUser({ commit, dispatch }, reload?: boolean) {
    return api.user.loadCurrentUser(reload)
      .then((user) => {
        commit("setCurrentUser", user);
        return { user };
      })
      .catch((err) => {
        return { err }
      })
  },
  sendLiveVideoNotification(ctx, { roomId }) {
    axios.post("/live_video.json", { roomId });
  },
  logout({ commit, dispatch }) {
    localStorageService.deleteOAuth();
    commit("setCurrentUser", null);
    commit("ws_setIsAuthenticated", false)
  },
  newTextMessageForm({ commit, state }, { feedId }) {
    const { id, name, image } = state.currentUser as User
    const user = UserWithPic.initialize({ id, name, profilePicUrl: image.url })
    const message = Message.newTextMessage(new Feed(feedId as number), user)
    message.editing = true
    if (feedId == 0) {
      commit("messages_add", message)
      if (state.user?.id == state.currentUser?.id) {
        commit("userMessages_add", message)
      }
    } else {
      commit("feedMessages_add", message)
    }
  },
  newPhotoMessageForm({ commit, state }, { file, feedId }) {
    const { id, name, image } = state.currentUser as User
    const user = UserWithPic.initialize({ id, name, profilePicUrl: image.url })
    const message = Message.newPhotoMessage(new Feed(feedId as number), user, file)
    message.imageFile = file
    message.editing = true


    if (feedId == 0) {
      commit("messages_add", message)
    } else {
      commit("feedMessages_add", message)
    }
  },

  showEditCurrentUserForm({ commit, state }) {
    const user = state.currentUser as CurrentUser
    const form = new Form({ ...user }, "currentUser", true)
    // commit("addForm", form)
    return form
  },
  async submitCurrentUserForm({ commit, dispatch }, form: Form<CurrentUser>) {
    form.busy = true
    console.log(form.obj)
    const { err, currentUser } = await api.user.updateCurrentUser(form.obj as CurrentUser)
    form.busy = false
    if (err) {
      form.errors = err.errors
      return { err, currentUser }
    }
    commit("setCurrentUser", currentUser)
    return { currentUser }
  },
  async submitMessageForm({ commit, dispatch }, form: Form<Message>) {
    form.busy = true
    const msg = form.obj as Message
    const tmpId = msg.id as string
    // commit("updateForm", { key: form.key, busy: true })
    const isNew = Message.isNew(msg)
    const feedId = msg.feedId || 0
    const { message, err } = feedId != 0
      ? await feedsApi.feeds.saveMessage(msg)
      : await api.messages.save(msg)

    form.busy = false
    if (err) {
      form.errors = err.errors
      return { err }
    }


    if (feedId == 0) {
      commit("messages_add", { ...message as Message, editing: false, tmpId });
      commit("userMessages_add", { ...message as Message, editing: false, tmpId });
    } else {
      commit("feedMessages_add", { ...message as Message, editing: false, tmpId });
    }

    commit("messages_update", { ...message as Message, editing: false, tmpId });

    dispatch("loadCurrentUser", true)
    return { message }
  },

  async submitFeedForm({ commit, dispatch }, form: Form<Feed>) {
    form.busy = true
    const feed = form.obj as Feed
    const isNew = Message.isNew(feed)
    const { res, err } = isNew
      ? await feedsApi.feeds.create(feed)
      : await feedsApi.feeds.update(feed)
    form.busy = false
    if (err) {
      form.errors = err.errors
      return { err }
    }
    if (!isNew) {
      commit("feeds_update", { ...res as Feed, editing: false });
    } else {
      commit("feeds_add", { ...res as Feed });
    }

    return { res }
  },

  // messages
  async messages_list({ dispatch, commit }, opt) {
    commit("messages_setLoading", true)
    const { res, err } = await api.messages.list(opt)
    commit("messages_setErr", err)
    commit("messages_append", res)
    dispatch("messages_loadVideoRections", res!.items.map(m => Number(m.id)))
    commit("messages_setLoading", false)
    return { res, err }
  },

  async messages_loadVideoRections({ commit }, messageIds: Array<number | ID>) {
    const { res, err } = await api.messages.loadVideoRections(messageIds)
    res && commit("messages_setVideoReactions", res)
    return { res, err }
  },

  async messages_destroy({ commit, state }, id) {
    const msg = state.messages.find(id) || state.userMessages.find(id)
    const fMsg = state.feedMessages.find(id)
    if (msg) {
      await api.messages.destroy(id as number)
      commit("messages_delete", id)
      messageDeleted(msg)
      return true
    } else if (fMsg) {
      const succ = await feedsApi.feeds.destroyMessage(fMsg)
      if (succ) {
        commit("messages_delete", id)
        messageDeleted(fMsg)
      }
      return succ

    } else {
      return Promise.resolve(false)
    }
  },
  //user
  async users_loadProfile({ commit, state }, params) {
    commit("userMessages_setLoading", true)
    try {
      const { res, err } = await api.user.loadProfile(params)
      if (res) {
        commit("userMessages_setUser", res.user)
        commit("userMessages_append", res.messages)
      }

      commit("userMessages_setLoading", false)
      commit("userMessages_setErr", err)
      return { res }
    } catch (err) {
      return { err }
    }

  },
  //feedMessages
  async feedMessages_loadFeed({ commit, state }, slug) {
    if (state.feed?.slug == slug) {
      return Promise.resolve({ res: state.feed })
    }
    commit("feedMessages_setLoading", false)
    const { res, err } = await feedsApi.feeds.get(slug)
    const { res: msgs, err: mErr } = await feedsApi.feeds.listMessages(1)
    commit("feedMessages_setFeed", res)
    commit("feedMessages_setErr", err || mErr)
    msgs && commit("feedMessages_append", msgs)
    commit("feedMessages_setLoading", false)
    return { res, err }
  },
  async feedMessages_list({ commit, state }, { slug, page }) {
    commit("feedMessages_setLoading", false)
    if (state.feed?.slug != slug) {
      const { res, err } = await feedsApi.feeds.get(slug)
      commit("feedMessages_setFeed", res)
      commit("feedMessages_setErr", err)
    }

    const { res, err } = await feedsApi.feeds.listMessages(state.feed!.id, page)
    commit("feedMessages_append", res)
    commit("feedMessages_setErr", err)
    commit("feedMessages_setLoading", false)
    return { res, err }
  },

  async feeds_loadFeedsToken({ commit }) {
    const { res, err } = await feedsApi.feeds.getToken()
    commit("feeds_setFeedsToken", res)
    return { res, err }
  },

  async feeds_list({ commit }, page) {
    const { res, err } = await feedsApi.feeds.list(page)
    if (res) {
      commit("feeds_append", res)
      commit("feeds_setErr", err)
      commit("feeds_setErr", err)
      commit("feeds_setLoading", false)
    } else {
      commit("feeds_setLoading", false)
    }
    return { res, err }
  },

  async releases_list({ commit }, page) {
    const { res, err } = await api.releases.list(page)
    if (res) {
      commit("releases_append", res)
      commit("releases_setErr", err)
      commit("releases_setErr", err)
      commit("releases_setLoading", false)
    } else {
      commit("releases_setLoading", false)
    }
    return { res, err }
  }
};


import { Message, MessageType, v1, v2 } from "@/types";
import { once, pick } from "lodash";
import api from "../api";
// import api from "../feeds/api";
// import v2 from "../api/v2";
import { PREVIEW_PREFIX } from "../use/useImageUpload";
import { useWebSocketController } from "../use/useWebSocket";
import { Store } from "./Store";
import { bus } from "./WsController";




export function startMessage2Msg(msg: v1.msg.startMessage) {
  const name = msg.body.username || ""
  const id = msg.body.userId || 0
  return Message.initialize(
    {
      ...pick(msg, "feedId", "userId"),
      ...pick(msg.body, "id", "tmpId"),
      body: ""
    },
    { name, id }
  )
}

export function typing2Msg(msg: v1.msg.typing) {
  const name = msg.body.username || ""
  const id = msg.body.userId || 0

  return Message.initialize(
    {
      ...pick(msg, "feedId", "userId"),
      ...pick(msg.body, "id", "body"),
      typing: true
    },
    { name, id }
  )
}

export function imageSelected2Msg(msg: v1.msg.imageSelected) {
  const img = {
    ...pick(msg.body, "imageWidth", "imageHeight"),
    url: PREVIEW_PREFIX + msg.body.previewBase64,
    imageKey: "",
    position: 0,
  }
  const name = msg.body.username || ""
  const id = msg.body.userId || 0
  const profilePicUrl = msg.body.profilePicUrl
  const props = {
    ...pick(msg, "feedId", "userId"),
    ...pick(msg.body, "id"),
    type: MessageType.photo,
    images: [img]
  }

  return Message.initialize(props, { name, id, profilePicUrl })
}

async function userStatusChanged(store: Store) {
  const { user, err } = await store.dispatch("loadCurrentUser", true)
  if (user) {
    const { res, err } = await api.user.loadRefreshToken()
    console.log(res, err)
    const wsc = await useWebSocketController()
    wsc.reconnect()
  }
}

function handleMessage(store: Store, msg: { type: string } & any) {
  switch (msg.type) {
    case v2.MessageType.userStatusChanged: {
      userStatusChanged(store)
      // store.dispatch("loadCurrentUser", true)
      break
    }
    case v1.MessageType.endComment: {
      
      const targetMessage = store.state.messages.items.find(m => m.id == msg.body.messageId)
      if (targetMessage) {
        targetMessage.comments = targetMessage.comments.filter(c => {
          if (c.userId == msg.userId && c.typing) return false
          else return true
        })
        store.commit("messages_update", targetMessage)
      }
      
    }
    case v1.MessageType.commentPosted: {
      const targetMessage = store.state.messages.items.find(m => m.id == msg.body.messageId)
      if (targetMessage) {
        targetMessage.comments = targetMessage.comments.map(c => {
          if (c.userId == msg.userId && c.typing) c.typing = false
          return c
        })
        store.commit("messages_update", targetMessage)  
      }
      break
    }
    case v1.MessageType.typingComment: {
      if (!msg.body.body) return
      msg.typing = true
      // Look for message with the comment
      const targetMessageIdx = store.state.messages.items.findIndex(mess => mess.id == msg.body.messageId)
      if (~targetMessageIdx) {
        const targetMessage = store.state.messages.items[targetMessageIdx]
        if (!targetMessage.comments) targetMessage.comments = [] // Posts created over WS do not have comments set
        // Look for comment on message. If it doesn't exsist then create it. If it does exsist update it
        const targetCommentIdx = targetMessage.comments.findIndex(c => {
          return c.userId == msg.userId && c.typing
        })
        if (~targetCommentIdx) targetMessage.comments[targetCommentIdx].body = msg.body.body
        else {
          msg.mentions = []
          msg.user = {name: msg.body.username}
          msg.body = msg.body.body
          targetMessage.commentsCount++
          targetMessage.comments.push(msg)
        }
        store.commit("messages_update", targetMessage)
      }
      break
    }
    case v1.MessageType.startMessage: {
      store.commit("messages_add", startMessage2Msg(msg))
      break
    }
    case v1.MessageType.typing:
      const m = typing2Msg(msg)
      if (!~store.state.messages.items.findIndex(mess => mess.id == m.id)) {
        if (m.feedId && m.feedId == store.state.feed?.id) {
          const exists = store.state.feedMessages.find(m.id)
          exists
            ? store.commit("feedMessages_update", m)
            : store.commit("feedMessages_add", m)
        } else {
          store.commit("messages_add", m)
          if (m.userId == store.state.user?.id) {
            store.commit("userMessages_add", m)
          }
        }
      } else {
        store.commit("messages_update", m)
      }
      break
    case v1.MessageType.endTyping: {
      const m = typing2Msg(msg)
      m.typing = false
      store.commit("messages_update", m)
      break
    }
    case v1.MessageType.imageSelected: {
      const idx = store.state.messages.items.findIndex((m) => m.id == msg.id);
      const m = imageSelected2Msg(msg)

      if (~idx) {
        store.commit("messages_update", { id: m.id, images: m.images })
      } else {
        store.commit("messages_add", m)
      }

      break
    }
    case v1.MessageType.imageProgress: {
      store.commit("messages_update", { id: msg.body.id, progress: msg.body.progress })
      break
    }
    case v1.MessageType.endText:
    case v1.MessageType.messageDeleted:
      store.commit("messages_delete", msg.body.id)
      break
    case v1.MessageType.textPostedSuccess:
    case v1.MessageType.imagePostedSuccess: {
      store.commit("messages_update", { id: msg.body.id, tmpId: msg.body.tmpId, progress: 0, typing: false, images: msg.body.images, user: msg.body.user })
      break
    }
  }
}

export const wsToStore = once((store: Store) => {
  bus.on("notify", (m) => handleMessage(store, m as v1.Msg))
  bus.on("feed.notify", (m) => handleMessage(store, m as v1.Msg))
})

<template>
  <svg class="slash-icon" width="223" height="391" viewBox="0 0 223 391" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M223 0H160.5L0 391H62.5L223 0Z" fill="#eb232c"/>
  </svg>
</template>

<script>

export default {

}

</script>

<style lang="scss">

.slash-icon {

}

</style>
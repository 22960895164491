<template>
  <span class="typing-cursor">_</span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>
<style scoped lang="scss">
$ti-color-bg: #888888;

.typing-cursor {
  color: $ti-color-bg;
  animation: 0.5s blink infinite ease-out;
  opacity: 0;
  font-weight: 700;
  margin-left: 2px;
  display: inline-block !important;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(0.44);
  }
}
</style>

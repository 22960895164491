<template>
  <swiper class="message-gallery" ref="swiper" @slideChangeTransitionStart="updateIndex">
    <swiper-slide v-for="img in images" :key="img.url" class="slide">
      <message-image :image="img" :is-visible="isVisible" />
    </swiper-slide>
    <div class="swiper-bullets">
      <div
        v-for="(img, idx) in images"
        :class="['dot', { active: imgIndex == idx }]"
        :key="img.url"
      ></div>
    </div>
  </swiper>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import messageImage from "../site/MessageImage.vue";
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    messageImage,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgIndex: 0,
      swiperOptions: {
        // Enable lazy loading
        lazy: true,
        // height: imgHeight(this.message),
        autoHeight: true, //enable auto height
      },
    };
  },
  methods: {
    updateIndex(swiper) {
      this.imgIndex = swiper.activeIndex;
    },
  },
});
</script>

<style lang="scss" scoped>
.message-gallery {
  position: relative;
  overflow: hidden;

  :deep(.swiper-wrapper) {
    white-space: nowrap;
    align-items: center;
  }
  .slide {
    display: inline-block;
    vertical-align: middle;
  }
  .swiper-bullets {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(156 156 156 / 40%);
    // width: 100%;
    bottom: 10px;
    z-index: 100;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 100%;
      background-color: #fff;
      display: inline-block;
      margin: 0 6px;
      &.active {
        background-color: #000;
      }
    }
  }
}
</style>

<template>
  <figure ref="messageImage" class="message-image" :style="figureStyles">
    <img :src="readyImageUrl" :class="{ 'image-load-succeeded': imageLoadSucceeded }" />
    <svg
      v-if="showLoader"
      :class="{ 'image-load-succeeded': !imageLoadSucceeded }"
      width="200px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="lds-rolling"
      style="background: none"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#6666"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
        transform="rotate(139.966 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1.2s"
          begin="0s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </svg>

    <router-link v-if="tag" class="tag" :to="`/`" v-text="tag" />
  </figure>
</template>

<script lang="ts">
// TS stuff
import { defineComponent } from "vue";

// Utils
import { imgURLImageoptim } from "../../utils";

export default defineComponent({
  data() {
    return {
      reloadTimer: false,
      imageLoadSucceeded: false,
      readyImageUrl: false,
      showLoader: false,
    };
  },
  mounted() {
    if (this.isVisible) {
      this.loadImage(); // If it's visible on load, we'll load it right now
    }
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    isIntrinsic: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: "",
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.loadImage();
      }
    },
  },
  methods: {
    loadImage() {
      const img = new Image();
      img.src = this.imageUrl;
      img
        .decode()
        .then(() => {
          this.readyImageUrl = this.imageUrl;
          this.imageLoadSucceeded = true;
          clearInterval(this.reloadTimer);
        })
        .catch((encodingError) => {
          this.showLoader = true;
          console.log(encodingError);
          console.log(`decode failed, reloading: ${this.imageUrl}`);
        });
    },
  },
  computed: {
    aspectRatio() {
      return this.image.h / this.image.w;
    },
    figureStyles() {
      const out = {};
      if (this.isIntrinsic) out.paddingBottom = `${this.aspectRatio * 100}%`;
      return out;
    },
    imageUrl() {
      if (this.image.url) return this.image.url + '/high.jpg'
      else return imgURLImageoptim(this.image);
    },
  },
});
</script>

<style lang="scss" scoped>
img {
  transition: opacity 200ms;
  opacity: 0;
  pointer-events: none;
  &.image-load-succeeded {
    opacity: 1;
    pointer-events: auto;
  }
}
svg {
  transition: opacity 200ms;
  opacity: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.image-load-succeeded {
    opacity: 1;
    pointer-events: none;
  }
}
.message-image {
  width: 100%;
  margin: 16px 0 0 0;
  height: 0;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .tag {
    position: absolute;
    bottom: 8px;
    left: 9px;
    z-index: 100;
    text-transform: uppercase;
    color: var(--white);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 3px 5.5px 3px 5px;
    font-size: 8px;
    line-height: 10px;
    display: block;
    border-radius: 1px;
    letter-spacing: 0.05em;
    backdrop-filter: blur(4px);
  }
}
</style>

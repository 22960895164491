<template>
  <div :class="['reply-icon', { none: !numComments }]">
    <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 148 148"
      xml:space="preserve"
      width="148"
      height="148"
    >
      <path
        d="m 18,110 h 4 v 22.293 c 0,3.675 1.304,5.707 3.256,5.707 1.021,0 2.218,-0.556 3.499,-1.722 L 57.608,110 H 118 c 4.4,0 8,-3.6 8,-8 V 30 c 0,-4.4 -3.6,-8 -8,-8 H 18 c -4.4,0 -8,3.6 -8,8 v 72 c 0,4.4 3.6,8 8,8 z "
        id="path10"
        inkscape:connector-curvature="0"
      />
    </svg>
    <!-- <span v-if="numComments">{{ numComments }}</span> -->
  </div>
</template>

<script type="ts">
import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      numComments: {
        type: Number,
        default: 0
      }
    }
  })
</script>

<style lang="scss" scoped>
.reply-icon {
  margin: 0px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  span {
    display: block;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 1px);
    transform: translate(-50%, -50%);
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
  }
  svg {
    width: 24px;
    height: auto;
  }
  path {
    fill: transparent;
    stroke: var(--black);
    stroke-width: 10px;
  }
  &.none path {
    display: none;
  }
}
</style>

<template>
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      fill-opacity="0.6"
    >
      <path
        d="M25,0 C38.8071187,0 50,11.1928813 50,25 C50,38.8071187 38.8071187,50 25,50 C11.1928813,50 0,38.8071187 0,25 C0,11.1928813 11.1928813,0 25,0 Z M18.6098655,13.9013453 L18.6098655,36.32287 L37.8923767,25.1121076 L18.6098655,13.9013453 Z"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<template>
  <div class="header-modal">
    <router-link to="/" class="logo">
      <SvgSplayIntroLogo />
    </router-link>
    <div class="header-user">
      <div class="user" :style="{transform: `translateY(${transformAmt}px)`, opacity: userOpacity}"></div>
    </div>
    <landing-search @active="setSearchActive" />
    <a href="https://app.splay.com" target="_blank" class="app-badge">
      <app-store-icon />
    </a>
  </div>
  
</template>

<script>

  import SvgSplayIntroLogo from "@/svg/SvgSplayIntroLogo.vue";
  import IconInstagram from "@/application/components/svg/IconInstagram.vue";
  import IconLinkedin from "@/application/components/svg/IconLinkedin.vue";
  import IconTwitter from "@/application/components/svg/IconTwitter.vue";
  import LandingSearch from "@/application/components/landing/Search.vue"
  import AppStoreIcon from "@/application/components/svg/AppStoreIcon.vue";

  import _throttle from "lodash/throttle"

  export default {
    components: {
      SvgSplayIntroLogo,
      IconInstagram,
      IconLinkedin,
      IconTwitter,
      LandingSearch,
      AppStoreIcon
    },
    data() {
      return {
        transformAmt: this.isMobile ? 253: 300,
        userOpacity: 0
      }
    },
    computed: {
      isMobile() {
        return window.innerWidth < 550
      },
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll
    },
    methods: {
      setSearchActive() {
        this.$emit('active')
      },
      onScroll() {
        if (this.isMobile){
          if (253 - window.scrollY < 0) {
            this.transformAmt = 0
            this.userOpacity = 1
          }
          else {
            this.transformAmt = 253 - window.scrollY
            if (this.transformAmt <= 30) this.userOpacity = 1 - (this.transformAmt / 30)
            else this.userOpacity = 0
          }
        }
        else {
          if (300 - window.scrollY < 0) {
            this.transformAmt = 0
            this.userOpacity = 1
          }
          else {
            this.transformAmt = 300 - window.scrollY
            if (this.transformAmt <= 50) this.userOpacity = 1 - (this.transformAmt / 50)
            else this.userOpacity = 0
          }
        }
      }
    },
  }

</script>

<style lang="scss">

.header-modal {
    width: calc(100% - 40px);
    // max-width: 1600px;
    height: 100px;
    z-index: 2000;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid white;
    .route-post & {
      background-color: transparent;
      border-color: transparent;
      .logo {
        // filter: drop-shadow(2px 2px 4px #000);
        @media (prefers-color-scheme: light) {
          path:not(.slash) {
            fill: #000;
          }
        }
      }
    }
    .not-logged-in & {
      .landing-search {
        display: none;
      }
      .app-badge {
        display: flex;
      }
    }
    .logo {
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
      display: block;
      path:not(.slash) {
        fill: #fff;
        transition: fill 0.2s;
      }
      svg {
        width: 80px;
        height: auto;
        display: block;
      }
    }
    .search-open & {
      .header-user {
        display: none;
      }
    }
    .route-post & {
      .header-user {
        .user {
          transform: unset !important;
          opacity: 1 !important;
        }
      }
    }
    .header-user {
      position: absolute;
      color: #fff;
      top: 0;
      left: 142px;
      font-size: 28px;
      font-weight: 600;
      line-height: 1;
      height: 100px;
      .user {
        padding-top: 38px;
        will-change: transform;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .app-badge {
      position: absolute;
      top: 50%;
      right: 20px;
      display: block;
      cursor: pointer;
      transform: translateY(-50%);
      display: none;
      svg {
        border: 1px solid #fff;
        border-radius: 8px;
        width: 130px;
        height: auto;
      }
    }
    @media only screen and (max-width: 550px) {
      height: 50px;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
      transform: unset;
      border: none;
      .route-post & {
        background-color: rgba(0, 0, 0, 0.8);
        .logo {
          filter: none;
          @media (prefers-color-scheme: light) {
            path:not(.slash) {
              fill: #fff;
            }
          }
        }
      }
      .logo {
        left: 14px;
        svg {
          width: 90px;
        }
      }
      .header-user {
      left: 106px;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      .user {
        padding-top: 18px;
      }
    }
    .app-badge {
      right: 4px;
      svg {
        width: 120px;
        border: none;
      }
    }
    }
  }

</style>
<template>
  <div class="bubble-container" :style="{ height: `${winHeight}px` }">
    <bubble-col 
      v-for="(m, i) in messages" 
      :key="i"
      :num-rows="numRows"
      :num-cols="numCols" 
      :messages="m"
      :scroll-factor="scrollFactors[i]"
      :is-logged-in="isLoggedIn"
      :legal-open="legalOpen"
      @setHover="setHoverDate"
      @clearHover="clearHoverDate"
    />
  </div>
</template>

<script>
 
import v2 from "@/application/api/v2";
import _shuffle from "lodash/shuffle"
import _throttle from "lodash/throttle"
import _random from "lodash/random"
import dayjs from "dayjs"

import BubbleCol from "@/application/components/site/BubbleCol.vue"

export default {
  components: {
    BubbleCol
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    legalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      messages: [],
      winWidth: window.innerWidth,
      winHeight: window.innerHeight
    }
  },
  computed: { 
    displayDate() {
      if (this.hoverDate) return this.hoverDate
      else return this.currentDate
    },
    numRows() {
      return 100
    },
    numCols() {
      return 6
    },
    scrollFactors() {
      const out = []
      const multiple = 1 / this.numCols
      for (let i = 0; i < this.numCols; i++) {
        if (this.isMobile) out.push((i + 2) * multiple)
        else out.push((i + 1) * multiple)
      }
      return _shuffle(out)
    },

    totalNumItems() {
      return this.numRows * this.numCols
    },
    isMobile() {
      return window.innerWidth < 550
    },
  },
  created() {
    v2.get(`/messages/get_landing_images?num=${this.totalNumItems}`).then(res => {
      const messages = res.data.images.reverse()
      const buckets = []
      for (let i = 0; i < this.numCols; i++) buckets.push([])
      for (let i = 0; i < messages.length; i++) buckets[i % buckets.length].push(messages[i])
      this.messages = buckets
    })
  },
  methods: {
    setHoverDate(message) {
      const formatedDate = dayjs(message.date).format("ddd MMM DD, YYYY")
      let string = "<span>" + message.user + "</span> on " + formatedDate
      if (this.isMobile) string = "<span>" + message.user + "</span><br>" + (new Date(message.date)).toDateString()
      this.$emit('display', string)
    },
    setCurrentDate(message) {
      const formatedDate = dayjs(message.date).format("ddd MMM DD, YYYY")
      let string = "<span>" + message.user + "</span> on " + formatedDate
      if (this.isMobile) string = "<span>" + message.user + "</span><br>" + (new Date(message.date)).toDateString()
      this.$emit('display-current', string)
    },
    clearHoverDate() {
      this.hoverDate = false
      this.$emit('display', false)
    },
  }
}

</script>

<style lang="scss">

.bubble-container {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
}

</style>
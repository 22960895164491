<template>
  <header class="new-site-header">
    <!-- <feed-header
      v-if="!isDropdownVisible"
      :show-title="false"
      :icon-size="35"
      :show-nav="false"
      extra-classes="feed-header--in-site-nav"
    ></feed-header>
    <rooms /> -->
    <div class="logo" @click="scrollToTop">
      <logo-svg />
      <logo-svg fill="#561518" class="dark" ref="mask" />
    </div>
    <!-- <header-nav v-on:dropdown-toggled="dropdownToggled" v-if="currentUser"> </header-nav> -->
    <!-- <user-menu /> -->

    <!-- <SvgSplayIntroLogo class="logo" /> -->
    <a class="icon-app" href="https://apps.apple.com/us/app/splay/id882251721" target="_blank">
        <AppStoreIcon />
    </a>

  </header>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
import { mapState } from "vuex";
import logoSvg from "../svgs/Logo.vue";
import UserMenu from "../user-menu/Menu.vue";
import HeaderNav from "./HeaderNav.vue";
import SvgSplayIntroLogo from "@/svg/SvgSplayIntroLogo.vue";
import AppStoreIcon from "@/application/components/svg/AppStoreIcon.vue";

export default defineComponent({
  components: {
    SvgSplayIntroLogo,
    AppStoreIcon,
    logoSvg,
    HeaderNav,
    UserMenu,
    FeedRoomsWidget: defineAsyncComponent({
      loader: () => import("@/application/feeds/components/FeedRoomsWidget.vue"),
    }),
    Rooms: defineAsyncComponent({
      loader: () => import("@/application/bbs20/components/Rooms.vue"),
    }),
    FeedHeader: defineAsyncComponent({
      loader: () => import("@/application/feeds/components/FeedHeader.vue"),
    }),
  },
  mounted() {
    // window.addEventListener("scroll", this.trackScroll);
    window.addEventListener("resize", this.trackScroll);
  },
  computed: {
    ...mapState(["currentUser"]),
  },
  data() {
    return {
      isDropdownVisible: false,
    };
  },
  methods: {
    dropdownToggled(newVisibility) {
      this.isDropdownVisible = newVisibility;
    },
    trackScroll() {
      const body = document.documentElement || document.body;
      const amt = ((body.scrollTop / body.offsetHeight) * 100).toFixed(2);
      this.$refs.mask.$el.style.clipPath = `polygon(0 0, 100% 0, 100% ${amt}%, 0 ${amt}%)`;
      return amt;
    },
    scrollToTop() {
      if (this.$route.name != "home") {
        this.$router.push("/");
      } else window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
});
</script>

<style lang="scss">
.new-site-header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  height: 60px;
  background-color: var(--white);
  z-index: 50000;
  margin: 0;
  @media only screen and (max-width: 600px) {
    left: 0;
    transform: unset;
  }
  .logo {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    svg {
      width: 29px;
      height: 50px;
      transform: scaleX(0.9);
    }
    .dark {
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      will-change: clip-path;
    }
  }

  // .logo {
  //   height: 40px;
  //   width: auto;
  //   position: absolute;
  //   left: 0;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }

  .icon-app {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    display: flex;
    @media (prefers-color-scheme: light) {
      filter: invert(1);
    }
  }

}
</style>

<template>
  <div class="sub-menu rooms menu">
    <section @click="closeRoomMenu">
      <strong>
        <svg
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          class="arrow"
        >
          <path
            d="M21,11v1H5.64l6.72,6.72l-0.71,0.71L3.72,11.5l7.92-7.92l0.71,0.71L5.64,11H21z"
          ></path>
        </svg>
        Rooms</strong
      >
    </section>
    <section class="room-list">
      <router-link to="/" class="room">
        <div class="room-img">
          <SvgSplaySlash class="splay-slash" height="45" />
        </div>
        <div class="name-wrapper">
          <span class="name">Splay</span>
        </div>
      </router-link>
      <div v-for="room in rooms" :key="room.id" class="room">
        <router-link :to="`/channels/${room.slug}`" class="name-wrapper">
          <img :src="getImgUrl(room.image)" class="room-img" />
          <span class="name" v-text="room.name" />
        </router-link>
        <router-link class="edit" :to="`/channels/${room.slug}/edit`"
          >Edit</router-link
        >
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import SvgSplaySlash from "@/svg/SvgSplaySlash.vue";

import { defineComponent } from "vue";

import { imgURLImageoptim } from "../../utils";

export default defineComponent({
  components: {
    SvgSplaySlash
  },
  props: {
    rooms: {
      type: Array,
      required: true
    }
  },
  methods: {
    getImgUrl(image) {
      return imgURLImageoptim(image);
    },
    closeRoomMenu() {
      this.$emit("closeSubMenu");
    }
  }
});
</script>

<style lang="scss">
.menu.rooms {
  .room-list {
    border-top: 1px solid #ccc;
    padding-bottom: 0;
  }
  .router-link-active.room .room-img {
    border: 2px solid red;
    height: 41px;
    width: 41px;
    min-width: 41px;
  }
  .room {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    line-height: 20px;
    .room-img {
      border-radius: 4px;
      background-color: white;
      min-width: 45px;
      width: 45px;
      height: 45px;
      object-fit: cover;
      position: relative;
      overflow: hidden;
      margin-right: 13px;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 47px;
      }
    }
    .name-wrapper {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      .name {
        font-weight: 600;
        font-size: 16px;
      }
      .edit {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="lds-dual-ring"></div>
</template>
<style>
.lds-dual-ring {
  display: inline-block;
  width: 36px;
  height: 36px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 4px;
  border-radius: 50%;
  border: 4px solid #838080;
  border-color: #838080 transparent #838080 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
	<svg version="1.1"
		 id="svg2" xmlns:svg="http://www.w3.org/2000/svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" inkscape:version="0.48.4 r9939" xmlns:cc="http://creativecommons.org/ns#"
		 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="231.042px"
		 height="88.454px" viewBox="0 -0.079 231.042 88.454" enable-background="new 0 -0.079 231.042 88.454" xml:space="preserve">
		<rect x="-352.518" y="-250.478" fill="none" width="1000.001" height="750"/>
		<g id="g2997">
			<g id="g3003" transform="translate(304.8633,363.8892)">
				<path id="path3005" inkscape:connector-curvature="0" fill="#FFFFFF" d="M-276.302-319.228c-1.144-2.545-4.231-5.548-9.771-5.548
					c-4.389-0.067-7.167,1.468-7.167,3.77c0,6.784,24.556,1.001,24.556,15.711c0,9.396-8.376,13.626-17.313,13.626
					c-9.162,0-15.403-3.154-18.865-7.084l7.084-5.465c2.152,3.163,6.232,4.856,11.24,4.856c4.464,0,7.542-2.47,7.542-4.556
					c0-6.609-24.098-1.151-24.098-15.929c0-8.769,7.626-12.624,17.255-12.624c9.311,0,14.235,4.772,16.087,7.075L-276.302-319.228z"/>
			</g>
			<g id="g3007" transform="translate(316.4375,332.603)">
				<path id="path3009" inkscape:connector-curvature="0" fill="#FFFFFF" d="M-273.409-248.834h9.404v-16.245
					c2.453,2.77,5.458,4.697,11.073,4.697c10.02,0,16.946-9.17,16.946-20.401c0-11.239-6.926-20.4-16.788-20.4
					c-4.623,0-8.236,1.619-11.089,4.23h-0.143v-3.312h-9.404V-248.834z M-255.077-269.009c-7.093,0-9.178-6.15-9.244-11.773
					c0.066-5.615,2.151-11.781,9.244-11.781c7.076,0,9.154,6.166,9.229,11.781C-245.923-275.159-248.001-269.009-255.077-269.009"/>
			</g>
			<path id="path3011" inkscape:connector-curvature="0" fill="#FFFFFF" d="M86.692,16.484h9.396v54.819h-9.396V16.484z"/>
			<g id="g3013" transform="translate(393.1812,364.2563)">
				<path id="path3015" inkscape:connector-curvature="0" fill="#FFFFFF" d="M-254.223-320.054c0-11.098-8.394-12.782-16.788-12.782
					c-8.545,0-16.621,3.537-16.621,12.7l9.395,0.382c0-4.237,1.77-5.847,7.226-5.847c4.08,0,7.393,1.075,7.393,4.922v1.159
					c-2.386,0.851-6.775,1.611-10.163,2.311l-3.931,0.776c-5.765,1.144-11.856,4.53-11.856,12.149c0,7.551,5.857,12.249,13.233,12.249
					c4.873,0,9.095-1.535,12.716-4.472c0,1.01,0.309,2.621,0.917,3.555h9.938c-0.534-0.851-1.46-2.545-1.46-6.557V-320.054z
					 M-263.618-306.888c0,4.99-5.624,7.16-10.012,7.16c-3.388,0-6.083-1.936-6.083-4.856c0-3.696,2.92-4.688,6.466-5.391l5.156-1.075
					c1.702-0.317,3.237-0.776,4.472-1.385V-306.888z"/>
			</g>
			<g id="g3017" transform="translate(415.2285,339.8052)">
				<path id="path3019" inkscape:connector-curvature="0" fill="#FFFFFF" d="M-248.711-265.039c-2.168,7.167-5.39,9.461-12.164,9.461
					c-2.312,0-3.931-0.232-5.624-0.532v-8.395c0.925,0.159,1.993,0.3,3.688,0.3c3.004,0,4.097-0.833,5.248-3.304l-14.018-39.958
					h10.088l8.385,28.327h0.167l7.301-28.327h9.705L-248.711-265.039z"/>
			</g>
			<g id="g3021" transform="translate(455.5469,399.6812)">
				<path id="path3023" inkscape:connector-curvature="0" fill="#EB222C" d="M-238.631-399.76h14.126l-36.346,88.454h-14.118
					L-238.631-399.76z"/>
			</g>
		</g>
	</svg>
</template>
<script type="text/javascript">
export default {};
</script>

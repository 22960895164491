<template>
  <div v-if="message" :class="['page-post', {'text-post': isTextPost}, {'no-comments': !message.commentsCount}, {'no-body': !messageBody}]">
    <!-- <Teleport to=".header-user .user">
      <span>@<router-link :to="`/${message.user.name}`">{{ message.user.name }}</router-link></span>
    </Teleport> -->
    <div v-if="!isTextPost" class="img-container" ref="imageContainer">
      <!-- :style="{height: `${imageWrapperDimentions.h}px`, width: `${imageWrapperDimentions.w}px`}" -->
      <div 
        ref="imageWrapper" 
        class="image-wrapper" 
        @swipe-left="nextImg"
        @swipe-right="prevImg"
        :style="{height: `${imageWrapperDimentions.h}px`, width: `${imageWrapperDimentions.w}px`}"
      >
        <img v-if="!isVideoPost" class="main-image" v-for="(image, i) in message.images" :style="{transform: `translateX(${(i - galleryIdx) * 100.2}%)`}" :key="image.url" :src="imgSrc(image)">
        <video
          v-else
          ref="video"
          class="video"
          playsinline
          controls
          :src="streamURL"
          :poster="imgSrc(message.images[0])"
          :id="message.id"
        ></video>
        <div class="btn next" v-show="galleryIdx < message.images.length - 1" @click="nextImg">
          <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4609 10.1719C11.4609 9.87891 11.3438 9.60938 11.1211 9.39844L1.83984 0.304688C1.62891 0.105469 1.37109 0 1.06641 0C0.46875 0 0 0.457031 0 1.06641C0 1.35938 0.117188 1.62891 0.304688 1.82812L8.83594 10.1719L0.304688 18.5156C0.117188 18.7148 0 18.9727 0 19.2773C0 19.8867 0.46875 20.3438 1.06641 20.3438C1.37109 20.3438 1.62891 20.2383 1.83984 20.0273L11.1211 10.9453C11.3438 10.7227 11.4609 10.4648 11.4609 10.1719Z" fill="black"/>
          </svg>
        </div>
        <div class="btn prev" v-show="!!galleryIdx" @click="prevImg">
          <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10.1719C0 10.4648 0.105469 10.7227 0.328125 10.9453L9.62109 20.0273C9.82031 20.2383 10.0781 20.3438 10.3828 20.3438C10.9922 20.3438 11.4609 19.8867 11.4609 19.2773C11.4609 18.9727 11.332 18.7148 11.1445 18.5156L2.61328 10.1719L11.1445 1.82812C11.332 1.62891 11.4609 1.35938 11.4609 1.06641C11.4609 0.457031 10.9922 0 10.3828 0C10.0781 0 9.82031 0.105469 9.62109 0.304688L0.328125 9.39844C0.105469 9.60938 0 9.87891 0 10.1719Z" fill="black"/>
          </svg>
        </div>
        <div class="dots" v-if="message.images.length > 1">
          <div v-for="(d, i) in message.images.length" :class="['dot', {'active': galleryIdx == i}]"></div>
        </div>
      </div>
      <img v-for="(image, i) in message.images" :key="image.url" :class="['bg-image', {'active': i == galleryIdx}]" :src="imgSrc(image)">
    </div>
    <div v-if="message" :class="['post-meta']">
      <div class="top-wrapper">
        <div class="user">
          <img v-if="profilePic" class="profile-pic" :src="profilePic">
          <div v-else class="profile-pic"></div>
          <router-link :to="`/${message.user.name}`" class="username">@{{ message.user.name }}</router-link>
        </div>
        <div class="timestamp">{{ timestamp }}</div>
        <share-button :text='`"@${message.user.name} - ${messageBody}"`' />
      </div>
      <div class="body" v-html="messageBody" />
      <div class="comments-count" v-if="message.commentsCount > 0">{{ message.commentsCount }} Comments</div>
      <message-comments v-if="message.commentsCount > 0" :message-id="message.id" />
    </div>
  </div>
</template>

<script lang="ts">
import { Message } from "@/types";
import { defineComponent } from "vue";
import api from "../api";
import MessageGrid from "../components/grids/MessageGrid.vue";
import AppStoreIcon from "@/application/components/svg/AppStoreIcon.vue";
import { imgURLImageoptim } from "../utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import MessageComments from "@/application/components/site/MessageComments.vue";
import Hls from "hls.js";
import { mapGetters } from "vuex";

import ShareButton from "@/application/components/site/ShareButton.vue";

import { initSwipeEvents } from "@/application/utils"

export default defineComponent({
  components: {
    MessageGrid,
    AppStoreIcon,
    MessageComments,
    ShareButton
  },
  data() {
    return {
      message: false,
      showComments: false,
      galleryIdx: 0,
      canPlay: false,
      winWidth: window.innerWidth,
      winHeight: window.innerHeight
    };
  },
  created() {
    const root = document.querySelector(':root')
    root.style.setProperty('--static-100vh', `${window.innerHeight}px`)
    window.addEventListener('resize', () => {
      if (!this.isMobile) {
        this.winHeight = window.innerHeight
        this.winWidth = window.innerWidth
      }
    })
    api.messages
      .load(this.$route.params.id as string)
      .then((message) => {
        this.message = message
        this.$nextTick(() => {
          if (this.message.type == 'livestreamFrame') this.setUpVid()
          if (this.isMobile) {
            this.$nextTick(() => {
              initSwipeEvents(this.$refs.imageWrapper)
            })
          }
        })
      })

  },
  computed: {
    noPostMeta() {
      return !this.message.commentsCount && !this.messageBody
    },
    messageBody() {
      if (this.message) return Message.toHTML(this.message);
      else return ""
    },
    profilePic() {
      if (this.message && this.message.user.image) {
        return this.message.user.image.url + '/low.jpg'
      }
      else return ""
    },
    timestamp() {
      if (!this.message) return ""
      else {
        return dayjs().to(dayjs(this.message.createdAt));
      }
    },
    isVideoPost() {
      return this.message.type == 'livestreamFrame'
    },
    isTextPost() {
      return this.message.type == 'text'
    },
    firstAspectRatio() {
      if (!this.message || this.isTextPost) return 0
      else {
        let closestImg = 0
        let smallestAspectRatioDifference = 100
        for (let i = 0; i < this.message.images.length; i++) {
          const aspectRatio = this.message.images[i].h / this.message.images[i].w
          const aspectRatioDiff = Math.abs(aspectRatio - this.stageAspectRatio)
          if (aspectRatioDiff < smallestAspectRatioDifference) {
            smallestAspectRatioDifference = aspectRatioDiff
            closestImg = i
          } 
        }
        return this.message.images[closestImg].h / this.message.images[closestImg].w
      }
    },
    stageAspectRatio() {
      const winWidth = this.winWidth - 440
      let winHeight = this.winHeight - 180
      if (this.noPostMeta) winHeight =  this.winHeight - 116
      return winHeight / winWidth
    },
    imageWrapperDimentions() {
      if (this.isMobile) return {h: "", w: ""}
      else if (this.firstAspectRatio < this.stageAspectRatio) {
        const width = this.winWidth - 440
        return {w: width, h: width * this.firstAspectRatio}
      }
      else {
        let height = this.winHeight - 180
        if (this.noPostMeta) height = this.winHeight - 116
        return {h: height, w: height / this.firstAspectRatio}
      }
    },
    ...mapGetters(["videoHost"]),
    streamURL() {
      if (!this.isVideoPost) return ""
      else return `https://${this.videoHost}/${this.message.videoUrl}`;
    },
    isMobile() {
      return window.innerWidth < 550
    }
  },
  methods: {
    toggleComments() {
      this.showComments = !this.showComments
    },
    imgSrc(image) {
      const url = image.url
      const fileType = url.split(".").slice(-1)[0]
      if (this.isMobile) return `${url}/low.${fileType}`
      else return `${url}/high.${fileType}`
    },
    nextImg() {
      if (this.galleryIdx + 1 < this.message.images.length) this.galleryIdx += 1
    },
    prevImg() {
      if (this.galleryIdx > 0) this.galleryIdx -= 1
    },
    setUpVid() {
      const hls = new Hls();
      const stream = `//${this.videoHost}/${this.message.videoUrl}`;

      hls.loadSource(stream);
      hls.attachMedia(this.$refs.video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.canPlay = true;
      });
    },
  }
});
</script>

<style lang="scss">
.page-post {
  &.no-body.no-comments {
    .img-container {
      height: calc(var(--static-100vh) - 76px);
    }
    .post-meta {
      min-height: 36px;
      .top-wrapper {
        margin-bottom: 0;
      }
    }
  }
  &.text-post {
    .post-meta {
      max-width: unset;
      padding-top: 132px;
    } 
  }
  .img-container {
    position: relative;
    height: calc(var(--static-100vh) - 140px);
    background-color: var(--white);
    // padding: 120px 0 120px 0;
    box-sizing: border-box;
    // overflow: hidden;
    .image-wrapper {
      position: absolute;
      top: calc(50% + 20px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // top: 20px;
      // left: 20px;
      // width: calc(100% - 40px);
      // height: calc(100% - 20px);
      z-index: 100;
      filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.1));
      overflow: hidden;
      .btn {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 20px;
          width: 20px;
          path {
            fill: #fff;
          }
        }
        &.next {
          right: 10px;
          svg {
            margin-left: 2px;
          }
        }
        &.prev {
          left: 10px;
          svg {
            margin-right: 2px;
          }
        }
      }
      .dots {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px 6px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 20px;
        width: 60px;
        .dot {
          height: 8px;
          width: 8px;
          background-color: #fff;
          border-radius: 100%;
          opacity: 0.4;
          transition: opacity 0.2s;
          &.active {
            opacity: 1;
          }
        }
      }
    }
    .main-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // height: calc(100% - 40px);
      object-fit: contain;
      will-change: transform;
      transition: transform 0.3s ease-in-out;
      // z-index: 100;
      // filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.1));
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(12px);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      display: none;
      &.active {
        opacity: 0.34;
      }
      @media (prefers-color-scheme: light) {
        // opacity: 0.6;
      }
    }
  }
  .post-meta {
    position: relative;
    // max-width: 1600px;
    margin: 0 auto;
    padding: 20px 60px;
    color: var(--black);
    font-size: 40px;
    min-height: 140px;
    box-sizing: border-box;
    .top-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 6px;
      .user {
        padding: 0px 0 0;
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        .profile-pic {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          background-color: red;
        }
        .username {
          font-size: 24px;
          padding-left: 12px;
          font-weight: 600;
        }
      }
      .timestamp {
        color: #828080;
        font-size: 22px;
      }
    }
    .body {
      line-height: 1;
    }
    .comments-count {
      font-size: 18px;
      margin-top: 4px;
      color: #5B5B5B;
      cursor: pointer;
    }
    .comments {
      // max-width: 600px;
      width: 100%;
      margin-top: 12px;
      .comment {
        border-top-style: dashed;
        .body {
          line-height: 1.2;
        }
      }
    }
  }
  @media only screen and (max-width: 550px) {
    &.no-body.no-comments {
      .img-container {
        height: calc(var(--static-100vh) - 72px);
        .image-wrapper {
          height: calc(var(--static-100vh) - 142px);
        }
      }
      .post-meta {
        min-height: 72px;
        .top-wrapper {
          margin-bottom: 0;
        }
      }
    }
    &.text-post {
      .post-meta {
        padding-top: 64px;
      }
    }
    .img-container {
      padding: 60px 0;
      .image-wrapper {
        top: 60px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(var(--static-100vh) - 200px);
        transform: unset;
        .btn {
          display: none;
        }
        .dots {
          padding: 0px 4px;
          width: 50px;
          .dot {
            height: 7px;
            width: 7px;
          }
        }
      }
    }
    .post-meta {
      font-size: 28px;
      padding: 14px 14px 22px;
      &.no-comments.no-body {
        // height: calc(var(--static-100vh) - 72px);
        // min-height: 72px;
        // .top-wrapper {
        //   margin-bottom: 0;
        // }
      }
      .top-wrapper {
        .timestamp {
          font-size: 16px;
        }
        .user {
          .username {
            font-size: 18px;
          }
        }
      }
      .comments-count {
        font-size: 16px;
      }
      .comments {
        padding: 0;
        .comment {
          .meta {
            .user {
              font-size: 18px;
            }
            .date {
              font-size: 16px;
            }
          }
          .body {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>

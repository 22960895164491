<template>
  <div :ref="`message${message.id}`" :class="['message', {'no-body': !message.body}, {'comments-open': showComments}]">
    <div class="user">
      <router-link
        v-if="message.user"
        :to="`/${encodeURIComponent(message.user.name)}`"
        class="username"
        v-text="message.user.name"
      />
      <!-- <span class="username">{{ message.user.name }}</span> -->
      <!-- <router-link
        :to="`/${encodeURIComponent(message.user.name)}/${message.id}`"
        class="last-active"
        v-text="timeSincePost"
      /> -->
      <span class="last-active">{{ timeSincePost }}</span>
    </div>
    <template v-if="editing && form">
      <message-form :form="form" @cancel="cancelEdit" />
    </template>
    <template v-else>
      <div class="progressbar" v-show="progress != 0">
        <circle-progress
          :percent="progress * 100"
          :size="150"
          fill-color="#e92634"
          empty-color="#f7f7f700"
          :border-width="8"
          :border-bg-width="8"
        />
      </div>
      <message-video v-if="message.videoUrl" :message="message" />
      <message-image
        v-else-if="images.length == 1"
        :image="images[0]"
        :tag="message.tag"
        :is-visible="isVisible"
      />
      <message-gallery
        v-else-if="images.length > 1"
        :images="message.images"
        :is-visible="isVisible"
      />

      <div class="absolute-wrapper">
        <div :class="['message-meta']">
          <message-body :key="message.body" :message="message" />
          <!-- <div class="share-button" ref="shareButton" @click="copyShareLink">Share</div> -->
          <reply-icon
            v-if="hasComments"
            :num-comments="message.commentsCount"
            @click="() => (showComments = !showComments)"
          />
          <share-icon class="share-button" @click.native="copyShareLink" />
        <!--  <message-more :message="message" @editing="onEditing" /> -->
        </div>
        <message-comments
          v-if="showComments"
          :message-id="message.id"
          @newComment="incrementCommentCount"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
// TS stuff
import { defineComponent, PropType } from "vue";
import type { Message, Message as MessageT } from "@/types";
import { Form } from "@/types";

// Components
import MessageImage from "../site/MessageImage.vue";
import MessageVideo from "../site/MessageVideo.vue";
import MessageComments from "../site/MessageComments.vue";
import MessageGallery from "./MessageGallery.vue";
import MessageBody from "../site/MessageBody.vue";
import MessageMore from "./MessageMore.vue";
import ReplyIcon from "@/application/components/svg/ReplyIcon.vue"
import ShareIcon from "@/application/components/svg/ShareIcon.vue"

// Utils
// import * as dayjs from 'dayjs'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import { v1 } from "@/application/api/v2";

//@ts-ignore
import CircleProgress from "vue3-circle-progress";
import MessageForm from "@/application/forms/MessageForm.vue";

export default defineComponent({
  components: {
    MessageImage,
    MessageGallery,
    MessageVideo,
    MessageComments,
    MessageBody,
    CircleProgress,
    MessageMore,
    MessageForm,
    ReplyIcon,
    ShareIcon
  },
  props: {
    message: {
      type: Object as PropType<MessageT>,
      required: true,
    },
  },
  data(): { form?: Form<Message>; isVisible: boolean } {
    return {
      isVisible: false,
      form: undefined,
      showComments: false
    };
  },
  mounted() {

    const options = {
      rootMargin: "0px 0px 300px 0px",
    };

    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let elem = entry.target;

          if (entry.isIntersecting) {
            this.isVisible = true;
          }
        }
      });
    }, options);

    let target = this.$refs[`message${this.message.id}`] as Element;
    observer.observe(target);

    if (this.message.editing) {
      this.onEditing();
    }
  },
  computed: {
    timeSincePost() {
      return dayjs().to(dayjs(this.message.createdAt));
    },
    images() {
      return this.message.images || [];
    },
    progress() {
      return this.message.progress || 0;
    },
    editing() {
      return this.message.editing;
    },
    hasComments() {
      return this.message.commentsCount > 0
    }
  },
  methods: {
    incrementCommentCount() {
      this.message.commentsCount++
    },
    cancelEdit() {
      this.form = undefined;
    },
    onEditing() {
      this.form = new Form(this.message, "message", true);
    },
    copyShareLink() {
      navigator.clipboard.writeText(this.message.publicUrl);
      // this.$refs.shareButton.innerText = 'Link Copied'
      // setTimeout(() => {
      //   this.$refs.shareButton.innerText = 'Share'
      // }, 3000)
    }
  },
});
</script>

<style lang="scss" scoped>
@import "../../variables";

.message {
  line-height: 1;
  display: block;
  position: relative;
  width: 100%;
  background-color: var(--white);
  // margin: 0 0 24px 0;
  // border-bottom: 1px solid #d6d6d6;
  // &.hide-border {
  //   border: none;
  // }
  &.no-body {
    border: none;
    margin: 0;
  }
  .user {
    font-size: 15px;
    font-weight: 700;
    line-height: 12px;
    color: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: var(--white);
    font-family: $font-family;
    padding: 0 12px;
    span {
      display: block;
    }
    .last-active {
      font-weight: 400;
      color: #828080;
      font-size: 14px;
    }
  }
  .message-meta {
    line-height: unset;
    padding: 16px 0px 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    font-family: $font-family;
    position: relative;
    &.no-body {
      .user {
        margin-top: 0;
      }
    }
    &.no-comments {
      
    }
    .share-button {
      padding: 0px 6px;
      cursor: pointer;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .message-photo-form.message-form,
  .message-text-form.message-form {
    padding: 0;
  }
  @media only screen and (max-width: 550px) {
    .message-meta {
      padding: 16px 12px;
    }
  }
}
.progressbar {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

// let log = console.log
// j  = 0
// f = 100 / 31
// for (let i = 0; i <  Math.PI; i += 0.1) {
//   log(`${(f*j).toFixed(2)}% {  transform: translate3d(${j * f}vw, -${ (31 - j/4) + Math.sin(i) * 25}vh, 0) rotate(${(-30+j*2.2).toFixed(2)}deg) }` )
//   j++
// }
/*
t: current time/currnet step
b: starting position
c: amount of change (end - start)
d: total animation time/steps
*/
// t: current time, b: begInnIng value, c: change In value, d: duration
const easing = {
  easeInQuad(x, t, b, c, d) {
    return c * (t /= d) * t + b;
  },
  easeOutQuad(x, t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  },
  easeInOutQuad(x, t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
    return (-c / 2) * (--t * (t - 2) - 1) + b;
  },
  easeInCubic(x, t, b, c, d) {
    return c * (t /= d) * t * t + b;
  },
  easeOutCubic(x, t, b, c, d) {
    return c * ((t = t / d - 1) * t * t + 1) + b;
  },
  easeInOutCubic(x, t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
    return (c / 2) * ((t -= 2) * t * t + 2) + b;
  },
  easeInQuart(x, t, b, c, d) {
    return c * (t /= d) * t * t * t + b;
  },
  easeOutQuart(x, t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  },
  easeInOutQuart(x, t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
    return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
  },
  easeInQuint(x, t, b, c, d) {
    return c * (t /= d) * t * t * t * t + b;
  },
  easeOutQuint(x, t, b, c, d) {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
  },
  easeInOutQuint(x, t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t * t + b;
    return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
  },
  easeInSine(x, t, b, c, d) {
    return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
  },
  easeOutSine(x, t, b, c, d) {
    return c * Math.sin((t / d) * (Math.PI / 2)) + b;
  },
  easeInOutSine(x, t, b, c, d) {
    return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
  },
  easeInExpo(x, t, b, c, d) {
    return t == 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
  },
  easeOutExpo(x, t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  },
  easeInOutExpo(x, t, b, c, d) {
    if (t == 0) return b;
    if (t == d) return b + c;
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  },
  easeInCirc(x, t, b, c, d) {
    return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
  },
  easeOutCirc(x, t, b, c, d) {
    return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
  },
  easeInOutCirc(x, t, b, c, d) {
    if ((t /= d / 2) < 1) return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
    return (c / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
  },
  easeInElastic(x, t, b, c, d) {
    var s = 1.70158;
    var p = 0;
    var a = c;
    if (t == 0) return b;
    if ((t /= d) == 1) return b + c;
    if (!p) p = d * 0.3;
    if (a < Math.abs(c)) {
      a = c;
      var s = p / 4;
    } else var s = (p / (2 * Math.PI)) * Math.asin(c / a);
    return (
      -(
        a *
        Math.pow(2, 10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p)
      ) + b
    );
  },
  easeOutElastic(x, t, b, c, d) {
    var s = 1.70158;
    var p = 0;
    var a = c;
    if (t == 0) return b;
    if ((t /= d) == 1) return b + c;
    if (!p) p = d * 0.3;
    if (a < Math.abs(c)) {
      a = c;
      var s = p / 4;
    } else var s = (p / (2 * Math.PI)) * Math.asin(c / a);
    return (
      a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) +
      c +
      b
    );
  },
  easeInOutElastic(x, t, b, c, d) {
    var s = 1.70158;
    var p = 0;
    var a = c;
    if (t == 0) return b;
    if ((t /= d / 2) == 2) return b + c;
    if (!p) p = d * (0.3 * 1.5);
    if (a < Math.abs(c)) {
      a = c;
      var s = p / 4;
    } else var s = (p / (2 * Math.PI)) * Math.asin(c / a);
    if (t < 1)
      return (
        -0.5 *
          (a *
            Math.pow(2, 10 * (t -= 1)) *
            Math.sin(((t * d - s) * (2 * Math.PI)) / p)) +
        b
      );
    return (
      a *
        Math.pow(2, -10 * (t -= 1)) *
        Math.sin(((t * d - s) * (2 * Math.PI)) / p) *
        0.5 +
      c +
      b
    );
  },
  easeInBack: (x, t, b, c, d) => {
    let s;
    if (s == undefined) s = 1.70158;
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
  },
  easeOutBack: (x, t, b, c, d) => {
    let s;
    if (s == undefined) s = 1.70158;
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
  },
  easeInOutBack: (x, t, b, c, d) => {
    let s;
    if (s == undefined) s = 1.70158;
    if ((t /= d / 2) < 1)
      return (c / 2) * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
    return (c / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
  },
  easeInBounce(x, t, b, c, d) {
    return c - easing.easeOutBounce(x, d - t, 0, c, d) + b;
  },
  easeOutBounce(x, t, b, c, d) {
    if ((t /= d) < 1 / 2.75) {
      return c * (7.5625 * t * t) + b;
    } else if (t < 2 / 2.75) {
      return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
    } else if (t < 2.5 / 2.75) {
      return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
    } else {
      return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
    }
  },
  easeInOutBounce(x, t, b, c, d) {
    if (t < d / 2) return easing.easeInBounce(x, t * 2, 0, c, d) * 0.5 + b;
    return easing.easeOutBounce(x, t * 2 - d, 0, c, d) * 0.5 + c * 0.5 + b;
  },
};

const randEasing = (keys = Object.keys(easing)) => {
  const idx = Math.floor(Math.random() * keys.length);
  const func = keys[idx];
  return easing[func];
};

const randSine = () => {
  return randEasing(["easeInSine", "easeInSine", "easeOutSine"]);
};

function createAnimation(sx, sy, ex, ey) {
  let positions = [];
  const frames = 31 * 2;
  const fx = randEasing(Object.keys(easing).filter((f) => !f.includes("In")));
  const fy = randEasing(Object.keys(easing));
  // t: current time, b: begInnIng value, c: change In value, d: duration
  let x = 0;
  let y = 0;
  const f = 2;
  const rf = Math.random() * 5.5 + 1;
  const sf = Math.random() / 4;
  for (let t = 0; t <= frames; t += 1) {
    x = fx(x, t, sx, ex - sx, frames);
    y = fy(y, t, sy, ey - sy, frames);
    const s = Math.sin(-30 + t * sf);
    const a = (-30 + t * rf).toFixed(2);
    // console.log("a", a);
    positions.push({
      transform: `translate3d(${x}vw, ${-y}vh, 0) scale(${2.5 +
        s}) rotate(${a}deg)`,
    });
  }

  return { positions, frames };
}

// start translate3d(0vw, -31vh, 0) rotate(-30deg);
// mid  translate3d(51.61290322580645vw, -51.98934007603763vh, 0) rotate(3.6deg);
// end  transform: translate3d(100vw, -24.28951656083223vh, 0) rotate(35.1deg);

export const animate = (el) => {
  const sy = Math.random() > 0.5 ? Math.random() * 60 + 20 : Math.random() * 20;

  const ey = sy + Math.random() * 50 + 50;

  const sx = Math.random() < 0.9 ? 0 : 120;
  const ex = sx == 0 ? 100 : -20;

  const { positions, frames } = createAnimation(sx, sy, ex, ey);
  const duration =
    Math.max(1000, Math.min(3000, window.innerWidth * 1.5)) +
    Math.random() * 1000;
  const keyframes = new KeyframeEffect(el, positions, {
    duration,
    fill: "both",
    easing: "linear",
    iterations: 1,
  });

  const animation = new Animation(keyframes);

  animation.play();
  return duration;
};

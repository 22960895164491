
import { TypedEmitter } from "tiny-typed-emitter";
import { ID } from '../feeds/api';


declare interface VideoEvents {
  "screenshot": (p: { file: File, recId: string, videoStartedAt: Date }) => void
}

export class VBus extends TypedEmitter<VideoEvents> {
}


let vbus = new VBus()


function stageName(): string {
  switch (location.hostname) {
    case "www.splay.com":
    case "splay.com":
      return "prod"
    case "staging.splay.com":
      return "staging"
    default:
      return "dev"
  }
}


export function useFeedVideo(_feedId?: ID) {
  return { vbus }
}

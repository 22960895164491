<template>
  <div class="dense-message">
    <router-link class="wrapper" :to="`/${message.user.name}/${message.id}`">
      <img v-if="message.images && message.images.length" :src="getImgSrc(message.images[0])" />
      <span v-else v-html="getMessageBody(message)" />
    </router-link>
    <div :class="['message-meta', { 'open': commentOpen }]">
      <div class="body-wrapper">
        <div class="body" v-text="message.type != 'text' ? message.body : ''" />
        <reply-icon v-if="message.commentsCount > 0" :num-comments="message.commentsCount"
          @click.native.stop="toggleComments" />
      </div>
      <message-comments v-if="message.commentsCount > 0" :message-id="message.id" />
    </div>
  </div>
</template>

<script>

import { imgURLImageoptim } from "../../utils";
import { Message } from "@/types";
import ReplyIcon from "@/application/components/svg/ReplyIcon.vue"
import MessageComments from "../site/MessageComments.vue";

export default {
  components: {
    ReplyIcon,
    MessageComments
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      commentOpen: false
    }
  },
  methods: {
    getImgSrc(image) {
      if (image && image.urls && image.urls.thumbnail) {
        return image.urls.thumbnail;
      }
      return imgURLImageoptim(image);
    },
    getMessageBody(message) {
      const maxLength = window.innerWidth < 550 ? 60 : 175;
      if (message.body.length > maxLength) {
        message.body = message.body.substring(0, maxLength);
        const lastSpaceIdx = message.body.lastIndexOf(" ");
        message.body = message.body.substring(0, lastSpaceIdx);
        message.body += "...";
      }
      return Message.toHTML(message);
    },
    toggleComments() {
      this.commentOpen = !this.commentOpen
    }
  }
}

</script>

<style lang="scss">
.dense-message {
  width: calc(25% - 24px);
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 24px 12px 0;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.15), 0px 2px 5px rgba(255, 255, 255, 0.05), 0px 8px 40px rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  padding-bottom: 48px;

  @media (prefers-color-scheme: light) {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.05), 0px 8px 40px rgba(0, 0, 0, 0.04);
  }

  .wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    display: block;

    .icon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      opacity: 0.6;

      &.image {
        top: 10px;
        right: 10px;
      }
    }

    span {
      line-height: 1.1;
      display: block;
      color: var(--black);
      position: absolute;
      top: 50%;
      font-size: 36px;
      left: 6px;
      right: 2px;
      transform: translateY(-50%);
      word-break: break-word;

      a {
        color: #eb222c;
        display: inline-block;
        position: relative;
        z-index: 100;
      }

      @media only screen and (max-width: 550px) {
        font-size: 18px;
        left: 6px;
        right: 2px;
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .message-meta {
    height: 48px;
    padding: 12px;
    box-sizing: border-box;
    color: var(--black);
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    transform: translateY(calc(100% - 48px));
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &.open {
      transform: translateY(0);
      background-color: rgba(0, 0, 0, 0.9);
      overflow-y: auto;
      // width: calc(100% + 17px);
      @media (prefers-color-scheme: light) {
        background-color: rgba(255, 255, 255, 0.95);
      }

      .body-wrapper {
        .body {
          white-space: unset;

        }
      }
      .comments .comment {
        border-top: 1px solid #3a3a3a;
      }
    }

    .body-wrapper {
      display: flex;
      align-items: flex-start;

      .body {
        width: calc(100% - 34px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .comments {
      padding-top: 12px;

      .comment {
        padding: 6px 0 8px;
        line-height: 1.2;
        border-top: none;

        .meta {
          .user {
            padding: 0 0 4px;
            font-size: 13px;
          }
          .date {
            font-size: 12px;
          }
        }
        .body {
            font-size: 12px;
          }
      }
    }
  }

  @media only screen and (max-width: 550px) {
    width: calc(100% - 12px);
    margin: 12px 6px 16px;

    .body-wrapper {
      .body {
        font-size: 15px;
      }
    }
    .message-meta {
      width: 100%;
      &.open {
        width: 100%;
      }
    }
    .comments {
      padding: 12px 0 0 0;
    }
  }
}
</style>
import * as Sentry from "@sentry/browser";
import "es6-promise/auto";
import { createApp } from "vue";
import VueGtag from "vue-gtag-next";

import SplayNotifications from "./plugins/Notification.js";

// import Vue from "vue";
import Vue3TouchEvents from "vue3-touch-events";
// @ts-ignore
import App from "./App.vue";
import "./lib/axios";
import csfrSetup from "./lib/csfr";
import localStorageService from "./lib/localStorageService";
import router from "./router";
import { key, useStore } from "./store";
import { isLocal } from "./utils";
import "./_transitions.scss";

if (!isLocal()) {
  Sentry.init({
    dsn: "https://37ea02815cb2453b9d327785e29851f1@sentry.io/1833521",
    sendDefaultPii: true
  });
}

const app = createApp(App);

const store = useStore();

// Register a global custom directive called `v-focus`
app.directive("focus", {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus();
  }
});

app
  .use(Vue3TouchEvents)
  .use(VueGtag, { config: { id: "UA-104557-6" } }, router)
  .use(SplayNotifications)
  .use(store, key)
  .use(router);

csfrSetup();

const { stripePublishableKey, applepayMerchantId } = document.body.dataset;

// // current user
const cuEL = document.querySelector("[data-current-user]") as HTMLElement;
//@ts-ignore
const currentUser = cuEL ? { ...(cuEL.dataset as CurrentUser) } : null;
// settings
const seEL = document.querySelector("[data-settings]") as HTMLElement;
const settings = seEL ? { ...seEL.dataset } : {};

// current tag, if exists via direct URL
const currentTagEl = document.querySelector(
  "[data-current-tag]"
) as HTMLElement;
const currentTag = currentTagEl ? currentTagEl.dataset.currentTag : null;

const vhe = document.querySelector("[data-video-host]") as HTMLElement;
const videoHost = vhe ? (vhe.dataset.videoHost as string) : "";

const appEmbed = new URLSearchParams(location.search).get("app-embed") == "1";

store.commit("setSettings", {
  ...settings,
  appEmbed,
  videoHost,
  applepayMerchantId,
  stripePublishableKey
});

if (currentUser) store.commit("setCurrentUser", currentUser);
store.commit("setCurrentMessageTag", currentTag || undefined);

store.dispatch("loadSettings", null);

const urlp = new URLSearchParams(location.search.replace(/^\?/, ""));
let deviceId = urlp.get("deviceId");
if (deviceId) {
  localStorageService.setUserDeviceId(deviceId);
  store.commit("setSettings", { deviceId });
}

store.dispatch("loadSettings", null);

// @ts-ignore:
// Stripe.applePay.checkAvailability((available: boolean) => {
//   store.commit("setApplepay", available);
// });

app.mount("#app");

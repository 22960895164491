import { Message } from "@/types"
import { TypedEmitter } from "tiny-typed-emitter"

declare interface VREvents {
  "videoReactions.pause": (e: {
    video: HTMLVideoElement
    message: Message,
  }) => void
  "videoReactions.play": (e: {
    video: HTMLVideoElement
    message: Message,
  }) => void
}

class VRBus extends TypedEmitter<VREvents> {
}

export default new VRBus()

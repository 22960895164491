<template>
  <svg v-if="shareSupported" @click="share" class="share-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 88 90">
    <g id="Regular-S" transform="translate(0 82)">
      <path class="monochrome-0 hierarchical-0:secondary SFSymbolsPreview4D4D4D" d="M22.2168 5.46875L66.0156 5.46875C74.3652 5.46875 78.4668 1.36719 78.4668-6.83594L78.4668-44.3848C78.4668-52.5879 74.3652-56.6895 66.0156-56.6895L55.5176-56.6895L55.5176-49.6094L65.918-49.6094C69.3848-49.6094 71.4355-47.7051 71.4355-44.043L71.4355-7.17773C71.4355-3.4668 69.3848-1.61133 65.918-1.61133L22.3145-1.61133C18.7988-1.61133 16.7969-3.4668 16.7969-7.17773L16.7969-44.043C16.7969-47.7051 18.7988-49.6094 22.3145-49.6094L32.7148-49.6094L32.7148-56.6895L22.2168-56.6895C13.916-56.6895 9.76562-52.5879 9.76562-44.3848L9.76562-6.83594C9.76562 1.36719 13.916 5.46875 22.2168 5.46875Z"/>
      <path class="monochrome-1 hierarchical-1:primary SFSymbolsPreview212121" d="M44.0918-24.8047C45.9961-24.8047 47.6074-26.3672 47.6074-28.2227L47.6074-66.3086L47.3145-72.168L49.3652-69.9219L54.7363-64.1113C55.3711-63.4277 56.25-63.0859 57.0801-63.0859C58.9355-63.0859 60.3027-64.3555 60.3027-66.1133C60.3027-67.0898 59.9121-67.7734 59.2285-68.4082L46.6309-80.4688C45.752-81.3477 45.0195-81.6406 44.0918-81.6406C43.2129-81.6406 42.4805-81.3477 41.5527-80.4688L28.9551-68.4082C28.3203-67.7734 27.9297-67.0898 27.9297-66.1133C27.9297-64.3555 29.248-63.0859 31.1035-63.0859C31.9336-63.0859 32.8613-63.4277 33.4961-64.1113L38.8672-69.9219L40.918-72.168L40.625-66.3086L40.625-28.2227C40.625-26.3672 42.2363-24.8047 44.0918-24.8047Z"/>
    </g>
  </svg>
</template>

<script>

export default {
  props: {
    text: {
      type: String
    }
  },
  computed: {
    shareSupported() {
      return !!navigator.share
    }
  },
  methods: {
    share() {
      navigator.share({
        title: 'Splay',
        url: window.location.href
      })
    }
  }
}

</script>

<style lang="scss">

.share-button {
  display: block;
  width: 30px;
  height: auto;
  fill: #5B5B5B;
  cursor: pointer;
  padding-left: 10px;
  @media only screen and (max-width: 550px) {
    width: 22px;
    padding-left: 6px;
  }
}

</style>
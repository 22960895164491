<template>
  <footer :class="[{ hidden: isLoading }]">
    <span>
      <router-link to="/terms" class="terms" v-text="'Terms '" />/
      <router-link to="/privacy" class="terms" v-text="' Privacy'" />
      <div>&copy; 2022 Splay, Inc.</div>
    </span>
    <!-- <div>
      <app-store-svg />
    </div> -->
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppStoreSvg from "../svgs/AppStore.vue";

export default defineComponent({
  components: {
    AppStoreSvg
  },
  computed: {
    isLoading() {
      return this.$store.state.loading;
    }
  }
});
</script>

<style lang="scss">
@import "../../variables";

.page-release {
  footer {
    max-width: 1200px;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 24px 0 30px 0;
  align-items: center;
  font-size: 12px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  transition: opacity 100ms;
  color: var(--black);
  background-color: var(--white);
  span {
    opacity: 0.5;
  }
  .log-out {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    padding-right: 12px;
    padding-left: 12px;
  }
}
</style>

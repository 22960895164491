
import { once } from "@/application/lib/once";
import { feedsWS, v1, v2 } from "@/types";
import { feedWS } from "@/types/feeds";
import { bus as wsBus } from "../../store/WsController";
import { addPeer, addRoom, deletePeer, deleteRoom, Room, setPeerCount, updatePeer, updateRoom, useRoomsStore } from "./rooms";


export const wsToRoomStore = once(() => {
  wsBus.on("notify", (m) => {
    if (m.type == "welcome") {
      handleWelcome(m as v1.msg.welcome)
    } else if (m.version == 1 && m.type.startsWith("liveStream")) {
      handleLiveStreamMessages(m as v1.msg.liveStreamMsg)
    } else if (m.version == 2 && m.type.startsWith("room")) {
      handleRoomMessages(m as v2.msg.RoomResMessage)
    }

  });

  wsBus.on("feed.notify", (m) => {
    if (m.version == 2 && m.type.startsWith("room")) {
      handleRoomMessages(m as v2.msg.RoomResMessage)
    } else if (m.version == 1 && m.type.startsWith("liveStream")) {
      handleLiveStreamMessages(m as v1.msg.liveStreamMsg)
    } else if (m.version == 2 && m.type.startsWith("feeds")) {
      handleFeedsMessage(m as feedsWS.msg);
    } else if (m.version == 2 && m.type.startsWith("feed")) {
      handleFeedMessages(m as feedWS.msg)
    }
  });
});

function handleWelcome(msg: v1.msg.welcome) {
  const wMsg = (msg as unknown) as v1.welcomeMsg;
  wMsg.body.liveStreams.forEach(
    (room) => addRoom(Room.createVideoFromWelcome(wMsg, room))
  );
  Object.values(wMsg.body.rooms).forEach(
    (room) => addRoom(Room.createAudioFromWelcome(room))
  );
}

function handleRoomMessages(msg: v2.msg.RoomResMessage) {
  switch (msg.type) {
    case v2.MessageType.roomCreated: {
      addRoom(Room.createAudio(msg as v2.msg.roomCreated));
      break
    }
    case v2.MessageType.roomJoined: {
      addRoom(Room.createAudio(msg as v2.msg.roomJoined));
      break
    }
    case v2.MessageType.roomLeft: {
      const m = msg as v2.msg.roomLeft;
      deletePeer(m.roomId, m.uid);
      setPeerCount(m.roomId, m.peerCount);
      break;
    }
    case v2.MessageType.roomPublished:
      updatePeer(msg.roomId, { uid: msg.uid, published: true });
      break;
    case v2.MessageType.roomUnpublished:
      updatePeer(msg.roomId, { uid: msg.uid, published: false });
      break;
    case v2.MessageType.roomSpeaking:
      updatePeer(msg.roomId, { uid: msg.uid, speaking: true, published: true });
      break;
    case v2.MessageType.roomStopSpeaking:
      updatePeer(msg.roomId, { uid: msg.uid, speaking: false, published: true });
      break;
  }
}

function handleLiveStreamMessages(msg: v1.msg.liveStreamMsg) {
  switch (msg.type) {
    case v1.MessageType.liveStreamCreatedSuccess: {
      addRoom(Room.createFromLiveStreamCreated((msg as unknown) as v1.msg.liveStreamCreated));
      break
    }
    case v1.MessageType.liveStreamCreated: {
      addRoom(Room.createFromLiveStreamCreated((msg as unknown) as v1.msg.liveStreamCreated));
      break;
    }
    case v1.MessageType.liveStreamClosed: {
      deleteRoom(msg.id)
      break
    }
    case v1.MessageType.liveStreamJoined: {
      setPeerCount(msg.id, msg.body.info.count)
      break
    }
    case v1.MessageType.liveStreamPeerJoined: {
      setPeerCount(msg.id, msg.body.count)
      break
    }
    case v1.MessageType.liveStreamLeft: {
      deletePeer(msg.id, msg.uid)
      break
    }
    case v1.MessageType.liveStreamStarted: {
      updateRoom(msg.id, { started: true, recId: msg.body.recId, startedAt: new Date(msg.body.recordingStartedAt || msg.body.created) })
      break
    }
  }
}

function handleFeedsMessage(msg: feedsWS.msg) {
  switch (msg.type) {
    case feedsWS.FeedsTypes.roomCreated: {
      if ((msg as feedsWS.roomCreated).roomType == "feed") {
        addRoom(Room.createFeed(msg as feedsWS.roomCreated));
      } else if ((msg as feedsWS.roomCreated).roomType == "audio") {
        addRoom(Room.createAudio(msg as feedsWS.roomCreated));
      } else if ((msg as feedsWS.roomCreated).roomType == "video") {
        addRoom(Room.createVideo(msg as feedsWS.roomCreated));
      }
      break;
    }

    case feedsWS.FeedsTypes.roomDestroyed: {
      deleteRoom(msg.roomId)
      break
    }
    case feedsWS.FeedsTypes.roomJoined: {
      addPeer(msg.roomId, msg.peer)
      break
    }
    case feedsWS.FeedsTypes.roomLeft: {
      deletePeer(msg.roomId, msg.peer.uid)
      break
    }
  }
}

function handleFeedMessages(msg: feedWS.msg) {
  switch (msg.type) {
    case feedWS.FeedTypes.joined: {
      const { state } = useRoomsStore()
      const room = state.rooms.find((r) => r.id == msg.roomId)
      if (room) {
        if ("peer" in msg) {
          addPeer(room.id, msg.peer)
        } else if ("peers" in msg) {
          msg.peers.forEach((peer) => addPeer(room.id, peer))
        }
      } else {
        addRoom(Room.createFeed(msg));
      }
      setPeerCount(msg.roomId, msg.peerCount)
      break
    }
    case feedWS.FeedTypes.left: {
      deletePeer(msg.roomId, msg.peer.uid)
      setPeerCount(msg.roomId, msg.peerCount)
      break
    }
    case feedWS.FeedTypes.announced: {
      break
    }
  }
}

<template>
  <div class="page-profile">
    <div class="user" v-if="user">
      <Teleport to=".header-user .user">
        <span>@{{ user.name }}</span>
      </Teleport>
      <img class="bg-image" v-if="user.image" :src="getImageUrl(user.image)" />
      <div class="bg-image" v-else></div>
      <div class="info-wrapper">
        <img class="profile-image" v-if="user.image" :src="getImageUrl(user.image)" />
        <div class="profile-image" v-else></div>
        <h4 class="username" v-text="user.name" />
        <div class="bio" v-if="user.bio">{{ user.bio }}</div>
      </div>
    </div>
    <dense-grid v-if="messages.length" :messages="messages" @loadMore="loadMore" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted } from "vue";
import { imgS3Url, stringToHslColor } from "../utils";
import DenseGrid from "../components/grids/DenseGrid.vue";
import { useStore } from "../store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    DenseGrid
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { state, dispatch, commit } = useStore();

    const loadProfile = (page = 1) => {
      return dispatch("users_loadProfile", {
        name: decodeURIComponent(route.params.name as string),
        page
      });
    };

    onMounted(async () => {
      if (!localStorage.getItem('oauth')) {
        router.push('/')
      }  
      const { res } = await loadProfile();
      if (!res) {
        router.push("/");
      }
    });

    onUnmounted(() => {
      commit("userMessages_clear", true);
    });

    return {
      getColor: (name: string) => stringToHslColor(name),
      getImageUrl: (image?: { url: string }) => {
        return image ? imgS3Url(image) : "";
      },
      loadMore: () => {
        if (
          state.userMessages.loading ||
          state.userMessages.pagination.page >=
            state.userMessages.pagination.pages
        ) {
          return;
        }
        loadProfile(state.userMessages.pagination.page + 1);
      },
      user: computed(() => state.user),
      messages: computed(() => state.userMessages.items),
      router,
      route
    };
  }
});
</script>

<style lang="scss" scoped>
.page-profile {
  min-height: 100vh;
  position: relative;
  .user {
    width: 100%;
    margin: 0 auto;
    position: relative;
    width: 100%;
    background-color: var(--white);
    color: var(--black);
    .bg-image {
      width: 100%;
      height: 260px;
      object-fit: cover;
      filter: blur(6px);
      opacity: 0.35;
      background-color: var(--white);
      @media (prefers-color-scheme: light) {
        opacity: 0.9;
      }
    }
    .info-wrapper {
      background-color: transparent;
      width: 100%;
      padding: 0 20px 20px;
      position: relative;
      margin-top: -100px;
      .profile-image {
        width: 160px;
        height: 160px;
        border-radius: 100%;
        background-color: var(--white);
        padding: 10px;
        box-sizing: border-box;
      }
      .username {
        font-size: 64px;
        font-weight: 500;
        color: #fff;
        margin: 0;
        line-height: 64px;
        color: var(--black)
      }
      .bio {
        margin: 12px 0 0;
        display: block;
      }
    }
  }
  .profile-grid {
    margin: 0 0px;
  }
  @media only screen and (max-width: 550px) {
    .user {
      height: 40vh;
      margin-bottom: 20px;
      .bg-image {
        height: 100%;
      }
      .info-wrapper {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 18px;
        margin-top: 0;
        position: absolute;
        bottom: -20px;
        left: 0;
        // transform: translate(-50%, 0);
        .profile-image {
          width: 100px;
          height: 100px;
          padding: 6px;
        }
        .username {
          font-size: 48px;
          margin: 6px 0 0 0;
          line-height: 1;
        }
        .bio {
          margin: 6px 0 0 0;
          line-height: 1;
        }
      }
    }
    .profile-grid {
      margin: 0 4px;
    }
  }
}
</style>

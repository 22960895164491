import type { Image } from "@/types";
import { v2 } from "@/types";
import imageCompression from 'browser-image-compression';
import { ref } from "vue";
import api from "../api";
import { useStore } from "../store";
import { imgURLLow } from "../utils";


export async function presignedUpload(file: File) {


  const ext = file.name.replace(/^.*\./, '');

  const { data } = await api.user.presignedUpload(ext)
  return { file, data }
}

export async function uploadToS3(file: File, data: v2.s3PresignedUpload, progressFunc: (p: number) => void): Promise<{ imageKey: string }> {

  await api.user.uploadToS3(data, file, progressFunc)

  return { imageKey: data.fields.key }
}

export const defaultOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true
}

export async function compressImage(file: File, options = defaultOptions): Promise<File> {
  return await imageCompression(file, options)
}

export async function uploadImage(f: File, progressFunc: (p: number) => void, extractWidthAndHeight = true, options = defaultOptions): Promise<Image> {

  const { file, data } = await presignedUpload(f)
  const compFile = await compressImage(file, options)

  let s3img = await uploadToS3(compFile, data, progressFunc)

  if (extractWidthAndHeight) {
    try {
      const img = await createPreviewFromFile(compFile, 30, 30)
      // const dataUrl = await imageCompression.getDataUrlFromFile(compFile);
      // const imgEl = await imageCompression.loadImage(dataUrl);

      // const preview = await createPreview(compFile, imgEl.width, imgEl.height);
      // // img.preview = preview
      // img.imageWidth = imgEl.width
      // img.imageHeight = imgEl.height
      return { ...img, ...s3img }
    } catch (err) {
      console.error(err)
    }
  }

  const store = useStore()
  let img = { ...s3img, imageWidth: 500, imageHeight: 500, url: "", preview: "", position: 0 }

  //@ts-ignore
  img.url = store.state.settings.imageUrl + img.imageKey
  //@ts-ignore
  img.url = imgURLLow(img, 200)

  return img
}

export const uploadProgress = ref(0)
export const progressFunc = (p: number) => {
  uploadProgress.value = p;
};

export async function createPreviewFromFile(file: File, tw = 30, th = 30): Promise<Image> {
  const dataUrl = await imageCompression.getDataUrlFromFile(file);
  const imgEl = await imageCompression.loadImage(dataUrl);

  const preview = await createPreview(file, imgEl.width, imgEl.height, tw, th);
  return {
    imageKey: '',
    imageWidth: imgEl.width,
    imageHeight: imgEl.height,
    preview,
    url: `${PREVIEW_PREFIX}${preview}`
  }
}

export const PREVIEW_PREFIX = "data:image/jpeg;base64,"

export async function createPreview(file: File, w: number, h: number, tw = 30, th = 30): Promise<string> {

  return new Promise(async (resolve) => {
    const canv = document.createElement("canvas");
    const pw = w > h ? tw : w / h * tw
    const ph = w < h ? th : h / w * th

    canv.width = pw
    canv.height = ph

    const ctx = canv.getContext("2d")
    const img = new Image();
    img.onload = function (e) {
      ctx?.drawImage(img, 0, 0, pw, ph);
      const dataUrl = canv.toDataURL("image/jpeg")

      resolve(dataUrl.slice(PREVIEW_PREFIX.length))
    };
    img.src = await imageCompression.getDataUrlFromFile(file)
  })
}

export async function crop(ifile: File, tw = 100, th = 100): Promise<{ file: File, dataURL: string }> {
  return new Promise(async (resolve, reject) => {
    const img = new Image();
    img.onload = async function () {
      const w = img.width;
      const h = img.height;

      const s = w > h ? h : w
      const cf = await crop1(ifile, s, s)
      const file = await resize(cf, tw, th)
      const dataURL = await imageCompression.getDataUrlFromFile(file)

      resolve({ file, dataURL })
    }

    img.src = await imageCompression.getDataUrlFromFile(ifile)
  })
}

export async function resize(file: File, tw = 100, th = 100, q = 100): Promise<File> {
  return new Promise(async (resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = async function () {

      let canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d"),
        oc = document.createElement('canvas'),
        octx = oc.getContext('2d');

      canvas.width = tw; // destination canvas size
      canvas.height = canvas.width * img.height / img.width;

      let cur = {
        width: Math.floor(img.width * 0.5),
        height: Math.floor(img.height * 0.5)
      }

      oc.width = cur.width;
      oc.height = cur.height;

      octx!.drawImage(img, 0, 0, cur.width, cur.height);

      while (cur.width * 0.5 > tw) {
        cur = {
          width: Math.floor(cur.width * 0.5),
          height: Math.floor(cur.height * 0.5)
        };
        octx!.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
      }

      ctx!.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);


      const f = await imageCompression.canvasToFile(canvas, "image/jpeg", file.name, file.lastModified, q)

      resolve(f)
    }
    img.src = await imageCompression.getDataUrlFromFile(file)
  })
}

async function crop1(file: File, tw = 100, th = 100): Promise<File> {
  return new Promise(async (resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // const reader = new FileReader();
    // reader.onload = function (ev) {
    const img = new Image();
    img.onload = async function () {

      canvas.width = tw
      canvas.height = th
      const sc = scaleImage(img.width, img.height, tw, th, false)
      const w = img.width;
      const h = img.height;

      const s = w > h ? h : w

      ctx!.drawImage(img, 0, 0, w, h, sc.targetleft, sc.targettop, sc.width, sc.height);
      // ctx!.drawImage(img, 0, 0, s, s, 0, 0, tw, th);
      const f = await imageCompression.canvasToFile(canvas, "image/jpeg", file.name, file.lastModified, 92)
      resolve(f)
    }
    img.src = await imageCompression.getDataUrlFromFile(file)
    // }
    // reader.readAsDataURL(file);
  })
}

function scaleImage(srcwidth: number, srcheight: number, targetwidth: number, targetheight: number, fLetterBox: boolean) {

  let result = { width: 0, height: 0, fScaleToTargetWidth: true, targetleft: 0, targettop: 0 };

  if ((srcwidth <= 0) || (srcheight <= 0) || (targetwidth <= 0) || (targetheight <= 0)) {
    return result;
  }

  // scale to the target width
  let scaleX1 = targetwidth;
  let scaleY1 = (srcheight * targetwidth) / srcwidth;

  // scale to the target height
  let scaleX2 = (srcwidth * targetheight) / srcheight;
  let scaleY2 = targetheight;

  // now figure out which one we should use
  let fScaleOnWidth = (scaleX2 > targetwidth);
  if (fScaleOnWidth) {
    fScaleOnWidth = fLetterBox;
  }
  else {
    fScaleOnWidth = !fLetterBox;
  }

  if (fScaleOnWidth) {
    result.width = Math.floor(scaleX1);
    result.height = Math.floor(scaleY1);
    result.fScaleToTargetWidth = true;
  }
  else {
    result.width = Math.floor(scaleX2);
    result.height = Math.floor(scaleY2);
    result.fScaleToTargetWidth = false;
  }
  result.targetleft = Math.floor((targetwidth - result.width) / 2);
  result.targettop = Math.floor((targetheight - result.height) / 2);

  return result;
}





export function timeout<E>(ms: number, err: E) {
  let tid: number
  const now = new Date()

  const p = cancelable<E>((resolve, reject) => {
    tid = window.setTimeout(() => { resolve(err) }, ms)
  })

  const cancel = (err?: E) => {
    // p.reject(err)
    window.clearTimeout(tid)
  }

  return Object.assign(p, { cancel })
}


export function cancelable<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void): PromiseLike<T> & { resolve: (res: T | PromiseLike<T>) => void, reject: (err: any) => void } {
  let resolve = (res: T | PromiseLike<T>) => { }
  let reject = (err: any) => { }

  const p = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve
    reject = _resolve
    executor(resolve, reject)
  })

  return Object.assign(p, { resolve, reject })
}

export default cancelable

<template>
  <div :class="['sub-menu create-room menu', { disabled: submitting }]">
    <section @click="closeRoomMenu">
      <strong>
        <svg
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          class="arrow"
        >
          <path
            d="M21,11v1H5.64l6.72,6.72l-0.71,0.71L3.72,11.5l7.92-7.92l0.71,0.71L5.64,11H21z"
          ></path>
        </svg>
        Create Room</strong
      >
    </section>
    <section class="create-room-form">
      <div :class="['room-avatar', { 'no-image': !roomImageUrl }]">
        <div v-if="!roomImageUrl" class="add">+</div>
        <img v-else :src="roomImageUrl" />
        <input type="file" ref="roomImageInput" @change="processNewImage" />
      </div>
      <div class="input-section">
        <input
          type="text"
          placeholder="Room Name"
          maxlength="36"
          v-model="roomName"
        />
      </div>
      <button
        @click="submitRoomData"
        :class="['save', { disabled: !roomName || !roomImageObj }]"
      >
        {{ saveButtonText }}
      </button>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { imgURLImageoptim } from "../../utils";
import {
  uploadImage,
  uploadProgress,
  progressFunc,
  crop
} from "@/application/use/useImageUpload";
import { Feed, Form, Message } from "@/types";

export default defineComponent({
  data() {
    return {
      saveButtonText: "Create",
      submitting: false,
      roomImageUrl: "",
      roomImageObj: false,
      roomName: ""
    };
  },
  methods: {
    closeRoomMenu() {
      this.$emit("closeSubMenu");
    },
    getImgUrl(image) {
      return imgURLImageoptim(image);
    },
    async processNewImage() {
      if (this.$refs.roomImageInput.value) {
        let iFile = this.$refs.roomImageInput.files?.item(0);
        const { file, dataURL } = await crop(iFile, 300, 300);
        this.roomImageUrl = dataURL;
        this.roomImageObj = file;
      }
    },
    async submitRoomData() {
      if (this.roomImageObj && this.roomName) {
        this.submitting = true;
        this.saveButtonText = "Submitting";
        const feedData = new Feed();
        const form = new Form(feedData, "feed", true);
        const image = await uploadImage(this.roomImageObj, progressFunc);
        feedData.image = image;
        feedData.name = this.roomName;
        const { res, err } = await this.$store.dispatch("submitFeedForm", form);
        if (err) this.saveButtonText = "ERROR";
        else {
          this.$router.push({ name: "feed", params: { slug: res.slug } });
        }
      }
    }
  }
});
</script>

<style lang="scss">
.sub-menu.create-room {
  &.disabled {
    .create-room-form {
      pointer-events: none;
      .save {
        opacity: 0.6;
      }
    }
  }
  .create-room-form {
    border-top: 1px solid #ccc;
    .room-avatar {
      border-radius: 100%;
      width: 90px;
      height: 90px;
      margin: 0 auto;
      display: block;
      overflow: hidden;
      position: relative;
      &.no-image {
        border: 1px solid #ccc;
      }
      .add {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 3px));
        line-height: 0.9;
        font-size: 40px;
        color: #ccc;
      }
      img {
        width: 100%;
        height: 100%;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
    .input-section {
      position: relative;
      padding-top: 16px;
      span {
        display: block;
        padding-bottom: 5px;
      }
      input {
        font-size: 15px;
        padding: 6px 8px;
        border-radius: 2px;
        border: none;
        width: calc(100% - 18px);
        border: 1px solid #ccc;
        &:focus {
          outline-color: #313131;
          outline-width: 1px;
        }
      }
    }
    .save {
      position: relative;
      width: 100%;
      background-color: red;
      border: none;
      color: white;
      font-size: 15px;
      padding: 8px;
      margin-top: 16px;
      font-weight: 600;
      border-radius: 2px;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}
</style>

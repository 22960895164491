<template>
  <div class="name-container">
    <router-link 
      v-for="(user, i) in users" 
      :ref="`name-${i}`" 
      :key="`${user.user}-${i}`" 
      :class="['username', {'active': i == idxOffset}]" 
      :to="`/${user.user}`"
    >
      <img :src="user.image">
      <span>{{ user.user }}</span>
    </router-link>
  </div>
</template>

<script>

import v2 from "@/application/api/v2";

export default {
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      users: [],
      nameHeight: window.innerWidth < 550 ? 57 : 144,
      idxOffset: -1,
      paddingTop: window.innerHeight / 2,
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 550
    }
  },
  watch: {
    async mode() {
      await this.getUsers()
      this.$el.scrollTop = 0
    }
  },
  async mounted() {
    await this.getUsers()
    if (this.isMobile){
      this.$el.addEventListener('scroll', this.onScroll)
      this.onScroll()
    }
  },
  methods: {
    async getUsers() {
      let url = "/users/get_user_by_post_count"
      if (this.mode == 'user-recent') url = "/users/get_user_by_recent_post"
      const res = await v2.get(url)
      this.users = res.data.users
    },
    onScroll() {
      const scrollPos = this.$el.scrollTop
      const winHeight = window.innerHeight
      const numNamesInTopHalf = ((winHeight / 2) - this.nameHeight / 2) / this.nameHeight
      const paddingIdxOffset = this.paddingTop / this.nameHeight
      this.idxOffset = Math.round((scrollPos / this.nameHeight) + numNamesInTopHalf - paddingIdxOffset)
    }
  }
}

</script>w

<style lang="scss">
.name-container {
  margin: 0 0 0 20px;
  position: relative;
  padding-top: 100px;
  z-index: 0;
  max-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  // scroll-snap-type: y mandatory;

  .username {
    display: block;
    font-size: 120px;
    font-weight: bold;
    color: #fff;
    line-height: 1.2;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    // scroll-snap-align: start;

    &.active {
      span {
        color: red;
      }
      img {
        opacity: 1;
      }
    }

    img {
      width: 600px;
      height: 600px;
      top: 50%;
      left: 50%;
      position: fixed;
      z-index: -1;
      transform: translate(-50%, calc(-50% + 30px));
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.1s;
      pointer-events: none;
    }

    span {
      display: block;
      position: relative;
      z-index: 100;
      color: var(--black);
      // mix-blend-mode: difference;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    margin-left: 4px;
    padding-top: calc(100vh / 2);
    // margin-top: calc(100vh / 2);
    .username {
      font-size: 48px;
      line-height: 57px;
      img {
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
import { loadCurrentUser } from "@/application/api/user";
import { useStore } from "@/application/store";
import { computed } from "vue";
import { useWebSocketController } from "../use/useWebSocket";
import { useRoomsStore } from "./store/rooms";
import { feedsGetRooms, feedsSubscribe } from "./store/ws";

const { state, dispatch } = useStore()



export const feed = computed(() => state.feed)

export async function loadFeed(slug: string) {
  if (state.feed?.slug == slug) {
    return Promise.resolve(state.feed!)
  }
  await dispatch("feedMessages_loadFeed", slug)
  return state.feed!
}


export async function useFeedsSubscribe() {
  try {
    await loadCurrentUser()
    const { res: token, err } = await dispatch("feeds_loadFeedsToken", true)
    const c = await useWebSocketController()
    {
      const { res, err } = await dispatch("feeds_list", 1)
      feedsSubscribe(token!)
    }
    useRooms()

  } catch (err) {

  }
}

export async function useRooms() {
  const { res, err } = await dispatch("feeds_loadFeedsToken", true)
  const c = await useWebSocketController()
  const { addRooms } = useRoomsStore()
  feedsGetRooms(res!)
}

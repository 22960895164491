<template>
  <div class="comments">
    <div class="comment" v-for="c in comments" :key="c.id">
      <div class="meta">
        <!-- <router-link
          class="user"
          :to="{
            name: 'profile',
            params: { name: encodeURIComponent(c.user.name) }
          }"
          v-text="c.user.name"
        /> -->
        <router-link :to="`/${c.user.name}`" class="user">{{ c.user.name }}</router-link>
        <span class="date" v-text="getCommentTime(c.createdAt)" />
      </div>
      <div class="body" v-html="parseComment(c)" @click="navToUser" />
    </div>
    <!-- <div class="comment new">
      <div
        contenteditable
        :class="['comment-input', { 'has-content': commentContent }]"
        ref="commentInput"
        placeholder="Write a comment..."
        @keyup.enter="postComment"
        @keydown="arrowListeners"
        @keyup="checkMentions"
        @focus="() => (showPlaceholder = false)"
        @blur="() => (showPlaceholder = !commentContent)"
      ></div>
      <span class="placeholder" v-if="showPlaceholder">Write a comment...</span>
      <button type="button" v-if="commentContent.trim()" @click="postComment">
        Post comment
      </button>
    </div> -->
    <!-- <div
      class="mention-suggestions"
      ref="suggestions"
      v-if="mentionSuggestions.length"
      :style="{ left: `${suggestionLeftOffset}px` }"
      @mouseleave="setActiveMention(-1)"
    >
      <div
        :class="['user', { active: activeMentionIdx == i }]"
        v-for="(s, i) in mentionSuggestions"
        :key="s.id"
        v-text="s.name"
        @mousedown="e => e.preventDefault()"
        @mouseenter="setActiveMention(i)"
        @click="setMention(i)"
      />
    </div>
    <div
      class="comment comment-mirror"
      ref="commentMirror"
      v-html="commentContent"
    /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import { v1 } from "@/application/api/v2";
import api from "@/application/api";

export default defineComponent({
  props: {
    messageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      commentContent: "",
      comments: [],
      mentions: [],
      activeMention: false,
      mentionSuggestions: [],
      suggestionLeftOffset: 0,
      showPlaceholder: true,
      caret: false,
      activeMentionIdx: -1
    };
  },
  watch: {
    commentContent(newContent) {
      if (this.activeMention) {
        api.user
          .search(newContent.substring(this.activeMention.start + 1))
          .then(res => {
            this.mentionSuggestions = res;
            this.activeMentionIdx = 0;
          });
      }
    }
  },
  created() {
    this.fetchComments();
  },
  methods: {
    navToUser(e) {
      // if (e.target.classList.contains("mention")) {
      //   const path = e.target.getAttribute("to");
      //   this.$router.push(path);
      // }
      return
    },
    parseComment(comment) {
      let out = comment.body;
      const mentions = comment.mentions.slice().reverse();
      mentions.map((mention, i) => {
        const mentionText = out.substring(
          mention.start,
          mention.start + mention.length
        );
        out = out.replace(
          mentionText,
          `<a class="mention" href="/${encodeURIComponent(
            mentionText.substring(1)
          )}">${mentionText}</a>`
        );
      });
      return out;
    },
    setMention(mentionIdx) {
      this.$refs.commentInput.innerHTML =
        this.commentContent.substring(0, this.activeMention.start) +
        `<span class="mention" data-user="${this.mentionSuggestions[mentionIdx].id}" contenteditable>@${this.mentionSuggestions[mentionIdx].name}</span>&nbsp;`;
      this.commentContent =
        this.commentContent.substring(0, this.activeMention.start) +
        `@${this.mentionSuggestions[mentionIdx].name} `;
      this.mentionSuggestions = [];
      this.activeMentionIdx = -1;
      this.suggestionLeftOffset = 0;
      this.activeMention = false;
      this.setCaretPosition(
        this.$refs.commentInput,
        this.$refs.commentInput.innerText.length
      );
      let spans = this.$refs.commentInput.querySelectorAll(".mention");
      for (var i = 0; i < spans.length; i++) {
        spans[i].setAttribute("contenteditable", "false");
      }
    },
    setCaretPosition(el, pos) {
      // Loop through all child nodes
      for (var node of el.childNodes) {
        if (node.nodeType == 3) {
          // we have a text node
          if (node.length >= pos) {
            // finally add our range
            var range = document.createRange(),
              sel = window.getSelection();
            range.setStart(node, pos);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return -1; // we are done
          } else {
            pos -= node.length;
          }
        } else {
          pos = this.setCaretPosition(node, pos);
          if (pos == -1) {
            return -1; // no need to finish the for loop
          }
        }
      }
      return pos; // needed because of recursion stuff
    },
    arrowListeners(e) {
      if (
        e.key == "ArrowDown" &&
        this.activeMentionIdx + 1 <= this.mentionSuggestions.length - 1
      ) {
        e.preventDefault();
        this.activeMentionIdx++;
      } else if (
        e.key == "ArrowUp" &&
        this.activeMentionIdx > 0 &&
        this.mentionSuggestions.length
      ) {
        e.preventDefault();
        this.activeMentionIdx--;
      } else if (e.key == "Tab") {
        e.preventDefault();
        if (this.mentionSuggestions.length)
          this.setMention(this.activeMentionIdx);
      }
    },
    checkMentions(e) {
      this.commentContent = this.$refs.commentInput.innerHTML;
      if (e.keyCode == 50 && !this.activeMention) {
        this.activeMention = {
          start: this.commentContent.length - 1
        };
        this.suggestionLeftOffset = this.$refs.commentMirror.clientWidth;
      } else if (this.activeMention) {
        if (
          this.commentContent.length - 1 < this.activeMention.start ||
          e.keyCode == 32
        ) {
          this.activeMention = false;
          this.suggestionLeftOffset = 0;
          this.mentionSuggestions = [];
        } else
          this.activeMention.text = this.commentContent.substring(
            this.activeMention.start
          );
      }
    },
    fetchComments() {
      v1.get(`/messages/${this.messageId}/comments.json`).then(res => {
        if (res.data.comments.length) this.comments = res.data.comments;
        this.showComments = true;
      });
    },
    getCommentTime(time) {
      return dayjs().to(dayjs(time));
    },
    postComment() {
      const mentionsEls = this.$refs.commentInput.querySelectorAll("span");
      const mentions = [];
      const commentText = this.$refs.commentInput.innerText;
      for (var i = 0; i < mentionsEls.length; i++) {
        const mention = mentionsEls[i];
        mentions.push({
          kind: "user",
          length: mention.innerText.length,
          start: commentText.indexOf(mention.innerText),
          text: mention.innerText,
          user_id: parseInt(mention.dataset.user)
        });
      }

      v1.post(`/messages/${this.messageId}/comments.json`, {
        comment: {
          body: commentText,
          mentions: mentions
        }
      }).then(res => {
        this.commentContent = "";
        this.$refs.commentInput.innerHTML = "";
        this.$refs.commentInput.blur();
        this.comments.push(res.data.comment);
        this.$emit("newComment");
        this.showPlaceholder = true;
      });
    },
    setActiveMention(mentionIdx) {
      this.activeMentionIdx = mentionIdx;
    }
  }
});
</script>

<style lang="scss">
.comments {
  color: var(--black);
  // opacity: 0.8;
  font-size: 12px;
  .comment {
    border-top: 1px solid #3a3a3a;
    padding: 12px 0;
    // margin-left: 12px;
    box-sizing: border-box;
    position: relative;
    @media (prefers-color-scheme: light) {
      border-top: 1px solid #eee;
    }
    &.new {
      display: flex;
      align-items: center;
    }
    &.comment-mirror {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      padding: 0 0 0 24px;
      margin: 0;
      font-size: 14px;
    }
    .meta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #828080;
        font-size: 22px;
        pointer-events: none;
      }
    }
    .user {
      padding-left: 0;
      // padding-bottom: 8px;
      font-size: 24px;
      font-weight: 600;
    }
    .body {
      font-size: 22px;
      padding-top: 4px;
      .mention {
        color: red;
      }
    }
    button {
      background: none;
      border: none;
      color: var(--black);
      font-size: 14px;
      padding: 0;
      cursor: pointer;
      white-space: nowrap;
    }
    .placeholder {
      pointer-events: none;
      opacity: 0.6;
      position: absolute;
      top: 13px;
      left: 12px;
    }
    .comment-input {
      background-color: transparent;
      color: var(--black);
      border: none;
      font-size: 14px;
      padding: 0;
      width: 100%;
      position: relative;
      &.has-content {
        background-color: var(--white);
        z-index: 100;
      }
      span {
        pointer-events: none;
        color: red;
      }
      &:focus {
        outline: none;
      }
      ::placeholder {
        color: #828080;
      }
    }
  }
  span {
    cursor: pointer;
    &:hover {
      // text-decoration: underline;
    }
  }
  .mention-suggestions {
    position: absolute;
    bottom: 6px;
    left: 0;
    transform: translateY(100%);
    background-color: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
    z-index: 200;
    font-size: 14px;
    line-height: 1.6;
    padding: 6px 8px;
    border-radius: 4px;
    .user {
      cursor: pointer;
      &.active {
        color: red;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    padding: 0 12px;
  }
}
</style>

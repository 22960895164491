<template>
  <div class="message-body" v-html="body" />
</template>
<script lang="ts">
import { Message } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  setup(props) {
    const { message } = props;

    return {
      body: Message.toHTML(message),
    };
  },
});
</script>
<style lang="scss" scoped>
.message-body {
  padding: 0.75rem;
  border: 1px solid #d4d4d4;
  border-radius: 0.25rem;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 300;
  min-height: 44px;

  :deep(.mention),
  :deep(a) {
    color: var(--red, red) !important;
    padding: 0;
    box-decoration-break: clone;
  }
}
</style>

import { FeedJSON, ID } from '@/application/feeds/api';
import { Message } from '@/types/Message';
import { UserWithPic } from '@/types/UserWithPic';
import { ulid } from 'ulid';
import * as yup from 'yup';
import type { Image } from "./Image";
import { PaginatedList } from './PaginatedList';

export class Feed {
  id!: ID
  slug!: string
  name!: string
  imageFile?: File
  image?: Image
  creatorId!: number
  creator!: UserWithPic
  usersCount: number = 0
  messagesCount: number = 0
  updatedAt!: Date
  editing = false
  messages = new PaginatedList<Message>()

  constructor(id: undefined | number = undefined) {
    this.id = id != undefined ? id : ulid()
    this.updatedAt = new Date()
  }

  static initialize(fj: FeedJSON, creator: UserWithPic): Feed {
    let f = new Feed(fj.id)
    f.slug = fj.slug
    f.name = fj.name
    f.image = fj.image
    f.creatorId = fj.creatorId
    f.creator = UserWithPic.initialize(creator)
    f.usersCount = fj.usersCount
    f.messagesCount = fj.messagesCount
    return f
  }

  static schema() {
    return yup.object().shape({
      name: yup.string().required().max(36)
    })
  }
}

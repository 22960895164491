<template>
  <div 
    :class="['landing-search', {'active': elActive}]" 
    @keyup.up.capture="arrowUp" 
    @keyup.down.capture="arrowDown" 
    @keyup.enter.capture="goToUser" 
    @blur.capture="setActive(false)"
    tabindex="0"
  >
    <search-icon @click="setActive(true)" />
    <div class="search-wrapper">
      <input 
        type="text" 
        placeholder="Search" 
        v-model="query" 
        @focus="setActive(true)" 
        ref="input"
      >
      <div class="results" v-if="searchResults.length">
        <router-link 
          v-for="(r, i) in searchResults" 
          :tabindex="0" 
          :to="`/${r.name}`"
          :class="[{'focus': focusIdx == i}]"
          @mouseenter.native="setFocus(i)"
          @mouseleave.native="setFocus(-1)"
          @click="setActive(false)"
          v-html="r.displayName"
        />
      </div>
    </div>
  </div>
</template>

<script>

import SearchIcon from "@/application/components/svg/SearchIcon.vue"

import _throttle from "lodash/throttle"

export default {
  components: {
    SearchIcon
  },
  data() {
    return {
      query: "",
      searchResults: [],
      queryTimeout: false,
      focusIdx: -1,
      elActive: false
    }
  },
  watch: {
    query() {
      this.performSearch()
    },
    elActive(isActive) {
      if (isActive) document.body.classList.add('search-open')
      else document.body.classList.remove('search-open')
    },
    href() {
      this.elActive = false
      this.$refs.input.blur()
    }
  },
  computed: {
    href() {
      return this.$route.path
    },
  },
  methods: {
    performSearch() {
      if (this.queryTimeout) clearTimeout(this.queryTimeout)
      this.queryTimeout = setTimeout(() => {
        const query = this.query
        fetch(`/api/v3/users/search?q=${this.query}`).then((res) => res.json()).then(res => {
          if (this.query == query) {
            this.searchResults = res.slice(0, 50)
            for (let i = 0; i < this.searchResults.length; i++) {
              let name = this.searchResults[i].name
              const subStringIdx = name.toLowerCase().indexOf(query.toLowerCase())
              name = name.slice(0, subStringIdx) + '<span>' + name.slice(subStringIdx, subStringIdx + query.length) + '</span>' + name.slice(subStringIdx + query.length)
              this.searchResults[i].displayName = name
            }
          }
        })
      }, 100)
    },
    setFocus(idx) {
      this.focusIdx = idx
    },
    arrowUp() {
      if (this.searchResults.length && this.focusIdx) this.focusIdx--
    },
    arrowDown() {
      if (this.searchResults.length && this.focusIdx + 1 < this.searchResults.length) this.focusIdx++ 
    },
    goToUser() {
      if (this.searchResults.length && this.focusIdx > -1) this.$router.push(`/${this.searchResults[this.focusIdx].name}`)
    },
    setActive(isActive) {
      if (!this.elActive && isActive) this.$refs.input.focus()
      if (!this.isActive) {
        if (this.focusIdx == -1) this.elActive = isActive
      }
      else this.elActive = isActive
    }
  }
}

</script>

<style lang="scss">
.landing-search {
  position: absolute;
  top: 33px;
  right: 30px;
  cursor: pointer;
  height: 35px;
  .route-post & {
    svg {
      // filter: drop-shadow(2px 2px 2px #000);
      @media (prefers-color-scheme: light) {
        stroke: #000;
      }
    }
  }
  &.active {
    .search-wrapper {
      background-color: #000;
      width: 360px;
      border-color: #ccc;
      pointer-events: auto;
    }
    input{
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }
    .results {
      opacity: 1;
    }
  }
  .search-wrapper {
    border-radius: 16px;
    padding: 4px 6px;
    width: 30px;
    background-color: transparent;
    border: 1px solid transparent;
    pointer-events: none;
    transition: width 0.3s, border-color 0.3s, background-color 0.3s;
  }
  svg {
    stroke: #fff;
    width: 22px;
    height: auto;
    position: absolute;
    top: 17px;
    right: 10px;
    transform: translateY(-50%);
    transition: stroke 0.2s;
  }
  input {
    background-color: transparent;
    border: none;
    color: transparent;
    font-size: 18px;
    padding: 0 0 0 8px;
    transition: color 0.3s;
    &::placeholder {
      // color: #fff;
      color: transparent;
      transition: color 0.3s;
    }

    &:focus {
      outline: none;
    }
  }
  .results {
    border-top: 1px solid #fff;
    color: #fff;
    width: 100%;
    // padding-left: 40px;
    padding-top: 4px;
    margin-top: 3px;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    max-height: 70vh;
    overflow-y: auto;
    transition: opacity 0.3s;
    a {
      display: block;
      cursor: pointer;
      padding-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        background-color: #9c9c00;
      }
      &.focus {
        background-color: #353535;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    top: 7px;
    right: 14px;
    .route-post & {
      svg {
        filter: none;
        @media (prefers-color-scheme: light) {
          stroke: #fff;
        }
      }
    }
    &.active {
      .search-wrapper {
        width: 224px;
      }
    }
    input {
      font-size: 16px;
      // padding: 0 0 0 26px;
      box-sizing: border-box;
    }
    svg {
      width: 20px;
      top: 18px;
      right: 5px;
      padding: 10px;
    }
    .results {
      height: 40vh;
    }
  }
}
</style>
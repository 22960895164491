<template>
  <div class="user-menu">
    <img class="avatar" @click="toggleMenu" :src="userImage" />
    <div v-show="menuOpen" class="menu">
      <section class="user-section">
        <div class="user-info">
          <span class="username">{{ currentUser.name }}</span>
          <div class="link" @click="openUserEditMenu">Edit Profile</div>
        </div>
        <img class="menu-avatar" :src="userImage" />
      </section>
      <section class="rooms-section" v-if="hasFeedsFeature">
        <div class="view-rooms link" @click="openRoomMenu">View Rooms</div>
        <div class="link" @click="openCreateRoomMenu">Create Room</div>
        <router-link to="/channels/invites">View Invites</router-link>
      </section>
      <section class="bottom-content">
        <div class="log-out link" @click="logOut">Log out</div>
      </section>
    </div>

    <rooms-list v-if="roomMenuOpen" :rooms="rooms" @closeSubMenu="closeSubMenu" />

    <user-edit-menu
      v-if="userEditMenuOpen"
      :current-user="currentUser"
      @closeSubMenu="closeSubMenu"
    />

    <create-room-menu v-if="createRoomMenuOpen" @closeSubMenu="closeSubMenu" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import SvgSplaySlash from "@/svg/SvgSplaySlash.vue";
// Utils
import { imgURLImageoptim } from "../../utils";

// components
import RoomsList from "./RoomsList.vue";
import UserEditMenu from "./UserEditMenu.vue";
import CreateRoomMenu from "./CreateRoomMenu.vue";

export default defineComponent({
  components: {
    SvgSplaySlash,
    RoomsList,
    UserEditMenu,
    CreateRoomMenu,
  },
  data() {
    return {
      menuOpen: false,
      roomMenuOpen: false,
      userEditMenuOpen: false,
      createRoomMenuOpen: false,
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    userImage() {
      return imgURLImageoptim(this.currentUser.image);
    },
    rooms() {
      return this.$store.state.feeds.items;
    },
    hasFeedsFeature() {
      return this.$store.state.settings.features && this.$store.state.settings.features.feeds;
    },
  },
  mounted() {
    this.setEventListeners();
  },
  methods: {
    setEventListeners() {
      document.addEventListener("click", () => {
        this.menuOpen = false;
        this.roomMenuOpen = false;
        this.userEditMenuOpen = false;
        this.createRoomMenuOpen = false;
      });
      this.$el.addEventListener("click", (e) => e.stopPropagation());
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    logOut() {
      window.localStorage.clear();
      window.location.reload();
    },
    openRoomMenu() {
      this.roomMenuOpen = true;
      this.menuOpen = false;
    },
    closeSubMenu() {
      this.roomMenuOpen = false;
      this.userEditMenuOpen = false;
      this.createRoomMenuOpen = false;
      this.menuOpen = true;
    },
    openUserEditMenu() {
      this.userEditMenuOpen = true;
      this.menuOpen = false;
    },
    openCreateRoomMenu() {
      this.createRoomMenuOpen = true;
      this.menuOpen = false;
    },
    getImgUrl(image) {
      return imgURLImageoptim(image);
    },
  },
  watch: {
    "$route.path"(newPath) {
      this.menuOpen = false;
      this.roomMenuOpen = false;
      this.userEditMenuOpen = false;
      this.createRoomMenuOpen = false;
    },
  },
});
</script>

<style lang="scss">
@import "../../variables";

.user-menu {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  .avatar {
    border-radius: 100%;
    height: 32px;
    width: 32px;
    cursor: pointer;
    padding: 5px;
  }
  .menu {
    position: absolute;
    // bottom: -8px;
    bottom: 40px;
    right: 0;
    transform: translateY(100%);
    width: 250px;
    box-sizing: border-box;
    background-color: var(--white);
    border: 1px solid #ccc;
    color: var(--black);
    font-size: 15px;
    border-radius: 8px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.2);
    @media (prefers-color-scheme: dark) {
      box-shadow: 2px 4px 12px rgba(204, 204, 204, 0.2);
    }
    .link,
    a {
      cursor: pointer;
      display: block;
      @media #{$has-hover} {
        &:hover {
          color: red;
        }
      }
    }
    section {
      padding: 16px;
      strong {
        font-size: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media #{$has-hover} {
          &:hover svg {
            fill: red;
          }
        }
      }
    }
    .user-section {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding-bottom: 16px;
      line-height: 22px;
      justify-content: space-between;
      .menu-avatar {
        border-radius: 100%;
        width: 42px;
        height: 42px;
        display: inline-block;
      }
      .username {
        padding-right: 16px;
        font-weight: 600;
        display: block;
        font-size: 17px;
      }
      a {
        padding-right: 16px;
      }
    }
    .rooms-section {
      border-bottom: 1px solid #ccc;
    }
    .bottom-content {
      padding: 8px 16px;
    }
  }
  .sub-menu {
    .arrow {
      fill: var(--black);
      height: 24px;
      width: 24px;
      pointer-events: none;
      display: block;
      padding-right: 13px;
    }
    .router-link-active {
      color: red;
    }
  }
}
</style>

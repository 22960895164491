<template>
  <div
    :class="['message-photo-form message-form', { focused: isFocused }]"
    v-if="visible"
  >
    <form @submit.prevent="onSubmit">
      <section>
        <div v-if="otherErr" class="error">
          {{ otherErr }}
        </div>
        <section
          v-if="imgHeight > 0"
          class="images"
          :style="{ height: `${imgHeight * 1.1}px` }"
        >
          <template v-for="img in message.editableImages" :key="img.id || img">
            <form-image
              v-if="img"
              :editableImage="img"
              v-on:editableImage="onEditableImage"
              v-on:fileDeleted="fileDeleted"
            />
            <div v-else class="placeholder" />
          </template>
        </section>
        <div :class="['input', { 'hide-buttons': !isNew }]">
          <post-buttons
            :class="[{ 'hide-buttons': !isNew }]"
            :showButtons="isNew"
          >
            <div ontouchstart="" class="btn img">
              <post-image-button @files="onFiles" />
            </div>
          </post-buttons>
          <splay-text-area
            :message="message"
            @message-changed="bodyChanged"
            @enter="onEnter"
            @focus="setFocus(true)"
            @blur="setFocus(false)"
            ref="splayText"
          />
          <button class="btn save" :disabled="disabled" @click="save">
            {{ isNew ? "Post" : "Update" }}
          </button>
        </div>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Form, Message, Image, MessageType } from "@/types";
import sortBy from "lodash";
import { useStore } from "@/application/store";
import {
  endText,
  imagePosted,
  imagePostedSuccess,
  imageProgress,
  imageSelected,
  startMessage,
  typing
} from "@/application/store/ws";
import { uploadImage, uploadProgress } from "@/application/use/useImageUpload";
// import imageCompression from "browser-image-compression";
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  defineAsyncComponent,
  computed
} from "vue";
//@ts-ignore
import CircleProgress from "vue3-circle-progress";
// import { imgURLImageoptim } from "../utils";

import SplayTextAreaLoader from "./SplayTextAreaLoader.vue";
import PhotoIcon from "@/svg/PhotoIcon.vue";
// import MicrophoneIcon from "@/svg/MicrophoneIcon.vue";
import PostButtons from "@/application/components/site/PostButtons.vue";

import VideoIcon from "@/svg/VideoIcon.vue";

import PostImageButton from "@/application/components/PostImageButton.vue";
import FormImage from "./FormImage.vue";
import { ulid } from "ulid";
import { EditableImage } from "@/types/image";
import { imgURLImageoptim } from "../utils";
import { useRoute } from "vue-router";
import { useFeedVideo } from "../use/useFeedVideo";
import { getCurrentInstance } from "vue";

import { useNotification } from "@/application/plugins/Notification";

const SplayTextArea = defineAsyncComponent({
  loader: () => import("./SplayTextArea.vue"),
  loadingComponent: SplayTextAreaLoader
});

const calcImageSize = (w: number, h: number, max = 400) => {
  const ratio = Math.min(max, screen.availWidth * 0.8) / w;
  return { w: w * ratio, h: h * ratio };
};

export default defineComponent({
  components: {
    CircleProgress,
    SplayTextArea,
    FormImage,
    PhotoIcon,
    PostImageButton,
    VideoIcon,
    PostButtons
  },
  props: {
    form: {
      type: Object as PropType<Form<Message>>,
      required: true
    }
  },
  emits: {
    cancel() {}
  },
  setup(props, { emit }) {
    const notify = useNotification();
    const { state, commit, dispatch } = useStore();
    const { vbus } = useFeedVideo(0);
    const route = useRoute();

    const isFocused = ref(false);

    const { form } = props;
    const message = form.obj;

    const otherErr = null;

    const disabled = ref(true);

    const imgHeight = ref(0);
    const visible = computed(() => {
      if (
        route.name != "profile" ||
        route.params.name == state.currentUser?.name
      ) {
        return state.currentUser?.status == "active";
      }
      return false;
    });

    const handleSelectFile = async (e: Event) => {
      // @ts-ignore
      const file = fileRef.value!.files?.item(0) as File;
      onFiles([file]);
    };

    const onFiles = async (files: File[]) => {
      if (files.length > 5) {
        files = Array.from(files).slice(0, 5);
        // toast.warning("5 images is max");
      }
      files.forEach(async (file, idx) => {
        if (file) {
          const eImg = await Message.imageFromFile(file, idx);

          disabled.value = false;
          message.editableImages[idx] = eImg;
          const { w, h } = calcImageSize(imgHeight.value, eImg.height, 200);
          imgHeight.value = Math.max(imgHeight.value, h);
        }
      });
    };

    const splayText = ref();

    const progressFunc = (progress: number, eImg: EditableImage) => {
      uploadProgress.value = progress;
      eImg.progress = progress;
      imageProgress(message, progress * 100);
    };

    const save = async () => {
      const isNew = Message.isNew(message);

      disabled.value = true;

      imagePosted(message);

      const images = message.editableImages
        ? message.editableImages.filter(eImg => eImg.progress == 0)
        : [];

      const upload = async (eImg: EditableImage, idx: number) => {
        const image = await uploadImage(eImg.file!, p => progressFunc(p, eImg));
        image.position = idx;
        message.images.push(image);
        return image;
      };
      const uploads = images.map((e, idx) => upload(e, idx));

      await Promise.all(uploads);

      message.images = message.images
        ? message.images.sort((a, b) => (a.position || 0) - (b.position || 0))
        : [];

      const { message: newMessage, err } = await dispatch(
        "submitMessageForm",
        form
      );

      if (err) {
        console.error(JSON.stringify(err));
      } else {
        imgHeight.value = 0;

        //reset text editor=
        splayText.value?.reset();

        // message.id = ulid();
        message.body = "";
        message.imageFile = undefined;
        message.images = [];
        imagePostedSuccess(newMessage as Message, message.id as string);
        notify(isNew ? "post created" : "post updated");
      }
      disabled.value = true;
    };

    const cancel = () => {
      if (Message.isNew(message)) {
        commit("messages_delete", message.id);
      } else {
        commit("messages_update", { id: message.id, editing: false });
      }
      endText(message);
      emit("cancel");
    };

    const sendTypings = () => {
      if (!message.body) endText(message);
      else typing(message);
    };

    onMounted(async () => {
      vbus.on("screenshot", ({ file, recId, videoStartedAt }) => {
        onFiles([file]);

        message.type = MessageType.livestreamFrame;
        message.tag = "live";
        message.recId = recId;
        message.videoStartedAt = videoStartedAt;
        message.videoEndedAt = new Date();
      });

      uploadProgress.value = 0;
      message.feedId = (state.feed?.id || 0) as number;
      if (message.images) {
        message.images.forEach(async (img, idx) => {
          const src = imgURLImageoptim(img as { url: string });
          const eImg = await Message.imageFromSrc(src, idx);

          disabled.value = false;
          message.editableImages[idx] = eImg;
          const { w, h } = calcImageSize(imgHeight.value, eImg.height, 200);
          imgHeight.value = Math.max(imgHeight.value, h);
        });
      }

      // if (route.name != "profile" || route.params.name == state.currentUser?.name) {
      //   visible.value = state.currentUser?.status == "active";
      // }
    });

    const bodyChanged = (e: any) => {
      disabled.value = message.body.trim() == "" && message.images.length == 0;
      sendTypings();
    };

    const deleteImage = (e: Event) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      imgHeight.value = 0;
      message.imageFile = undefined;
      disabled.value = message.body.trim() == "" && message.images.length == 0;
    };

    const onEditableImage = (eImg: EditableImage) => {
      imgHeight.value = Math.max(imgHeight.value, eImg.height);
    };

    const fileDeleted = (id: string) => {
      const idx = message.editableImages.findIndex(ei => ei.id == id);
      if (~idx) {
        message.editableImages.splice(idx, 1);
      }
    };

    return {
      isFocused,
      visible,
      isNew: Message.isNew(message),
      splayText,
      imgHeight,
      uploadProgress,
      handleSelectFile,
      message,
      bodyChanged,
      otherErr,
      cancel,
      onEnter: () => {
        save();
      },
      save,
      sendTypings,
      disabled,
      onFiles,
      deleteImage,
      busy: computed(() => form.busy),
      //form image events
      onEditableImage,
      fileDeleted,
      editableImages: computed(() => message.editableImages)
    };
  },
  methods: {
    setFocus(isFocused) {
      this.isFocused = isFocused;
    }
  }
});
</script>

<style lang="scss">
@use "sass:math";
@import "../variables";
@import "../feeds/feeds.scss";
$button-width: 44px;

.message-form {
  form section {
    margin: 16px 0 24px 0;
  }
  &.focused {
    .post-buttons {
      .mic,
      .btn-video {
        width: 0;
        opacity: 0;
      }
    }
  }
  .input {
    color: var(--black);
    position: relative;
    .ProseMirror-focused ~ .post-image {
      circle,
      path {
        fill: var(--black);
      }
    }
    .save {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: none;
      border: none;
      padding: 8px;
      margin: 0 8px 0 0;
      font-size: 16px;
      cursor: pointer;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      color: #828080;
      @media (prefers-color-scheme: dark) {
        color: #d4d4d4;
      }
      &:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
    &.hide-buttons .ProseMirror {
      padding-left: 44px;
    }
  }
  .images {
    display: flex;
    justify-content: space-between;
    overflow: auto;

    // > * {
    //   flex-shrink: 0;
    //   margin-right: 1rem;
    // }

    .placeholder {
      width: 300px;
      height: 450px;
      background: rgb(206, 206, 206);
    }
  }

  .buttons {
    // display: flex;
    // justify-content: space-between;
    text-align: right;

    .btn {
      pointer-events: auto;
      transition: transform 100ms;
      background-color: transparent;
      color: #828080;
      width: $button-width;
      height: $button-width;
      display: inline-block;
      margin: 0 5px;
      border-radius: 100%;
      overflow: hidden;
      vertical-align: bottom;
      cursor: pointer;
      justify-content: center;
      .img-upload {
        background-color: var(--black) !important;
      }
      &.save {
        // background-color: var(--black);
        // color: var(--white);
      }

      &:hover {
        filter: invert(90%);
      }

      &:active {
        filter: invert(80%);
      }

      &.save {
        width: unset;
        border-radius: 4px;
        padding: 5px 16px;
        // border: 1px solid #828080;

        &:disabled {
          // background: #888;
          cursor: not-allowed;
          pointer-events: all !important;

          &:hover {
            filter: unset;
          }
        }
      }

      svg {
        margin-top: math.div($button-width, 4);
        width: math.div($button-width, 2);
        height: math.div($button-width, 2);
        circle,
        path {
          // fill: white;
          fill: var(--white);
          stroke: var(--white);
        }
      }
    }
  }

  .post-buttons {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    z-index: 100;
    &.buttons-2 + .editor {
      .ProseMirror {
        padding-left: 60px;
      }
    }
    &.buttons-1 {
      .btn {
        width: 44px;
      }
      & + .editor .ProseMirror {
        padding-left: 44px;
      }
    }
    &.hide-buttons .btn {
      width: 44px;
    }
    .btn {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
      svg {
        width: 22px;
        height: 22px;
      }
      circle,
      path {
        stroke: #828080;
        fill: #828080;
        @media (prefers-color-scheme: dark) {
          stroke: #d4d4d4;
          fill: #d4d4d4;
        }
      }
    }
  }
}

:global(.mentions-highlighter) {
  color: red !important;
  margin-left: 2px;
  // z-index: 25000;
}
</style>

<script setup lang="ts">
//@ts-ignore
import { Peer, VideoReaction } from "@/application/bbs20/store/models";
import { animate } from "@/application/components/video/emoji_ani";
import { timer } from "@/application/lib/timer";
import vrbus from "@/application/store/videoReactionsBus";
import { Message } from "@/types";
import { onMounted, ref } from "vue";
const reactions = ref<HTMLDivElement | undefined>();

const addReaction = (reaction: VideoReaction, peer: Peer) => {
  const div = reactions.value;
  if (!div) {
    return;
  }

  let rEl = document.createElement("div");
  div.appendChild(rEl);
  rEl.classList.add("reaction");

  rEl.appendChild(document.createTextNode(reaction.emoji));

  const usEl = document.createElement("div");
  usEl.classList.add("reaction-username");
  usEl.appendChild(document.createTextNode(peer.name));

  const duration = animate(rEl);

  const { tick } = timer(duration, duration);
  tick(() => {
    rEl.parentNode?.removeChild(rEl);
  });
};

type reactionsPlayerType = {
  message: null | Message;
  video: null | HTMLVideoElement;
  cursor: 0;
};

const reactionsPlayer: reactionsPlayerType = {
  message: null,
  video: null,
  cursor: 0,
};

let iid: number | null = null;

function start({ video, message }: { video: HTMLVideoElement; message: Message }) {
  if (reactionsPlayer.message == null || reactionsPlayer.message.id != message.id) {
    reactionsPlayer.cursor = 0;
  }
  reactionsPlayer.message = message;
  reactionsPlayer.video = video;
  //@ts-ignore
  iid = setInterval(() => {
    const t = reactionsPlayer.video!.currentTime;

    while (true) {
      const reaction =
        reactionsPlayer.message?.videoReactions &&
        reactionsPlayer.cursor < reactionsPlayer.message.videoReactions.length &&
        reactionsPlayer.message.videoReactions[reactionsPlayer.cursor];

      if (reaction && reaction.sentAt <= t) {
        //@ts-ignore
        addReaction(reaction, reaction.user);
        reactionsPlayer.cursor++;
        console.log("REACTION", reactionsPlayer.cursor - 1, reaction.sentAt, t);
      } else {
        break;
      }
    }
  }, 100);
}

function stop() {
  if (iid) {
    clearInterval(iid);
    iid = null;
  }
}

onMounted(() => {
  vrbus.on("videoReactions.play", start);
  vrbus.on("videoReactions.pause", stop);
});
</script>
<template>
  <div class="reactions" ref="reactions"></div>
</template>
<style scoped lang="scss">
.reactions {
  position: absolute;
  z-index: 99999999;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}
</style>

<template>
  <div
    :class="['message-video', { 'can-play': canPlay, 'not-playing': !hasEverPlayed }]"
    @click="toggleVid"
    :style="inlineStyle"
  >
    <play-icon class="play-icon" v-if="!isPlaying" />
    <video
      ref="video"
      playsinline
      :src="streamURL"
      :poster="posterImage"
      :id="message.id"
      @play="startPlaying"
      @pause="() => (isPlaying = false)"
      @canplay="setCanPlay"
    ></video>
  </div>
</template>

<script lang="ts">
// TS stuff
import vrbus from "@/application/store/videoReactionsBus";
import type { Message as MessageT } from "@/types";
import Hls from "hls.js";
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
// Utils
import { imgS3Url, iOS } from "../../utils";
// Components
import playIcon from "../svgs/PlayIcon.vue";

export default defineComponent({
  components: {
    playIcon,
  },
  props: {
    message: {
      type: Object as PropType<MessageT>,
      required: true,
    },
  },
  data() {
    return {
      video: false,
      isPlaying: false,
      observer: false,
      vidHeight: 0,
      vidWidth: 0,
      isIOS: iOS(),
      canPlay: false,
      hasEverPlayed: false,
      reactionsIID: null,
    };
  },
  mounted() {
    if (!this.isIOS) this.setUpVid();
    else this.canPlay = true;

    // Safari desktop can't seem to get the video height and width from the loadedmetadata event, but we have the dimensions
    // on the poster on the image, so we'll use that.
    this.vidHeight = this.message.images[0].imageHeight;
    this.vidWidth = this.message.images[0].imageWidth;
    this.initObserver();

    this.$refs.video?.addEventListener("ended", () => {
      vrbus.emit("videoReactions.pause", {
        video: this.$refs.video as HTMLVideoElement,
        message: this.message,
      });
    });
  },
  computed: {
    ...mapGetters(["videoHost"]),
    posterImage() {
      return imgS3Url(this.message.images[0]);
    },
    aspectRatio() {
      return `${(this.vidHeight / this.vidWidth) * 100}%`;
    },
    streamURL() {
      return `https://${this.videoHost}/${this.message.videoUrl}`;
    },
    inlineStyle() {
      if (this.hasEverPlayed) {
        return { paddingBottom: this.aspectRatio };
      } else {
        return {};
      }
    },
  },
  methods: {
    startPlaying() {
      this.isPlaying = true;
      this.hasEverPlayed = true;
    },
    setCanPlay() {
      this.canPlay = true;
    },
    toggleVid() {
      if (!this.isPlaying) this.playVid();
      else this.pauseVid();
    },
    playVid() {
      if (this.canPlay && !this.isPlaying) {
        this.stopOtherVids();
        this.$refs.video?.play();

        vrbus.emit("videoReactions.play", {
          video: this.$refs.video as HTMLVideoElement,
          message: this.message,
        });

        // this.reactionsIID = setInterval(() => {
        //   console.log(this.$refs.video?.currentTime);
        // }, 100);
      }
    },
    pauseVid() {
      if (this.isPlaying) {
        this.$refs.video?.pause();

        vrbus.emit("videoReactions.pause", {
          video: this.$refs.video as HTMLVideoElement,
          message: this.message,
        });
      }
    },
    stopOtherVids() {
      const videos = document.querySelectorAll(`video[id]:not([id="${this.message.id}"])`);
      for (let i = 0; i < videos.length; i++) videos[i].pause();
    },
    initObserver() {
      this.observer = new IntersectionObserver(
        (e) => {
          if (e[0].isIntersecting) this.playVid();
          else this.pauseVid();
        },
        {
          threshold: 0.33,
        }
      );
      this.observer.observe(this.$refs.video);
    },
    setUpVid() {
      const hls = new Hls();
      const stream = `//${this.videoHost}/${this.message.videoUrl}`;

      hls.loadSource(stream);
      hls.attachMedia(this.$refs.video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.canPlay = true;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.message-video {
  position: relative;
  width: 100%;
  height: 0;
  pointer-events: none;
  margin-top: 16px;
  transition: padding-bottom 200ms ease-in-out;
  &.not-playing {
    // Start with a reduced height, once we play we'll use the real aspect ratio.
    padding-bottom: calc(177.778% / 2) !important;
  }
  &.can-play {
    pointer-events: auto;
    .play-icon {
      display: block;
    }
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; // need this because we reduce height of the video element until the person hits play.
  }
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: none;
  }
}
</style>

<template>
  <div id="app" :class="classes">
    <!-- <site-header v-if="!appEmbed && (isPostPage || isProfilePage) && isMobile" /> -->
    <modal-header v-if="isLoggedIn || isLegalPage || isPostPage" />
    <router-view v-if="!isArchivePage" :key="href" @logged-in="setLoggedIn"/>
    <archive-page v-else />
    <!-- <site-footer v-if="!isArchivePage" /> -->
  </div>
</template>

<script lang="ts">
// Helpers
import _throttle from "lodash/throttle";
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { useBbs20Store } from "./bbs20/store";
import siteFooter from "./components/site/Footer.vue";
// Components
import siteHeader from "./components/site/Header.vue";
import ModalHeader from "@/application/components/site/ModalHeader.vue";
import { Room, useRoomsStore } from "./feeds/store/rooms";
import { useFeedsSubscribe } from "./feeds/use";
import localStorageService from "./lib/localStorageService";
import ArchivePage from "./pages/Archive.vue";

type dataType = {
  videoOpen: boolean;
  showEditProfile: boolean;
  voiceActive: boolean;
  audioStarted: boolean;
  feedAudioRoom: Room | null;
  feedVideoRoom: Room | null;
};

export default defineComponent({
  components: {
    siteHeader,
    siteFooter,
    ArchivePage,
    ModalHeader
  },

  mounted() {
    this.initUser();
    this.setEventListeners();

    // debugger;
    useFeedsSubscribe();

    const { state: rooms, bus: rbus } = useRoomsStore();

    //NOTE: only for debug, to show values in vue dev tools vuex tab
    if (["b2020.splay.com", "localhost"].includes(location.hostname)) {
      this.$store.commit("rooms_set", rooms);
    }

    this.$store.dispatch("releases_list", 1);

    if (this.appEmbed) {
      document.body.classList.add("app-embed");
    }

    document.documentElement.style.setProperty("--100vh", `${window.innerHeight}px`);

    if (this.isArchivePage) {
      document.body.classList.add(`route-archive`);
    } else {
      document.body.classList.remove(`route-archive`);
      this.$nextTick(() => {
        // console.log("ROUTE: ", this.$route.name)
        // if (!localStorage.getItem('oauth') && this.$route.name == 'profile') {
        //   this.$router.push('/')
        // }  
      })
    }
    if (!this.isLoggedIn) document.body.classList.add("not-logged-in");
    else document.body.classList.remove("not-logged-in");
  },

  computed: {
    classes() {
      return [
        "layout-default bbs",
        `route-${this.routeName}`,
        { livevideo: this.videoOpen },
        { "voice-active": this.voiceActive },
      ];
    },
    href() {
      if (this.$route.path == '/privacy' || this.$route.path == '/terms') return '/'
      else return this.$route.path
    },
    routeName() {
      return this.$route.name;
    },
    inGroups() {
      return this.$route.fullPath.startsWith("/channels");
    },
    isLegalPage() {
      return this.$route.name == "terms" || this.$route.name == "privacy";
    },
    isArchivePage() {
      return window.location.host.split(".")[0] == "bbs";
    },
    isPostPage() {
      return this.$route.name == 'post'
    },
    isProfilePage() {
      return this.$route.name == 'profile'
    },
    isMobile() {
        return window.innerWidth < 550
    },
    ...mapGetters(["appEmbed", "currentUser"]),
  },
  methods: {
    initUser() {
      const urlp = new URLSearchParams(location.search.replace(/^\?/, ""));
      this.$store.dispatch("loadCurrentUser", null).then(({ user }) => {
        if (user) {
          if (!user.name || user.status != "active") {
            // this.$router.push({ name: "user_settings" });
          } else {
            let deviceId = localStorageService.getDeviceId();
            if (!urlp.get("deviceId") && !deviceId) {
              deviceId = uuidv4() as string;
              localStorageService.setUserDeviceId(deviceId);
              this.$store.commit("setSettings", { deviceId });
            }
            document.body.classList.remove("current-user-loading");
            // document.body.classList.remove("not-logged-in");
          }
        } else {
          // document.body.classList.remove("not-logged-in");
          // document.body.classList.add("not-logged-in");
        }
        this.loadingUser = false;
        const { setUser, setToken, connectWs } = useBbs20Store();
        const token = localStorageService.getAccessToken();
        setUser(user);
        setToken(token);
        connectWs();
        useFeedsSubscribe();
      });
    },
    onOpenVideo() {
      this.videoOpen = true;
    },
    onCloseVideo() {
      this.videoOpen = false;
    },
    userUpdated() {
      this.showEditProfile = false;
    },
    setVoiceActive() {
      this.voiceActive = true;
    },
    setVoiceInactive() {
      this.voiceActive = false;
    },
    startAudio() {
      this.audioStarted = true;
    },
    setEventListeners() {
      const onResize = () => {
        // Set mobile safe 100vh
        document.documentElement.style.setProperty("--100vh", window.innerHeight + "px");
      };
      window.addEventListener("resize", _throttle(onResize, 10));
    },
    setLoggedIn(isLoggedIn) {
      this.isLoggedIn = isLoggedIn
      document.body.classList.remove("not-logged-in");
    }
  },
  data(): dataType {
    return {
      videoOpen: false,
      showEditProfile: false,
      voiceActive: false,
      audioStarted: false,
      feedAudioRoom: null,
      feedVideoRoom: null,
      loadingUser: true,
      isLoggedIn: !!localStorage.getItem('oauth')
    };
  },
});
</script>

<style lang="scss">
// TODO: Move this to a better place
@import "./main";
@import "./variables";

#app {
  position: relative;
  z-index: 0;
}

.layout-default {
  position: relative;
  &.voice-active {
    margin: 140px auto 0 auto;
  }
  .__cov-progress {
    top: 48px !important;
  }
}
</style>

import { useStore } from "@/application/store";
import { User, v1 } from "@/types";
import ReconnectingWebSocket from "reconnectingwebsocket";
import { loadCurrentUser } from "../api/user";
import localStorageService from "../lib/localStorageService";
import { WsController } from "../store/WsController";
import { imgURLLow } from "../utils";


export function getWsURL(): string {
  return document.querySelector("[data-ws-url]")?.getAttribute("data-ws-url") || "";
}

export async function connectWebScoket(reconnect = false): Promise<ReconnectingWebSocket> {
  if (!reconnect && ws?.readyState == WebSocket.OPEN) {
    return Promise.resolve(ws)
  }


  let user: User | undefined
  try {
    user = await loadCurrentUser() as User

  } catch (e) { }

  const { getters, commit } = useStore()

  const deviceId = localStorageService.getDeviceId()

  const token = localStorageService.getRefreshToken() || "";
  let url = getWsURL()
  const profilePicURL = user ? imgURLLow(user.image) : ""
  const username = user?.name || ""

  if (reconnect) {
    ws = new ReconnectingWebSocket(
      `${url}?version=2&deviceId=${deviceId}&token=${token}&username=${username}&profilePicURL=${profilePicURL}`
    );
    return ws
  }
  ws ??= new ReconnectingWebSocket(
    `${url}?version=2&deviceId=${deviceId}&token=${token}&username=${username}&profilePicURL=${profilePicURL}`
  );

  return ws
}


let ws: ReconnectingWebSocket
export async function useWebSocket(): Promise<ReconnectingWebSocket> {

  if (ws?.readyState == WebSocket.OPEN) {
    return Promise.resolve(ws)
  }


  let user: User | undefined
  try {
    user = await loadCurrentUser() as User

  } catch (e) { }

  const { getters, commit } = useStore()

  const deviceId = localStorageService.getDeviceId()

  const token = localStorageService.getRefreshToken() || "";
  let url = getWsURL()
  const profilePicURL = user ? imgURLLow(user.image) : ""
  const username = user?.name || ""


  ws = await connectWebScoket()

  const handleWelcome = (e: ReconnectingWebSocket.MessageEvent) => {
    const m = JSON.parse(e.data)
    const msg = m as v1.msg.welcomeRaw
    if (msg.type == v1.MessageType.welcome) {
      commit("ws_setUID", msg.uid)
      ws.removeEventListener("message", handleWelcome)
    }
  }
  ws.addEventListener("message", handleWelcome)

  return new Promise((resolve) => {
    ws.addEventListener("open", () => {
      useStore().commit("ws_setConnected", true)
      resolve(ws)
    }, { once: true })
  })
}



let _ctrl: WsController | undefined
export async function useWebSocketController(): Promise<WsController> {
  if (_ctrl && _ctrl?.connected()) {
    return Promise.resolve(_ctrl)
  }

  const sock = await useWebSocket()
  _ctrl ??= WsController.getInstance(sock)
  return _ctrl
}




import { Feed } from "./Feed"
import { Message } from "./Message"
import { CurrentUser } from "./User"

export class Form<T = Message | CurrentUser | Feed> {
  busy = false
  errors?: { [field: string]: Array<string> }

  constructor(public obj: T, public type: "message" | "currentUser" | "feed", public open = true) {
  }

  set key(_) { }
  get key() {
    if (this.type == "message") {
      const o = (this.obj as unknown) as Message
      return `${o.type}Message`
    }
    return this.type
  }
}
